import React, { useEffect, useState } from 'react';
import { useSelector} from "react-redux";

const PillToggle = (props) => {

    const user = useSelector((state) => state.auth).userdata;
    
    const [activeTab, setActiveTab] = useState(0)

    

    const handleClick = (e, index) => {
        setActiveTab(index)
        //TODO handle whatever event needs handling
        props.handleClick(e)
    }

    return (
        <div className="pill-toggle">
            {
                props.tabs ? props.tabs.map((tab, index)=>{
                    return (
                        <button 
                            key={index}
                            onClick={(e)=>{handleClick(e, index)}} 
                            className={`tab bold ${index === activeTab ? 'active' : ''}`}
                        >
                           {tab}
                        </button>
                    )
                }) : null
            }
        </div>
    )
}

export default PillToggle