import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector} from "react-redux";
import Avatar from '../global/Avatar'

//images
import clock from "../../assets/images/icons/clock.svg"
import clockGrey from "../../assets/images/icons/clock-grey-small.svg"
import location from "../../assets/images/icons/location.svg"
import courseImg from "../../assets/images/courses/C1_Corporate_English_Adults.jpg"
import capGrey from "../../assets/images/icons/cap-grey.svg"
import peopleGrey from "../../assets/images/icons/people-grey.svg"
import lessonGrey from "../../assets/images/icons/lesson-grey.svg"

const ScheduleCard = (props) => {

    const users = useSelector((state) => state.users);
    const user = useSelector((state) => state.auth).userdata;

    let ageGroup = props.course.ageGroup.toUpperCase().replace(/\s/g, '');

    const userType = {
        YOUNGLEARNERS: "GRP-YTS",
        TEEN: "GRP-TNS",
        ADULTS: "GRP-ADS",
        // ADMIN: "GRP-ADM",
        // INSTRUCTOR: "GRP-INS"
    }

    const days = ["SUN", "MON", "TUES", "WED", "THUR", "FRI", "SAT"];
    const months = ["JAN","FEB","MAR","APR","MAY","JUNE","JULY","AUG","SEPT","OCT","NOV","DEC"];



    const displayStudents = () => {
        const filteredUsers = users.filter((user) => user.ageGroupId === userType[ageGroup] && user.cohort.includes(props.course.id));
        //const filteredUsers = users.filter((user) => user.cohort.includes(props.course.id));

        return filteredUsers.map((user, index)=>{
            return <Avatar key={index} outline='white' size='small' user={user}/>
        })
    }


    function getMonth(){
        let lessonDate = new Date(props.lesson.date)
        let month = months[lessonDate.getMonth()]
        return month
    }

    const getDate = () => {
        let lessonDate = new Date(props.lesson.date)
        let day = lessonDate.getDay()
        let date = lessonDate.getDate()
        return (days[day] + " " + date)
    }


    return (
        <div className="schedule-card">
            <div className='square date'>
                <span>{getMonth()}</span>
                <span>{getDate()}</span> 
            </div>
            {
                user.occupation === 'instructor' ? <>
                <div className="time-location">
                    <div className="time">
                        <img src={clock}/>
                        <span>{props.lesson.startTime} - {props.lesson.endTime}</span>
                    </div>
                    <div className="location">
                        <img src={location}/>
                        <span>{props.course.location}</span>
                    </div>
                </div>
                </> : null
            }

            <div className="name-info">
                <h5>
                <Link to={process.env.PUBLIC_URL + "/course/"+props.course.id}>{props.course.cefrLevel} {props.course.name} ({props.course.ageGroup})</Link></h5>
                <div>{displayStudents()}</div> 
             </div>
            <Link className="cta secondary" to={"/course/"+props.course.id+"/"+props.lesson.id}>View lesson</Link>
        </div>
    )
}

export default ScheduleCard
