import React from "react";
import PageWrapper from "../global/PageWrapper";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Breadcrumbs from "../global/Breadcrumbs";
import StudentsTable from "./StudentsTable";
import LessonsList from "./LessonsList";
import PerformanceChart from "../infographics/PerformanceChart";
import Icon from "../global/Icon";
import CourseProgressBar from "../global/CourseProgressBar"

//images
import placeholder from "../../assets/images/recommendations/classroom.png"
import add from "../../assets/images/icons/add.svg"

let materials = [
    {
        type: "Activity",
        objective: "<strong>Objective:</strong> Develop storytelling skills and creativity through the creation of a short story.",
        saved: true
    },
    {
        type: "Podcast",
        objective: "<strong>Cultural and Historical Contexts:</strong> Literature is often studied in its cultural and historical context.",
        saved: true
    }
]

const CourseDetail = (props) => {
    const params = useParams();
    const { courseId } = params;
    const courses = useSelector((state) => state.courses);

    const course = useSelector((state) =>
        courses.find((record) => record.id.toLowerCase() === courseId.toLowerCase())
    );

    const breadcrumbs = [
        {
            page:"Courses",
            target: "/courses"
        },
        {
            page: `${course.cefrLevel} ${course.name} (${course.ageGroup})`,
            target: ''
        }
    ]

    return (
        <PageWrapper>
                <div className="course-detail">
                <Breadcrumbs data={breadcrumbs}/>
                <h1 className="text-h3">{course.cefrLevel} {course.name} ({course.ageGroup})</h1>
                <div className="course-page-progress">
                    <p>Course completion</p>
                    <CourseProgressBar value="70"/>
                </div>
                
                {
                    course.lessons ? <>
                        <LessonsList lessons={course.lessons} course={course}/>
                    </> : null
                }
                <StudentsTable course={course}/>
                <section>
                    <div className="section-header">
                        <h3>Class performance</h3>
                    </div>
                    <div className="section-body light-bg">
                        <PerformanceChart />
                    </div>
                    <div className="section-footer">
                        <a className="link-button">
                            <span>Performance breakdown</span>
                            <span><Icon icon="chevron-right" size="18"/></span>
                        </a>
                    </div>
                </section>
                <section>
                <div className="section-header">
                    <h3>Course content</h3>
                    <p>You can add more content to this course.</p>
                </div>
                <div className="section-body">
                    <div className="material-group">
                        {materials.map((item, index) => {
                            return (
                                <div className="card material-card" style={{background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(99.71deg, #000000 1.54%, rgba(0, 0, 0, 0) 100.73%), url(' + placeholder + ')'}} key={"material" + index}>
                                    <span className={`chip-highlight tag-${item.type.toLowerCase()}`}>{item.type}</span>
                                    <div className="description">
                                        <Icon icon={item.type.toLowerCase() === "activity" ? "user-group" : "listen"} size="24"/>
                                        <p dangerouslySetInnerHTML={{__html: item.objective}}/>
                                    </div>
                                    {!item.saved && <button className="icon-button add-btn" aria-label="Add to agenda"><Icon icon="add-item" size="24"/></button>}
                                </div>
                            )
                        })}
                         <button>
                            <img src={add}/>
                        </button>
                    </div>
                </div>
                <div className="section-footer">
                    <a className="link-button">
                        <span>More</span>
                        <span><Icon icon="chevron-right" size="18"/></span>
                    </a>
                </div>
                
            </section>
            </div>
        </PageWrapper>
    )
}

export default CourseDetail
