/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageWrapper from './global/PageWrapper';
import SearchInput from './global/SearchInput';
import MessageUser from './messages/MessageUser';
import MessageWindow from './messages/MessageWindow';
import { LoremIpsum } from 'lorem-ipsum';

import '../../src/assets/styles/scss/_messages.scss';

const Messages = (props) => {
	const Users = useSelector((state) => state.users);
	const [shuffledUsers, setShuffledUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);

	/**shuffling userCard logic only once */
	useEffect(() => {
		const shuffledArray = [...Users];
		for (let i = shuffledArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffledArray[i], shuffledArray[j]] = [
				shuffledArray[j],
				shuffledArray[i],
			];
		}
		setShuffledUsers(shuffledArray);

		if (shuffledArray.length > 0) {
			setSelectedUser(shuffledArray[0]);
		}
	}, []);

	const slicedUsers = shuffledUsers.slice(0, 10);

	/*lorem ipsum generate*/
	const generateRandomText = () => {
		const lorem = new LoremIpsum({
			sentencesPerParagraph: {
				max: 20,
				min: 5,
			},
			wordsPerSentence: {
				max: 20,
				min: 5,
			},
		});
		const randomSentenceCount = Math.floor(Math.random() * 5) + 1; // Generate random number of sentences
		return lorem.generateSentences(randomSentenceCount);
	};
	const randomText = generateRandomText();

	/**Display chat window on Mobile view */
	const [MobileView, setMobileView] = useState(false);
	useEffect(() => {
		const handleResize = () => {
			setMobileView(window.innerWidth < 900);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleUserClick = (user) => {
		setSelectedUser(user);
		if (MobileView) {
			setMobileView(false);
		}
	};

	/**return to msg on mobile view */
	const ReturnToMessages = () => {
		setMobileView(true);
	};

	return (
		<PageWrapper page="messages">
			<div className="msg-page-wrapper">
				<h3 className="directory-header-title">Messages</h3>

				<SearchInput
					placeholder="Search conversation & people"
					isHeader={true}
				/>

				<div className="msg-page-layout">
					<div
						className={`msg-card-list scrollable ${MobileView ? '' : 'hide'}`}
					>
						{slicedUsers.map((user, index) => (
							<section
								key={index}
								onClick={() => handleUserClick(user)}
							>
								<MessageUser
									user={user}
									variant="dark"
									randomText={generateRandomText()}
								/>
							</section>
						))}
					</div>

					<div className={`view-msg scrollable ${MobileView ? 'hide' : ''}`}>
						<section>
							{selectedUser && (
								<MessageWindow
									user={selectedUser}
									randomText={generateRandomText()}
									onReturnToMessages={ReturnToMessages}
								/>
							)}
						</section>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default Messages;
