import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageWrapper from "./global/PageWrapper";
import assignmentsImage from "../assets/images/assignments/assignments.png";
import pal from "../assets/images/assignments/pal.png";
import Card from "./achieve_archive/global/cards/Card";

const AssignmentDetail = (props) => {
    
  const params = useParams();
  const { courseId, lessonId, assignmentId } = params;
  const user = useSelector((state) => state.auth).userdata;
  const courses = useSelector((state) => state.courses);

  const course = useSelector((state) => {
    return courses.find(
      (record) => {
        // console.log("Course ID:", record.id, "CourseId:", courseId);
        return record.id?.toLowerCase() === courseId.toLowerCase()
      }
    )
  });

  // console.log("Got the Course:", course.id);
    const lesson =  course.lessons.find(
      (record) => {
        // console.log("Lesson Record.id", record.id, "LessionId:", lessonId);
        return record.id?.toLowerCase() === lessonId.toLowerCase()
      }
    );

    // console.log("Got the Lesson:", lesson);
    const assignment =  lesson.assignments.find(
      (record) => {
        // console.log("Assignments: Records:", record.id, "assignmentId:", assignmentId);
        return record.id?.toLowerCase() === assignmentId.toLowerCase()
      }
    );

  // console.log("Got the Assignment:", assignment);
  const lines = assignment.overview?.split("\n");

  return (
    <PageWrapper>
        {/* <div className="assignment-detail">
            <h3>Assignment Detail</h3> 
            <p>{assignment.id}</p>
            <p>{assignment.name}</p> */}

            <>
                <div className="assignment-container assignment-background assignment-txt-color">
                <div className="assignment-img-container">
                    <img src={assignmentsImage} alt="Three students chatting outside" />
                </div>
                <section>
                    <p className="assignment-description assignment-description__text">
                    English Homework Assignment
                    </p>
                    <h3 className="assignment-title">
                    {assignment.name}
                    </h3>
                    <p className="assignment-body assignment-description__body">
                    This assignment is a Self-Paced option but should take around{" "}
                    <span className="assignment_bold">
                        {assignment.duration} minutes
                    </span>
                    </p>
                    <button className="assignment-btn primary">Start Homework Assignment</button>
                </section>
                <section className="overview">
                    <h3 className="assignment-title">Overview</h3>
                    <div className="assignment-overview__text">
                    {lines?.map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}
                    </div>
                    <div className="assignment-overview__goal">
                    {/* <span className="pal-assignment-container">
                        <img src={pal} alt="Pal AI chatbot" />
                    </span> */}
                    <p>Working towards:</p>
                    </div>
                    <p className="assignment-overview__course">
                    Completion of{" "}
                    <span>
                        <a href="#">{course.cefrLevel + " " + course.name}</a>
                    </span>
                    </p>
                </section>
                </div>
                <section className="course overview-cards">
                <div className="card-list">
                    <Card modifier="overview">
                    <button className="icon-btn info-btn"></button>
                    <span className="label">Level</span>
                    <span className="number">{course.cefrLevel}</span>
                    </Card>
                    <Card modifier="overview">
                    <span className="label">Est. time</span>
                    <span className="number text">
                        {assignment.duration} mins
                    </span>
                    </Card>
                    <Card modifier="overview">
                    <span className="label">Points</span>
                    <span className="number text">
                        Up to {assignment.points}
                    </span>
                    </Card>
                    <Card modifier="overview">
                    <span className="label">Current Position</span>
                    <span className="number text">{assignment.position}</span>
                    </Card>
                </div>
                </section>
                </> 

        {/* </div> */}
    </PageWrapper>
  );
};

export default AssignmentDetail;