import library from "../../assets/images/courses/library.png"
import typing from "../../assets/images/courses/typing.png"

function getDateFromToday(count) {
  let nextDate = new Date()
  nextDate.setDate(new Date().getDate() + count);
  //if Sunday
  if(nextDate.getDay() === 0){
    //move to Monday
    nextDate.setDate(nextDate.getDate() + 1);
  }
  //if Saturday
  if(nextDate.getDay() === 6){
    //move to Monday
    nextDate.setDate(nextDate.getDate() + 2);
  }
  //serialize date
  return nextDate.getTime()
}

const courses = [
 {
   "id": "ENG-ADS-A1-000",
   "location": "In person",
   "semester": "SEM-SPR",
   "instructor": "INS-000",
   "name": "General English",
   "ageGroup": "Adults",
   "cefrLevel": "A1",
   "gseRange": [22,29],
   "gseThreshhold": 29,
   "Proficiency": "beginner-1",
   "cefrDescriptions": "BreakThrough - Simple interactions.  Ask and answer simple questions about themselves. Respond to simple immediate questions.",
   "book":"Roadmap A1",
   "lessons": [
    {
      "id": "L1",
      "name": "The Future is Now | Reading",
      "description": "Looking at Grammar and working with the Future tense. It is recommended that students have, The Future is Now, text book.",
      "startTime": "16:00",
      "endTime": "18:00",
      "date": getDateFromToday(0),
      "image": library,
      "agenda": [{"time": "16:00 - 16:15", "activity": "Recap on previous topics class"}, {"time": "16:15 - 16:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "16:30 - 17:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "17:00 - 17:45", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "17:45 - 18:00", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
          {
            "id": "A1",  
            "name": "Simple Interactions",
            "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
            "description": "This assignment is a Self-Paced option but should take around 30 minutes",
            "duration": 30,
            "timeUnit": "minutes",
            "points": 30,
            "position": "N/A",
          }
      ]
    },
    {
      "id": "L2",
      "name": "The Future is Now | Writing",
      "description": "Looking at Grammar and working with the Future tense. It is recommended that students have, The Future is Now, text book.",
      "startTime": "16:00",
      "endTime": "18:00",
      "date": getDateFromToday(56),
      "image": typing,
      "agenda": [{"time": "16:00 - 16:15", "activity": "Recap on previous topics class"}, {"time": "16:15 - 16:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "16:30 - 17:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "17:00 - 17:45", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "17:45 - 18:00", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
        {
          "id": "A1",  
          "name": "Simple Interactions",
          "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
          "description": "This assignment is a Self-Paced option but should take around 30 minutes",
          "duration": 30,
          "timeUnit": "minutes",
          "points": 30,
          "position": "N/A",
        }
      ]
    }
   ]
 },
 {
   "id": "ENG-YTS-A1-000",
   "location": "Online",
   "semester": "SEM-SPR",
   "instructor": "INS-000",
   "name": "General English",
   "ageGroup": "Young Learners",
   "cefrLevel": "A1",
   "gseRange": [22,29],
   "gseThreshhold": 29,
   "Proficiency": "beginner-1",
   "cefrDescriptions": "BreakThrough - Simple interactions.  Ask and answer simple questions about themselves. Respond to simple immediate questions.",
   "book":"Speakout A1",
   "lessons": [
    {
      "id": "L1",
      "name": "Introduction to Descriptive Writing 1",
      "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
      "startTime": "09:00",
      "endTime": "11:00",
      "date": getDateFromToday(0),
      "image": library,
      "agenda": [{"time": "09:00 - 09:15", "activity": "Recap on previous topics class"}, {"time": "09:15 - 09:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "09:30 - 10:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "10:00 - 10:45", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "10:45 - 11:00", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
          {
            "id": "A1",  
            "name": "Simple Interactions",
            "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
            "description": "This assignment is a Self-Paced option but should take around 30 minutes",
            "duration": 30,
            "timeUnit": "minutes",
            "points": 30,
            "position": "N/A",
          }
      ]
    },
    {
      "id": "L2",
      "name": "Introduction to Descriptive Writing 2",
      "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
      "startTime": "09:00",
      "endTime": "11:00",
      "date": getDateFromToday(7),
      "image": typing,
      "agenda": [{"time": "09:00 - 09:15", "activity": "Recap on previous topics class"}, {"time": "09:15 - 09:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "09:30 - 10:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "10:00 - 10:45", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "10:45 - 11:00", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
        {
          "id": "A1",  
          "name": "Simple Interactions",
          "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
          "description": "This assignment is a Self-Paced option but should take around 30 minutes",
          "duration": 30,
          "timeUnit": "minutes",
          "points": 30,
          "position": "N/A",
        }
      ]
    }
   ]
 },
//  {
//    "id": "ENG-TNS-B1-000",
//    "location": "Online",
//    "semester": "SEM-SPR",
//    "instructor": "INS-000",
//    "name": "General English",
//    "ageGroup": "Teens",
//    "cefrLevel": "B1",
//    "gseRange": [43, 50],
//    "gseThreshhold": 50,
//    "Proficiency": "intermediate",
//    "cefrDescriptions": "Understanding main points of discussion.  Give or seek personal views and opinions informally. Maintain a general conversation, may have difficulty with precise meaning.  Can enter in uprepared conversation.",
//    "book":"Speakout A3",
//    "lessons": [
//     {
//       "id": "L1",
//       "name": "Introduction to Descriptive Writing 1",
//       "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
//       "startTime": "13:00",
//       "endTime": "15:30",
//       "date": getDateFromToday(14),
//       "image": library,
//       "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
//       "materials": [],
//       "assignments": [
//           {
//             "id": "A1",  
//             "name": "Simple Interactions",
//             "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
//             "description": "This assignment is a Self-Paced option but should take around 30 minutes",
//             "duration": 30,
//             "timeUnit": "minutes",
//             "points": 30,
//             "position": "N/A",
//           }
//       ]
//     },
//     {
//       "id": "L2",
//       "name": "Introduction to Descriptive Writing 2",
//       "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
//       "startTime": "13:00",
//       "endTime": "15:30",
//       "date": getDateFromToday(21),
//       "image": typing,
//       "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
//       "materials": [],
//       "assignments": [
//         {
//           "id": "A1",  
//           "name": "Simple Interactions",
//           "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
//           "description": "This assignment is a Self-Paced option but should take around 30 minutes",
//           "duration": 30,
//           "timeUnit": "minutes",
//           "points": 30,
//           "position": "N/A",
//         }
//       ]
//     }
//    ]
//  },
 {
  "id": "ENG-TNS-B1P-000",
  "location": "Online",
  "semester": "SEM-SPR",
  "instructor": "INS-001",
  "name": "General English",
  "ageGroup": "Teens",
  "cefrLevel": "B1",
  "gseRange": [43, 50],
  "gseThreshhold": 50,
  "Proficiency": "intermediate",
  "cefrDescriptions": "Understanding main points of discussion.  Give or seek personal views and opinions informally. Maintain a general conversation, may have difficulty with precise meaning.  Can enter in uprepared conversation.",
  "book":"Speakout A4",
  "lessons": [
   {
     "id": "L1",
     "name": "Introduction to Descriptive Writing 1",
     "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
     "startTime": "13:00",
     "endTime": "15:30",
     "date": getDateFromToday(14),
     "image": library,
     "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
     "materials": [],
     "assignments": [
         {
           "id": "A1",  
           "name": "Simple Interactions",
           "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
           "description": "This assignment is a Self-Paced option but should take around 30 minutes",
           "duration": 30,
           "timeUnit": "minutes",
           "points": 30,
           "position": "N/A",
         }
     ]
   },
   {
     "id": "L2",
     "name": "Introduction to Descriptive Writing 2",
     "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
     "startTime": "13:00",
     "endTime": "15:30",
     "date": getDateFromToday(21),
     "image": typing,
     "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
     "materials": [],
     "assignments": [
       {
         "id": "A1",  
         "name": "Simple Interactions",
         "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
         "description": "This assignment is a Self-Paced option but should take around 30 minutes",
         "duration": 30,
         "timeUnit": "minutes",
         "points": 30,
         "position": "N/A",
       }
     ]
   }
  ]
},
 {
   "id": "ENG-ADS-B1P-000",
   "location": "Online",
   "semester": "SEM-SPR",
   "instructor": "INS-000",
   "name": "General English",
   "ageGroup": "Adults",
   "cefrLevel": "B1+",
   "gseRange": [51, 58],
   "gseThreshhold": 58,
   "Proficiency": "intermediate",
   "cefrDescriptions": "Strong Threashold - Communicate in details with limited precision.  Exhange familiar/nonfamiliar information with some confidence.",
   "book":"Speakout A5",
   "lessons": [
    {
      "id": "L1",
      "name": "Introduction to Descriptive Writing 1",
      "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
      "startTime": "12:00",
      "endTime": "02:00",
      "date": getDateFromToday(0),
      "image": library,
      "agenda": [{"time": "12:00 - 12:15", "activity": "Recap on previous topics class"}, {"time": "12:15 - 12:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "12:30 - 01:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "01:00 - 01:45", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "01:45 - 02:00", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
          {
            "id": "A1",  
            "name": "Simple Interactions",
            "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
            "description": "This assignment is a Self-Paced option but should take around 30 minutes",
            "duration": 30,
            "timeUnit": "minutes",
            "points": 30,
            "position": "N/A",
          }
      ]
    },
    {
      "id": "L2",
      "name": "Introduction to Descriptive Writing 2",
      "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
      "startTime": "12:00",
      "endTime": "02:00",
      "date": getDateFromToday(7),
      "image": typing,
      "agenda": [{"time": "12:00 - 12:15", "activity": "Recap on previous topics class"}, {"time": "12:15 - 12:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "12:30 - 01:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "01:00 - 01:45", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "01:45 - 02:00", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
        {
          "id": "A1",  
          "name": "Simple Interactions",
          "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
          "description": "This assignment is a Self-Paced option but should take around 30 minutes",
          "duration": 30,
          "timeUnit": "minutes",
          "points": 30,
          "position": "N/A",
        }
      ]
    }
   ]
 },
//  {
//    "id": "ENG-TNS-B1-001",
//    "location": "Online",
//    "semester": "SEM-SMR",
//    "instructor": "INS-001",
//    "name": "General English",
//    "ageGroup": "Teens",
//    "cefrLevel": "B1",
//    "gseRange": [43, 50],
//    "gseThreshhold": 50,
//    "Proficiency": "intermediate",
//    "cefrDescriptions": "Understanding main points of discussion.  Give or seek personal views and opinions informally. Maintain a general conversation, may have difficulty with precise meaning.  Can enter in uprepared conversation.",
//    "book":"Speakout A6",
//    "lessons": [
//     {
//       "id": "L1",
//       "name": "Introduction to Descriptive Writing 1",
//       "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
//       "startTime": "13:00",
//       "endTime": "15:30",
//       "date": getDateFromToday(0),
//       "image": library,
//       "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
//       "materials": [],
//       "assignments": [
//           {
//             "id": "A1",  
//             "name": "Simple Interactions",
//             "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
//             "description": "This assignment is a Self-Paced option but should take around 30 minutes",
//             "duration": 30,
//             "timeUnit": "minutes",
//             "points": 30,
//             "position": "N/A",
//           }
//       ]
//     },
//     {
//       "id": "L2",
//       "name": "Introduction to Descriptive Writing 2",
//       "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
//       "startTime": "13:00",
//       "endTime": "15:30",
//       "date": getDateFromToday(7),
//       "image": typing,
//       "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
//       "materials": [],
//       "assignments": [
//         {
//           "id": "A1",  
//           "name": "Simple Interactions",
//           "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
//           "description": "This assignment is a Self-Paced option but should take around 30 minutes",
//           "duration": 30,
//           "timeUnit": "minutes",
//           "points": 30,
//           "position": "N/A",
//         }
//       ]
//     }
//    ]
//  },
 {
   "id": "ENG-TNS-B2-002",
   "location": "Online",
   "semester": "SEM-SMR",
   "instructor": "INS-001",
   "name": "General English",
   "ageGroup": "Teens",
   "cefrLevel": "B2",
   "gseRange": [59, 66],
   "gseThreshhold": 66,
   "Proficiency": "advanced",
   "cefrDescriptions": "Speak: converse naturally, fluently and effectively;  understand in detail what is said to him/her;  initiate discourse: take his/her turn when appropriate and end conversation when needed.  Spontaneous interaction, and changes in direction.  Correct mistakes",
   "book":"Speakout A7",
   "lessons": [
    {
      "id": "L1",
      "name": "Introduction to Descriptive Writing 1",
      "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
      "startTime": "13:00",
      "endTime": "15:30",
      "date": getDateFromToday(14),
      "image": library,
      "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
          {
            "id": "A1",  
            "name": "Simple Interactions",
            "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
            "description": "This assignment is a Self-Paced option but should take around 30 minutes",
            "duration": 30,
            "timeUnit": "minutes",
            "points": 30,
            "position": "N/A",
          }
      ]
    },
    {
      "id": "L2",
      "name": "Introduction to Descriptive Writing 2",
      "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
      "startTime": "13:00",
      "endTime": "15:30",
      "date": getDateFromToday(21),
      "image": typing,
      "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
      "materials": [],
      "assignments": [
        {
          "id": "A1",  
          "name": "Simple Interactions",
          "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
          "description": "This assignment is a Self-Paced option but should take around 30 minutes",
          "duration": 30,
          "timeUnit": "minutes",
          "points": 30,
          "position": "N/A",
        }
      ]
    }
   ]
 },
//  {
//    "id": "GEN-INS-ADS",
//    "location": "",
//    "semester": "",
//    "instructor": "",
//    "name": "General Instruction",
//    "ageGroup": "Adults",
//    "cefrLevel": "",
//    "gseRange": "",
//    "gseThreshhold": null,
//    "Proficiency": "",
//    "cefrDescriptions": "",
//    "book":"Speakout A8",
//    "lessons": [
//     {
//       "id": "L1",
//       "name": "Introduction to Descriptive Writing 1",
//       "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
//       "startTime": "13:00",
//       "endTime": "15:30",
//       "date": getDateFromToday(14),
//       "image": library,
//       "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
//       "materials": [],
//       "assignments": [
//           {
//             "id": "A1",  
//             "name": "Simple Interactions",
//             "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
//             "description": "This assignment is a Self-Paced option but should take around 30 minutes",
//             "duration": 30,
//             "timeUnit": "minutes",
//             "points": 30,
//             "position": "N/A",
//           }
//       ]
//     },
//     {
//       "id": "L2",
//       "name": "Introduction to Descriptive Writing 2",
//       "description": "Explain the concept of descriptive writing, emphasising its purpose to create a vivid and sensory experience for the reader.",
//       "startTime": "13:00",
//       "endTime": "15:30",
//       "date": getDateFromToday(21),
//       "image": typing,
//       "agenda": [{"time": "03:00 - 03:15", "activity": "Recap on previous topics class"}, {"time": "03:15 - 03:30",  "activity": "Intro this week's topic and the activity that will follow"}, {"time": "03:30 - 04:00", "activity": "Group work: Class will be sorted into pairs for speaking exercise" }, {"time": "04:00 - 04:15", "activity": "Peer-to-peer evaluation of speaking exercise"}, {"time": "04:15 - 04:30", "activity": "Slight recap of lesson and handing out homework"}],
//       "materials": [],
//       "assignments": [
//         {
//           "id": "A1",  
//           "name": "Simple Interactions",
//           "overview": "Introductions are a common occurrence in group settings. Let's plan your introduction for a first-time gathering at a new school or job. You should be able to tell the person your name, where you're from, and something interesting about yourself.",
//           "description": "This assignment is a Self-Paced option but should take around 30 minutes",
//           "duration": 30,
//           "timeUnit": "minutes",
//           "points": 30,
//           "position": "N/A",
//         }
//       ]
//     }
//    ]
//  }
];

export default courses;