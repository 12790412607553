import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { clearSessionData } from "../../redux/sessionSlice";
import { deleteAuthAsync } from '../../redux/authSlice';

const SignOut = (props) => {
  const dispatch = useDispatch();

  const startLogout = () => {
    // Clear all redux session 
    dispatch(deleteAuthAsync());

    // Clear session data from local storage
    localStorage.removeItem("sessionData");
  };

  useEffect(() => {
    startLogout();
  }, []);

  const handleLogout = () => {
    startLogout();
  };
  return (
    <div className="auth-page sign-in">
      {/* <button type="button" className="button-primary" onClick={handleLogout}>Logout</button> */}
    </div>
  );
};

export default SignOut;
