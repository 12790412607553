import React from "react";
import PageWrapper from "./global/PageWrapper";
import Icon from "./global/Icon";
import RecommendationsList from "./instructor_home/RecommendationsList";
import DevelopmentDonut from "./instructor_development/DevelopmentDonut";
import LearningJourney from "./infographics/LearningJourney";
import pal from "../assets/images/brand/pal.svg"
const Tests = (props) => {

    let upcomingTest = {
        date: {
            day: 'Monday',
            month: 'Jul',
            date: '30'
        },
        time: "4:00 PM",
        title: "Pearson English International Certificate",
        description: "The Pearson English International Certificate is recognised globally and accepted by employers and national education authorities as evidence of a required level of English."
    }
    let otherTests = [
        {
            date: {
                day: 'Wednesday',
                month: 'Aug',
                date: '30'
            },
            time: "4:00 PM",
            title: "PTE Test",
            subtitle: "PTE Academic, online",
            description: "Accepted globally for university study and by the Australian and New Zealand governments for work and migration visas."
        },
        {
            date: {
                day: 'Tuesday',
                month: 'Sep',
                date: '05'
            },
            time: "4:00 PM",
            title: "UK VISA",
            subtitle: "PTE Academic UKVI",
            description: "For settlement visa applications, choose PTE Home A1, A2 or B1 – our 30-minute Speaking and Listening SELT test."
        }
    ]

    return (
        <PageWrapper page="tests">
            <div className="student-tests">
                <div className="background"/>
                <div className="hero">
                    <h2>Tests</h2>
                    <div className="assignment-card">
                        <div className="pal-badge">
                            <img src={pal}/>
                            <span><span>PAL</span> You have a test to take, Rico!</span>
                        </div>
                        
                        <div className="test-card">
                            <div className="test-info">
                                <div className="test-date">
                                    <div class="date"><span className="month">{upcomingTest.date.month}</span><span className="day">{upcomingTest.date.date}</span></div>
                                    <h3>{upcomingTest.title}</h3>
                                    
                                </div>
                                <p className="test-date-details">{upcomingTest.date.day}, at {upcomingTest.time}</p>
                                <p className="test-description">{upcomingTest.description}</p>
                            </div>
                            <button className="primary">Start test</button>
                        </div>
                    </div>

                </div>
                <section>
                    <div className="section-header">
                        <h3>Why not try...</h3>
                    </div>
                    <div className="section-body test-card-list">
                        {otherTests.map((test, index) => {

                            return (
                                <div className="test-card card" key={"test"+index}>
                                    <div className="test-info">
                                        <div className="test-date">
                                            <div class="date"><span className="month">{test.date.month}</span><span className="day">{test.date.date}</span></div>
                                            <div>
                                                <h3 className="test-title">{test.title}</h3>
                                                <p className="test-subtitle">{test.subtitle}</p>
                                            </div>
                                        
                                        </div>
                                        <p className="test-description">{test.description}</p>
                                    </div>
                                    <button className="primary">Start test</button>
                                </div>      
                            )
                        })}
                        
                    </div>
                </section>
                
                

                
            </div>
            
        </PageWrapper>
    )
}

export default Tests