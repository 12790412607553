import React from "react";

//images
import podcastThumbnail from "../../assets/images/podcast-thumbnail.png"

const Podcasts = (props) => {

    return (
        <div className="podcasts">
            <h4>Pearson Podcasts</h4>
            <div className="podcast-card">
                <img src={podcastThumbnail}/>
                <span>Body Unboxed</span>
                <span>Episode 1 - Tough Nut?</span>
            </div>
            
        </div>
    )
}

export default Podcasts