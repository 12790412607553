import { createSlice } from "@reduxjs/toolkit";
import skillsData from "./data/skills";
import { getSkillsReducer} from "./reducers/skillsReducer";

const skillsSlice= createSlice({
    name: "skills",
    initialState: skillsData,
    reducers: {
      getSkills: getSkillsReducer,
      skillsLogout: (state) => {
        // Reset the state to initial values
        return [];
      },
    },
  });
  
  // Note: skillsSlice.actions automatically creates actions based on our reducer names
  export const {getSkills, skillsLogout} = skillsSlice.actions;
  
  // export  reducer, so it can be added to the store
  export default skillsSlice.reducer;

    //    area: "Management",
    //    totalEarned: "768",
    //    verified: "0%",
    //    inferred: "100%",