import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import ScheduleCard from "./ScheduleCard";
import PillToggle from "../global/PillToggle";

const ScheduleList = (props) => {

    const user = useSelector((state) => state.auth).userdata;
    const courses = useSelector((state) => state.courses);
    const [filteredCourses, setFilteredCourses] = useState([])

    const tabs = [
        'Today',
        'This week',
    ]

    useEffect(()=>{
        if(courses){
            getTodaysCourses()
        }
    },[courses])

    const getTodaysCourses = () => {
        let newArray = []
        let today = new Date()
        let dayCheck = today.getDay()
        
        //if you're testing this prototype over the weekend
        //change "today" to be Monday
        if(dayCheck === 0){
            today.setDate(today.getDate() + 1);
        } 
        else if(dayCheck === 6){
            today.setDate(today.getDate() + 2);
        }
        courses.forEach(course=>{
            let lessons = course.lessons
            course.lessons.forEach(lesson=>{
                if(new Date(lesson.date).getDate().toString() === today.getDate().toString()){
                    let courseCopy = {...course}
                    courseCopy.startTime = lesson.startTime
                    courseCopy.endTime = lesson.endTime
                    newArray.push(courseCopy)
                    return
                }
            })
        })
        setFilteredCourses(newArray)
    }

    const getThisWeeksCourses = () => {
        let newArray = []
        courses.forEach(course=>{
            let lessons = course.lessons
            course.lessons.forEach(lesson=>{
                let lessonDateTime = new Date(lesson.date).getTime().toString()
                let todayTime = new Date().getTime().toString()
                let endOfWeek = new Date()
                endOfWeek.setDate(endOfWeek.getDate() + 7)
                let endOfWeekTime = endOfWeek.getTime().toString()
                if(lessonDateTime >= todayTime && lessonDateTime <= endOfWeekTime) {
                    let courseCopy = {...course}
                    courseCopy.startTime = lesson.startTime
                    courseCopy.endTime = lesson.endTime
                    newArray.push(courseCopy)
                    return
                }
            })
        })
        setFilteredCourses(newArray)
    }

    const handleTabClick = (e) => {
        let tab = e.target.innerText
        switch(tab) {
            case 'Today':
              getTodaysCourses()
              break;
            case 'This week':
                getThisWeeksCourses()
              break;
            default:
              // code block
          }
    }

    return (
       <div className="schedule-list">
        <h3>Class schedule</h3>
        <PillToggle tabs={tabs} handleClick={handleTabClick}/>
        {
            filteredCourses.map((course, index)=>{
                if(index < 6){
                    return <ScheduleCard key={index} course={course}/>
                }
            })
        }
       </div>
    )
}

export default ScheduleList