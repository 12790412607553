import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../util/routes/protected";
import { AuthRoute } from "../util/routes/auth";
import { useDataFetch } from "../util/hooks/useDataFetch";
import { Auth, Unauthorized, Authorized } from "./auth/Auth";
import { useLocation } from "react-router-dom";
import "../assets/styles/css/style.css";
import SignIn from "./auth/SignIn";
import SignOut from "./auth/SignOut";
import Placeholder from "./global/Placeholder";
import Home from "./Home";
import Profile from "./Profile";
import Students from "./Students";
import Teachers from "./Teachers";
import Programs from "./Programs";
import Reports from "./Reports";
import Courses from "./Courses";
import Assignments from "./Assignments";
import AssignmentDetail from "./AssignmentDetail";
import Lesson from "./instructor_lesson/Lesson";
import Explore from "./Explore";
import Games from "./Games";
import Content from "./Content";
import Sandbox from "./Sandbox";
import TeacherLesson from "./instructor_lesson/TeacherLesson";
import NewLesson from "./instructor_new_lesson/NewLesson";
import CourseDetail from "./instructor_course/CourseDetail";
import StudentDetail from "./StudentDetail";
import Onboarding from "./auth/Onboarding";
import MyLearning from "./MyLearning";
import MyLearningDetail from "./MyLearningDetail";
import Messages from "./Messages";
import MyDevelopment from "./MyDevelopment";
import SelectStudent from "./instructor_new_lesson/SelectStudent";
import Tests from "./Tests";


function App(props) {
  const location = useLocation();
  // location change listener
  useEffect(() => {
    // strip off stray mobile-open classes on body
    document.body.className = document.body.className.replace(
      "modal-window-open",
      ""
    );
    // scroll to top
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Auth>
        <Authorized>
          <Routes>
            <Route 
              path={process.env.PUBLIC_URL + "/onboarding"} 
              element={<Onboarding />} 
            />
            <Route
              path={process.env.PUBLIC_URL + "/sandbox"}
              element={<Sandbox />}
            />
            <Route 
              path={process.env.PUBLIC_URL + "/home"} 
              element={<Home chatbot={true}/>} 
            />
            <Route 
              path={process.env.PUBLIC_URL + "/chatbot"} 
              element={<Home chatbot={true}/>} 
            />
            <Route
              path={process.env.PUBLIC_URL + "/mylearning"}
              element={<MyLearning />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/mylearning/detail"}
              element={<MyLearningDetail />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/profile"}
              element={<Profile />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/students"}
              element={<Students />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/student/:studentId"}
              element={<StudentDetail />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/student/:studentId/learningplan"}
              element={<StudentDetail plan={true}/>}
            />
            <Route
              path={process.env.PUBLIC_URL + "/teachers"}
              element={<Teachers />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/programs"}
              element={<Programs />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reports"}
              element={<Reports />}
            />
            <Route 
              path={process.env.PUBLIC_URL + "/mydevelopment"}
              element={<MyDevelopment/>}
            />
            <Route
              path={process.env.PUBLIC_URL + "/courses"}
              element={<Courses />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/assignments"}
              element={<Assignments />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/course/:courseId/:lessonId/:assignmentId"}
              element={<AssignmentDetail />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/course/:courseId"}
              element={<CourseDetail />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/course/:courseId/:lessonId"}
              element={<Lesson />}
            />
             <Route
              path={process.env.PUBLIC_URL + "/course/:courseId/newlesson"}
              element={<NewLesson/>}
            />
             <Route
              path={process.env.PUBLIC_URL + "/course/:courseId/selectstudent"}
              element={<SelectStudent/>}
            />
            <Route
              path={process.env.PUBLIC_URL + "/explore"}
              element={<Explore />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/games"}
              element={<Games />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/mycontent"}
              element={<Content />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/sign-out"}
              element={<SignOut />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/messages"}
              element={<Messages />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/tests"}
              element={<Tests />}
            />
          </Routes>
        </Authorized>
        <Unauthorized>
          <SignIn />
        </Unauthorized>
      </Auth>
    </>
  );
}

export default App;
