import React from "react";
import Icon from "./Icon";

const OverviewCard = (props) => {

    return (
        <div className="card overview rounded">
               {props.info && <button className="icon-button info-btn" aria-label="info">
                    <span className="info-icon">
                        <Icon icon="info" size="18"/>
                    </span>
                </button> }
                <span className="label">{props.title}</span>
                    <span className="number">{props.highlight}</span>
                    {props.trending && 
                        <span className="chip-highlight">
                            {props.trending.amount} 
                            <span className={props.trending.direction}>{props.trending.direction === "up" ? <Icon icon="trending-up" size="18"/> : props.trending.direction === "down" ?<Icon icon="trending-down" size="18"/> : <Icon icon="trending-none" size="18"/> }</span>
                        </span>}
                {props.trending && 
                <div className={`graph-container trend-${props.trending.direction}`}>
                    {props.trending.direction === "up" ? 
                   <svg width="162" height="23" viewBox="0 0 162 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M1 19.4571L9.59504 10.7143L12.9008 13.8L15.5455 5.57143L18.1901 7.62857H21.4959L26.7851 16.8857L30.0909 14.8286L35.3802 19.4571L38.6859 12.7714L41.3306 16.8857L44.6364 12.2571L46.6198 18.4286H49.9256L52.5702 15.3429L55.2149 16.8857L63.8099 16.3714L67.1157 21L71.0826 14.8286H73.0661L77.0331 18.4286L84.3058 14.8286H86.9504L90.2562 6.6H92.9008L95.5455 13.8L98.8512 15.8571L101.496 3L106.785 16.3714L112.736 14.3143L115.38 10.7143L121.331 7.62857L127.281 10.7143L133.231 7.11428H135.876L138.521 5.05714L141.165 12.7714L144.471 6.08571L147.116 13.2857L149.76 8.65714L153.066 7.11428L155.711 14.3143L161 6.6" stroke="currentCOlor" stroke-width="1.5"/>
                   </svg>
                   
                 : props.trending.direction === "down" ? 
                 <svg width="162" height="23" viewBox="0 0 162 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1 19.4571L9.59504 10.7143L12.9008 13.8L15.5455 5.57143L18.1901 7.62857H21.4959L26.7851 16.8857L30.0909 14.8286L35.3802 19.4571L38.6859 12.7714L41.3306 16.8857L44.6364 12.2571L46.6198 18.4286H49.9256L52.5702 15.3429L55.2149 16.8857L63.8099 16.3714L67.1157 21L71.0826 14.8286H73.0661L77.0331 18.4286L84.3058 14.8286H86.9504L90.2562 6.6H92.9008L95.5455 13.8L98.8512 15.8571L101.496 3L106.785 16.3714L112.736 14.3143L115.38 10.7143L121.331 7.62857L127.281 10.7143L133.231 7.11428H135.876L138.521 5.05714L141.165 12.7714L144.471 6.08571L147.116 13.2857L149.76 8.65714L153.066 7.11428L155.711 14.3143L161 6.6" stroke="currentCOlor" stroke-width="1.5"/>
                 </svg>
                 
                : <svg width="162" height="23" viewBox="0 0 162 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 19.4571L9.59504 10.7143L12.9008 13.8L15.5455 5.57143L18.1901 7.62857H21.4959L26.7851 16.8857L30.0909 14.8286L35.3802 19.4571L38.6859 12.7714L41.3306 16.8857L44.6364 12.2571L46.6198 18.4286H49.9256L52.5702 15.3429L55.2149 16.8857L63.8099 16.3714L67.1157 21L71.0826 14.8286H73.0661L77.0331 18.4286L84.3058 14.8286H86.9504L90.2562 6.6H92.9008L95.5455 13.8L98.8512 15.8571L101.496 3L106.785 16.3714L112.736 14.3143L115.38 10.7143L121.331 7.62857L127.281 10.7143L133.231 7.11428H135.876L138.521 5.05714L141.165 12.7714L144.471 6.08571L147.116 13.2857L149.76 8.65714L153.066 7.11428L155.711 14.3143L161 6.6" stroke="currentCOlor" stroke-width="1.5"/>
                </svg>
                }
                </div> }
            

            
        </div>
    )
}
export default OverviewCard