/** @format */

import React, { useState, useEffect } from 'react';
import Avatar from '../global/Avatar';

const MessageUser = (props) => {

	/**change the Avatar size on smaller screen */
	const [smallerScreen, setSmallerScreen] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setSmallerScreen(window.innerWidth < 900);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<div className={`msg-card ${props.variant}`}>
				<Avatar
					outline="white"
					size={smallerScreen ? 'small' : 'medium'}
					user={props.user}
				/>
				<div className="msg-container">
					<p className="name">
						{`${props.user.firstName} ${props.user.lastName}`}
					</p>
					<p className="title">{`${props.randomText}`}</p>

					<p className="msg-content">{props.randomText}</p>
				</div>

				<div className="chip-container">
					<span
						className={`chip-highlight ${
							props.user.status === 'busy'
								? 'pink'
								: props.user.status === 'online'
								? 'teal'
								: ''
						}`}
					>
						{props.user.status}
					</span>
				</div>
			</div>
		</>
	);
};

export default MessageUser;
