import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "./global/Icon";
import PageWrapper from "./global/PageWrapper";

const Assignments = (props) => {
  const user = useSelector((state) => state.auth).userdata;
  const courses = useSelector((state) => state.courses);
  const [filteredAssignments, setFilteredAssignments] = useState([])

  // console.log(user.cohort)


  useEffect(()=>{
    if(courses){
      let allAssignments = []
      courses.forEach(course=>{
        let lessons = course.lessons
        lessons.forEach(lesson=>{
          let assignments = lesson.assignments
          assignments.forEach(assignment=>{
            let assignmentCopy = {...assignment}
            assignmentCopy.courseName = course.cefrLevel + ' ' + course.name + ' (' + course.ageGroup + ')'
            assignmentCopy.courseId = course.id
            assignmentCopy.lessonId = lesson.id
            allAssignments.push(assignmentCopy)
            // console.log(assignmentCopy.courseId)
          })
        })
      })
      let filteredArray = allAssignments.filter(assignment => user.cohort.includes(assignment.courseId))
      setFilteredAssignments(filteredArray)
    }
  },[courses])

  return (
    <PageWrapper>
      <div className="assignments">
        <h3>Assignments</h3> 
        {
          filteredAssignments.map((assignment, index)=>{
            return (
              <div className="schedule-card" key={index}>
                  <div>
                    <h5>{assignment.name}</h5>
                    <p>{assignment.courseName}</p>
                    <p><Icon icon="time" size="18"/> {assignment.duration} {assignment.timeUnit}</p>
                    <p>{assignment.points} points</p>
                  </div>
                  <Link to={'/course/'+assignment.courseId+"/"+assignment.lessonId+"/"+assignment.id} className="button secondary">View assignment</Link>
              </div>
            )
          })
        }
      </div>

      {/* <section>

        {courses.map((course, index) => {

          return (
            <div className="schedule-card" key={"assignment"+index}>
              <div className="date">
                <span>{course.courseStartMonth}</span>
                <span>{course.courseStartDay}</span>
            </div>
                <div>
                  <h5>{course.assignment}</h5>
                  <p>{course.cefrLevel} {course.name} ({course.ageGroup})</p>
                  <p><Icon icon="time" size="18"/> {course.assignmentDuration} {course.assignmentTimeUnit}</p>
                  <p>{course.assignmentPoints} points</p>
                </div>
                <Link to={'/course/'+course.id+'/assignment'} className="button secondary">View assignment</Link>
            </div>
          )
        })}

      </section> */}
    </PageWrapper>
  );
};

export default Assignments;
