// breadcrumbs
import { Link } from 'react-router-dom';

const Breadcrumbs = (props) => {
	
    const sampleData = [
        {
            page:"Classes",
            target: "/classes"
        },
        {
            page:"B1+ General English (Teens)",
            target: "/classes"
        },
        {
            page:"Lesson 03",
            target: ""
        }
    ]

     return (
        <div className="breadcrumb-container">

            {props.data.map((page,index) => {

                if (index < props.data.length - 1) {
                    return (
                        <span key={index} className='crumb'>
                            <Link to={page.target}>
                                {page.page}
                            </Link>
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                    )
                } else {
                    return (
                        <span key={index} className='crumb active'>
                            {page.page}
                        </span>
                    )
                }

                
            })}
           
        
            
        </div>
    )
}

export default Breadcrumbs