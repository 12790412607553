import React, {useState} from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import coin from "../../assets/images/gold-coin.png"


const LineChart = props => {
    // const [data, setData] = useState(props.data)
//   static demoUrl = 'https://codesandbox.io/s/simple-area-chart-4ujxw';
let [posData, setposData] = useState({});

    const CustomTooltip = ({ active, payload, label }) => {
        // console.log( payload)
        if (active && payload && payload.length) {
        return (
            <div className={props.type === "journey" ? "timeline-tooltip journey" : "timeline-tooltip"}>
                {props.type === "journey" ? 
                    <>
                     <div className='test-info'>
                     <p className="label">{`100 hours of teaching!`}</p>
                    <p className='test-date'>Completed: <span className='blue'>{label}</span></p>
                </div>
                <div className='image coin'>
                    <img src={coin} alt="gold coin"/>
                </div>
                    </>
                    : <>
                     <div className='test-info'>
                     <p className="label">{`${payload[0].payload.test}`}</p>
                    {payload[0].payload.desc ? <p className="desc">{`${payload[0].payload.desc}`}</p> : <></>}
                    <p className='test-date'>{label}</p>
                </div>
                <div className='test-badge'>
                    <p className="value">
                        <span>{`${payload[0].value}`}</span>
                        <span className='gse'>GSE</span>
                    </p>
                </div>
                </>
                }
               
           
            </div>
        );
        }
    
        return null;
    };
  
    return (
        
            <ResponsiveContainer width="100%" height={250}>
            <AreaChart
                width={500}
                height={400}
                data={props.data}
                margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
                }}
            >
                <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={props.fill ? props.fill : "#010676"} stopOpacity={0.5}/>
                <stop  offset="100%" stopColor={props.fill ? props.fill : "#014576"} stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="paint0_linear_1609_20075" x1="0" y1="0" x2="1" y2="1">
                <stop stop-color="#0CD6CB"/>
                <stop offset="1" stop-color="#597FFF"/>
                </linearGradient>
            </defs>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="name"  interval="preserveStartEnd"  tickLine={false} axisLine={false} overflow={"allow"} dy={5}/>
                <YAxis  dataKey="gse" hide={true} domain={[0,100]} /> 
                <Tooltip  cursor={false}  content={<CustomTooltip/>} position={{ x: posData.x - 200, y: posData.y - 40 }}/>
                <Area type="monotone" dataKey="gse" strokeWidth={4} strokeLinecap="round" stroke="#212E47" fill="url(#colorUv)" minTickGap={10} 
                    activeDot={{ className:'active-dot',fill: `${props.fill ? props.fill :'url(#paint0_linear_1609_20075)'}`, stroke:'none', fillOpacity:1, r: 8 }} 
                    dot={{ fill: '#212E47', stroke:'none', fillOpacity:1, r: 8 }}
                    
                    />
            </AreaChart>
            </ResponsiveContainer>
   
    );
  
}
export default LineChart
