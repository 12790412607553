import React from "react";
import Icon from "../global/Icon";
import placeholder from "../../assets/images/recommendations/classroom.png"
const MaterialSuggestions = () => {
    let materials = [
        {
            type: "Activity",
            objective: "<strong>Objective:</strong> Develop storytelling skills and creativity through the creation of a short story.",
            saved: false
        },
        {
            type: "Activity",
            objective: "<strong>Objective:</strong> Develop persuasive writing skills by creating a business proposal that aims to...",
            saved: false
        },
        {
            type: "Podcast",
            objective: "<strong>Critical Thinking and Analysis:</strong> Students learn to think critically and analyze texts to develop their...",
            saved: true
        },
        {
            type: "Podcast",
            objective: "<strong>Cultural and Historical Contexts:</strong> Literature is often studied in its cultural and historical context.",
            saved: true
        }
    ]
    return (
        <div className="material-group">
            {materials.map((item, index) => {
                return (
                    <div className="card material-card" style={{background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(99.71deg, #000000 1.54%, rgba(0, 0, 0, 0) 100.73%), url(' + placeholder + ')'}} key={"material" + index}>
                        <span className={`chip-highlight tag-${item.type.toLowerCase()}`}>{item.type}</span>
                        <div className="description">
                            <Icon icon={item.type.toLowerCase() === "activity" ? "user-group" : "listen"} size="24"/>
                            <p dangerouslySetInnerHTML={{__html: item.objective}}/>
                        </div>
                        {!item.saved && <button className="icon-button add-btn" aria-label="Add to agenda"><Icon icon="add-item" size="24"/></button>}
                    </div>
                )
            })}
            
        </div>
    )
}
export default MaterialSuggestions