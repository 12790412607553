// sessionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: "session",
  initialState: null,
  reducers: {
    setSessionData: (state, action) => {
      state = action.payload;
      // Save the session data to browser storage
      localStorage.setItem("sessionData", JSON.stringify(state));
      return state;
    },
    // clearSessionData: () => {
    //   // Clear the session data from browser storage
    //   localStorage.removeItem("sessionData");
    //   return null;
    // },
    loadSessionData: (state) => {
      // Load the session data from browser storage
      const sessionData = localStorage.getItem("sessionData");
      return sessionData ? JSON.parse(sessionData) : null;
    },
    sessionLogout: (state) => {
      // Reset the state to initial values
      return {};
    },
  },
});

export const { setSessionData, loadSessionData, sessionLogout } =
  sessionSlice.actions;

export default sessionSlice.reducer;
