import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import ScheduleCard from "./ScheduleCard";
import PillToggle from "../global/PillToggle";

const ScheduleList = (props) => {

    const user = useSelector((state) => state.auth).userdata;
    const courses = useSelector((state) => state.courses);
    const [allLessons, setAllLessons] = useState([])
    const [filteredLessons, setFilteredLessons] = useState([])

    const tabs = [
        'Today',
        'This week',
        'This month',
        'This year'
    ]

    useEffect(()=>{
        if(courses){
            let newArray = []
            courses.forEach(course=>{
                let lessons = course.lessons
                course.lessons.forEach(lesson=>{
                    //modifying the object data so that I can pass all the course information inside the particular lesson to SchduleCard
                    let lessonCopy = {...lesson}
                    let courseCopy = {...course}
                    delete courseCopy.lessons;
                    lessonCopy.course = courseCopy
                    newArray.push(lessonCopy)
                })
            })
            setAllLessons(newArray)
        }
    },[courses])

    useEffect(()=>{
        if(allLessons.length > 0){
            getTodaysLessons()
        }
    },[allLessons])

    const getTodaysLessons = () => {
        let today = new Date()
        let dayCheck = today.getDay()
        
        //if you're testing this prototype over the weekend
        //change "today" to be Monday
        if(dayCheck === 0){
            today.setDate(today.getDate() + 1);
        } 
        else if(dayCheck === 6){
            today.setDate(today.getDate() + 2);
        }
        // console.log(today.getDate().toString())
        // console.log(new Date(lesson.date))
        // allLessons.forEach(lesson=>console.log(lesson.course.cefrLevel + lesson.course.name + lesson.course.ageGroup + new Date(lesson.date).getDate().toString()))
        let filteredLessons = allLessons.filter(lesson => new Date(lesson.date).getDate().toString() === today.getDate().toString())
        filteredLessons = filteredLessons.sort((a, b)=>{
            let aStart = Date.parse(`01/01/2011 ${a.startTime}`)
            let bStart = Date.parse(`01/01/2011 ${b.startTime}`)
            if (aStart < bStart) {
                return -1;
              }
              if (aStart > bStart) {
                return 1;
              }
              // a must be equal to b
              return 0;
        })
        setFilteredLessons(filteredLessons)
    }

    const getThisWeeksLessons = () => {
        let filteredLessons = allLessons.filter(lesson => {
            // let lessonDateTime = new Date(lesson.date).getTime().toString()
            // let todayTime = new Date().getTime().toString()
            // let endOfWeek = new Date()
            // endOfWeek.setDate(endOfWeek.getDate() + 7)
            // let endOfWeekTime = endOfWeek.getTime().toString()
            // if(lessonDateTime >= todayTime && lessonDateTime <= endOfWeekTime) {
            //     return lesson
            // }
            let lessonDateTime = new Date(lesson.date).getDate().toString()
            let todayTime = new Date().getDate().toString()
            let endOfWeek = new Date()
            endOfWeek.setDate(endOfWeek.getDate() + 7)
            let endOfWeekTime = endOfWeek.getDate().toString()
            if(lessonDateTime >= todayTime && lessonDateTime <= endOfWeekTime) {
                return lesson
            }
        })
        setFilteredLessons(filteredLessons)     
    }

    const handleTabClick = (e) => {
        let tab = e.target.innerText
        switch(tab) {
            case 'Today':
              getTodaysLessons()
              break;
            case 'This week':
                // getThisWeeksLessons()
              break;
            case 'This month':
              // code block
              break;
            case 'This year':
                // code block
                break;  
            default:
              // code block
          }
    }

    return (
       <div className="schedule-list">
        <h3>My Schedule</h3>
        <PillToggle tabs={tabs} handleClick={handleTabClick}/>
        {
            filteredLessons.map((lesson, index)=>{
                if(index < 6){
                    return <ScheduleCard key={index} course={lesson.course} lesson={lesson}/>
                }
            })
        }
       </div>
    )
}

export default ScheduleList