import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

//images
import pal from "../../assets/images/brand/pal.svg"

const PALRecommendationData = [
    {
        role: 'student',
        title: 'You have 1 assignment due',
        subtitle: "Need help? I'm here for you.",
        buttonText: 'Start assignment',
        link: '/course/ENG-TNS-B1P-000/L1/A1'
    }, 
    {
        role: 'instructor',
        title: 'You have 2 new messages!',
        subtitle: "They're from Rico Almeida and Anna.",
        buttonText: 'Go to your messages',
        link: '/messages'
    }, 
    {
        role: 'administrator',
        title: 'You have 1 new student registration',
        subtitle: "Let's get them onboarded!",
        buttonText: 'See student',
        link: '/student/STU-000'
    }
]

const PALRecommendation = (props) => {

    const user = useSelector((state) => state.auth).userdata;
    
    let [data, setData] = useState(PALRecommendationData[0])
    
    useEffect(()=>{
        if(user){
            PALRecommendationData.forEach(obj=>{
                if(obj.role === user.occupation){
                    setData(obj)
                }
            })
        }
    },[user])

    return (
        <div className="pal-rec-container">
            <div className="pal-badge">
                <img src={pal}/>
                <span><span>PAL</span> Your virtual assistant</span>
            </div>
            <div className="pal-rec-body">
                <div>
                    <h3>{data.title}</h3>
                    <p>{data.subtitle}</p>
                </div>
                <Link className="primary" to={process.env.PUBLIC_URL + data.link}>{data.buttonText}</Link>
            </div>
        </div>
    )
}

export default PALRecommendation