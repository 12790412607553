import React, { useState } from "react";
import location from "../../assets/images/icons/location-teal.svg"
import Avatar from "./Avatar";
import Icon from "./Icon";

const PersonDetailHeader = (props) => {
    const person = props.user;
    const [loading, setLoading] = useState(false)


    const sentPlacementTest = () => {
        setLoading(true)
        setTimeout(() => {   
            props.click()
            setLoading(false)
        }, 1000)
    }

    return (
        <section className={`intro person-details-banner ${props.type}`}>
                <div className="person-info">
                    <Avatar outline='white' size='large' user={person}/>
                    <div className="person-description">
                        <h1 className="name">{person.firstName} {person.lastName}</h1>
                        <div className="location">
                            <img src={location}/>
                            <span>{`${person.city}, ${person.country}`}</span>
                        </div>
                        <p className="level">English level: {person.englishLevel ? person.englishLevel : "A1Genera"}</p>
                        <p className="sublevel">Enrolled in: {person.enrollments.length > 1 ? person.enrollments.length + " courses" : person.enrollments.length === 1 ? "1 course" : "No courses"}</p>
                        <div className="chip-list interest-list">
                            <div className="interest tag dark">🍕 Food</div>
                            <div className="interest tag dark">⚽ Soccer</div>
                            <div className="interest tag dark">📖 Fiction Stories</div>
                            <div className="interest tag dark">🗞️ Politics</div>
                            <div className="interest tag dark">🔬 Science</div>
                        </div>
                    </div>
                </div>

                {!props.plan && 
                <div className="profile-actions">
                    <button className="icon-button icon-left">
                        <Icon icon="edit" size="18"/>
                        <span>Edit profile</span>
                    </button>
                    {/* Add logic for when learning plan is available */}
                    {props.test ? 
                        <button className="button secondary grey fadeIn" disabled style={{whiteSpace:"nowrap"}}><span className="icon" style={{verticalAlign:"middle"}}><Icon icon="correct" size="24"/></span> Placement test sent </button>
                    : 
                    <button className="button primary" onClick={() => sentPlacementTest()} style={{position:"relative"}}>
                        {loading ? <><div className="loader" style={{position:"absolute",left:"calc(50% - 8px)"}}></div><span style={{opacity:0}}>Send placement test</span></>
                             : "Send placement test"}
                        
                        </button> 
                    }
                    
                    
                </div> }
            </section>
    )

}
export default PersonDetailHeader