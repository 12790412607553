import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDebounce } from "./hooks/useDebounce";

function SearchInput({ onSearch, setParentSearchText, placeholder, isHeader }) {
  const [searchText, setSearchText] = useState("");
  const [debouncedTerm] = useDebounce(searchText, 500);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    if (debouncedTerm) {
      if(setParentSearchText) {
        setParentSearchText(debouncedTerm);
      }
      // console.log("DebouncedTerm:", debouncedTerm);
    }
  }, [debouncedTerm]);

  const inputContainerStyle = {
    width: isHeader ? "100%" : "100%", // Set the width based on the isHeader prop, 774
    height: isHeader ? "52px" : "",
  };

  const iconPosition = isHeader
    ? "student-search-icon-container-header"
    : "student-search-icon-container";

  return (
    <div className="student-search-input">
      <div className={`student-input-container`} style={inputContainerStyle}>
        <div className={iconPosition}>
          <FontAwesomeIcon icon={faSearch} className="student-search-icon" />
        </div>
        <input
          type="text"
          value={searchText}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

export default SearchInput;
