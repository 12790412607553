import React, { useState } from "react";
import SearchInput from "../global/SearchInput";
import CheckList from "./CheckList";

const FilteredList = ({ checkLists }) => {
  const [searchText, setSearchText] = useState("");
  // const [checkedItems, setCheckedItems] = useState([]);

  const setNewSearchText = (searchText) => {
    setSearchText(searchText);
    // console.log("FilteredList: SearchText:", searchText);
  };

  const onSearch = (searchText) => {
    console.log(searchText);
  };
  return (
    <div>
      <SearchInput
        searchText={searchText}
        setParentSearchText={setNewSearchText}
        onSearch={onSearch}
        placeholder="Start Typing..."
      
      />
      <CheckList checkListNames={checkLists} />
    </div>
  );
};

export default FilteredList;
