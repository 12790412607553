import React from "react";
import OverviewCard from "../global/OverviewCard";

const Overview = (props) => {

    return (
       <section className="overview">
            <div className="section-header">
                <h3>Overview</h3>
                <div className="dropdowns">
 
                         <span className='text-body bold'>Courses:</span>
                        <div class="gr-form-element">
                            <div class="gr-select-container">
                                <select class="gr-select" id="selectOne">
                                    <option value="one">General English Adults</option>
                                </select>
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84765 7H12.859C13.4081 7 13.8533 7.44827 13.8533 8.00124C13.8533 8.26845 13.7472 8.52458 13.5587 8.71261L10.553 11.7101C10.1655 12.0966 9.54112 12.0966 9.15359 11.7101L6.14793 8.71261C5.75776 8.32349 5.75473 7.68955 6.14117 7.29667C6.32792 7.10681 6.58228 7 6.84765 7Z" fill="#020917"/>
                                </svg>
                            </div>
                        </div>


                        <span className='text-body bold'>Semester:</span>
                        <div class="gr-form-element">
                            <div class="gr-select-container">
                                <select class="gr-select" id="selectOne">
                                    <option value="one">Spring 2023</option>
                                </select>
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84765 7H12.859C13.4081 7 13.8533 7.44827 13.8533 8.00124C13.8533 8.26845 13.7472 8.52458 13.5587 8.71261L10.553 11.7101C10.1655 12.0966 9.54112 12.0966 9.15359 11.7101L6.14793 8.71261C5.75776 8.32349 5.75473 7.68955 6.14117 7.29667C6.32792 7.10681 6.58228 7 6.84765 7Z" fill="#020917"/>
                                </svg>
                            </div>
                        </div>

                </div>
            </div>
            <div className="section-body">
                <div className="card-row overflow-scroll">
                    <OverviewCard title="Students enrolled" highlight="58" trending={{amount:"+13", direction:"up"}} info={true} />
                    <OverviewCard title="Programs goals on track" highlight="94%" trending={{amount:"-2%", direction:"down"}} info={true} />
                    <OverviewCard title="Teacher Satisfaction Score" highlight="4.7/5" trending={{amount:"+0", direction:"none"}} info={true} />
                </div>
            </div>
       </section>
    )
}

export default Overview