import React from "react";
import AchievementRing from "../infographics/AchievementRing";

const AchievementCard = (props) => {

    return (
        <div className={props.data.progress === 100 ? "card achievement-card complete" : "card achievement-card"}>
            <h3 className="card-title">{props.data.title}</h3>
            <AchievementRing size="200" stroke="8" value={props.data.progress} icon={props.data.icon} fill={props.data.progress === 100 ? 1 : 0}/>
            
            <div className="desc" dangerouslySetInnerHTML={{__html: props.data.desc}}/>
           
        </div>
    )
}
export default AchievementCard