/** @format */

import React, { useState, useEffect } from 'react';

/**components */
import Card from '../../achieve_archive/global/cards/Card';
import ContentToggle from '../../students/ContentToggle';
import FilteredList from '../../students/FilteredList';

const SelectStudentFilterToggle = (props) => {
	const languageList = [
		'German',
		'Mandarin',
		'Portuguese',
		'Spanish',
		'Turkish',
		'Ukrainian',
	];
	const teacherList = ['Marcia de Silva', 'Steven Peters', 'Ximena Flora'];

	const countryList = [
		'Brazil',
		'China',
		'Germany',
		'Spain',
		'Turkey',
		'Ukraine',
		'Venezuela',
	];

	const allLists = [languageList, teacherList, countryList];

	return (
		<div className="student-two-column-grid">
			<div className="">
				{/* First Card */}
				<div className="student-filter-gap">
					<Card>
						<ContentToggle
							sectionName={''}
							header={'Native language'}
							showContent={true}
						>
							<FilteredList checkLists={allLists[0]} />
							<div className="student-filteredlist-link">
								<a href="#">More</a>
							</div>
						</ContentToggle>
					</Card>
				</div>

				{/* Second Card */}
				<div className="student-filter-gap">
					<Card>
						<ContentToggle
							sectionName={''}
							header={'Teacher'}
							showContent={true}
						>
							<FilteredList checkLists={allLists[1]} />
							<div className="student-filteredlist-link">
								<a href="#">More</a>
							</div>
						</ContentToggle>
					</Card>
				</div>
				{/* Third Card */}
				<div className="student-filter-gap">
					<Card>
						<ContentToggle
							sectionName={''}
							header={'Country of residence'}
							showContent={true}
						>
							<FilteredList checkLists={allLists[2]} />
							<div className="student-filteredlist-link">
								<a href="#">More</a>
							</div>
						</ContentToggle>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default SelectStudentFilterToggle;
