import { createSlice } from "@reduxjs/toolkit";
import semestersData from "./data/semesters";
import { getSemestersReducer} from "./reducers/semestersReducer";

const semestersSlice= createSlice({
    name: "semesters",
    initialState: semestersData,
    reducers: {
      getSemesters: getSemestersReducer,
      semestersLogout: (state) => {
        // Reset the state to initial values
        return [];
      },
    },
  });
  
  // Note: semestersSlice.actions automatically creates actions based on our reducer names
  export const {getSemesters, semestersLogout} = semestersSlice.actions;
  
  // export  reducer, so it can be added to the store
  export default semestersSlice.reducer;