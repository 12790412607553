/** @format */

import React from 'react';

const TextInput = (props) => {
	return (
		<div className="form-control new-lesson-input">
			<label htmlFor="input-text">{props.label}</label>
			<input
				className="gr-input"
				type={'text'}
				id="input-text"
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
			/>
		
		</div>
	);
};

export default TextInput;
