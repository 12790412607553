import { createSlice } from "@reduxjs/toolkit";
import { getCoursesReducer } from "./reducers/coursesReducer";
import coursesData from "./data/courses"
const coursesSlice = createSlice({
  name: "courses",
  initialState:coursesData,
  reducers: {
    getCourses: getCoursesReducer,
    coursesLogout: (state) => {
      // Reset the state to initial values
      return [];
    },
  },
});

// Note: coursesSlice.actions automatically creates actions based on our reducer names
export const { getCourses, coursesLogout } = coursesSlice.actions;

// export  reducer, so it can be added to the store
export default coursesSlice.reducer;
