/** @format */

import React from 'react';

const DateInput = (props) => {
	return (
		<div className="form-control new-lesson-input">
			<label htmlFor="input-date">{props.label}</label>
			<input
				className="gr-input"
				type={'date'}
				id="input-date"
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
			/>
		</div>
	);
};

export default DateInput;
