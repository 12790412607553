import React from "react";

const Icon = (props) => {
    const size = props.size
    return (
        <svg focusable="false" className={"icon-" + size} aria-hidden="true">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={"#" + props.icon + "-" + size}></use>
        </svg>
    )
}
export default Icon
