import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../achieve_archive/global/Toast";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import { checkAuthAsync } from "../../redux/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { setSessionData, loadSessionData } from "../../redux/sessionSlice";

import Logo from "../../assets/images/brand/pearson_logo_reverse.svg";

const SignIn = (props) => {
  const [showToastMessage, setShowToast] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  /**Get Session Data**/
  const sessionData = useSelector((state) => state.session);
  if (sessionData) {
    console.log("SignIn: SessionData:", sessionData);
  } else {
    // console.log("SignIN: No Session Data");
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // perform a login call, then change the state on authslice
    const date = +new Date();
    let response = await fetch(`${AUTH_BASE_URL}auth/session/?d=${date}`, {
      // let response = await fetch(`${AUTH_BASE_URL}auth/session`, {
      method: "POST",
      body: JSON.stringify({
        data: {
          username: username,
          password: password,
          loginType: "native",
        },
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (response.ok) {
      // Create sessionData in redux (Does not persist on refresh)
      dispatch(setSessionData(data));
      // Check user authorization
      dispatch(checkAuthAsync());
      // Load user sessionData to local storage (Data persists on refresh)
      dispatch(loadSessionData()); // Dispatch the loadSessionData action
      
      // navigate to dashboard and then fire this event
      console.log(data)
      if(data.email === 'newstudent@gmail.com' || data.email === "rico.almeida.06@gmail.com"){
        navigate(`${process.env.PUBLIC_URL}/onboarding?step=1`, { replace: true });
      }else{
        navigate(`${process.env.PUBLIC_URL}/home`, { replace: true });
      }
    } else {
      // console.log("Not in DB Json:", json)
      setLoadingState(false);
      setShowToast(data.message);
      setTimeout(() => {
        setShowToast(null);
      }, 3000);
    }
  };

  const handleCloseToast = () => {
    setShowToast(null);
  };
  return (
    <div className="auth-page sign-in">
      {showToastMessage != null ? (
        <Toast
          closeToast={handleCloseToast}
          message={showToastMessage}
          type="alert"
        />
      ) : null}
      <div className="login">
        <div className="logo">
          <img src={Logo} />
        </div>
        {/* <p className="text-h4 teal">Welcome to</p> */}
        <h2>Hi there!</h2>
        <p>If you’ve used Pearson before, you can sign in with the same details</p>
        <form onSubmit={handleSubmit}>
          <div className="form-input">
            <label htmlFor="username">Email address</label>
            <input
              id="username"
              type="text"
              className="gr-input"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              autoFocus
              placeholder='Email'
            />
          </div>
          <div className="form-input">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              className="gr-input"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              // ref={passWordInput}
              placeholder='Password'
            />
          </div>
          <span>or <a className="gradient-text bold">create an account</a></span>
          <button
            onClick={handleSubmit}
            type="submit"
            className="primary"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
