const semesters = [
 {
   "id": "SEM-WTR",
   "Name": "Winter",
   "Months": ["December", "January", "February"]
 },
 {
   "id": "SEM-SPR",
   "Name": "Spring",
   "Months": ["March", "April", "May"]
 },
 {
   "id": "SEM-SMR",
   "Name": "Summer",
   "Months": ["June", "July", "August"]
 },
 {
   "id": "SEM-FAL",
   "Name": "Fall",
   "Months": ["September", "October", "November"]
 }
];

export default semesters;