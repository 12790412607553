import React from "react";

const Checkbox = ({ label }) => {
  const onChange = (event) => {
    const { checked } = event.target;
    // console.log("Checkbox:", checked, "; Label:", label);
  };

  return (
    <div className="directory-check-list">
      <ul >
        <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <input
            type="checkbox"
            onChange={onChange}
            style={{ width: "18px", height: "18px", marginRight: "10px" }}
          />
          <label style={{ color: "black" }}>{label}</label>
        </li>
      </ul>
    </div>
  );
};

export default Checkbox;
