const groups = [
 {
   "id": "GRP-YTS",
   "name": "Young Learners",
   "range": [8, 9, 10, 11, 12],
   "total": 8
 },
 {
   "id": "GRP-TNS",
   "name": "Teens",
   "range": [13, 14, 15, 16, 17, 18, 19],
   "total": 9
 },
 {
   "id": "GRP-ADS",
   "name": "Adults",
   "range": [20],
   "total": 8
 },
 {
   "id": "GRP-INS",
   "name": "Instructor",
   "range": [],
   "total": 3
 }
];

export default groups;