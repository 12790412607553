import React from "react";
import { useSelector} from "react-redux";

import Icon from "./global/Icon";
import OverviewCard from "./global/OverviewCard";
import RecommendationCard from "./global/RecommendationCard";
import DevelopmentProgress from "./infographics/DevelopmentProgress";
import ProgressBar from "./infographics/ProgressBar";
import ChatBox from "./pal/ChatBox";
import ProfileProgressBar from "./profile/ProfileProgressBar";
import PillToggle from "./global/PillToggle";

//images
import chevronRight from '../assets/images/icons/chevron-right.svg'
import pencilTeal from "../assets/images/icons/pencil-teal.svg"
import recommendation1 from "../assets/images/recommendations/classroom.png";
import recommendation2 from "../assets/images/recommendations/trinity.png";
import PersonCard from "./global/PersonCard";
import StudentList from "./instructor_lesson/StudentList";
import Breadcrumbs from "./global/Breadcrumbs";

const Sandbox = (props) => {

    const testUser = useSelector((state) => state.users)[3];
    const testUser2 = useSelector((state) => state.users)[4];
    const sampleData = [
        {
            page:"Classes",
            target: "/classes"
        },
        {
            page:"B1+ General English (Teens)",
            target: "/classes"
        },
        {
            page:"Lesson 03",
            target: ""
        }
    ]

    return (
        <div className="sandbox">
            <PillToggle/>
            <Breadcrumbs data={sampleData}/>
                <button className="primary">Primary</button>
                <button className="secondary">Secondary</button>
                <button className="icon-button icon-right">
                    <span>Edit</span>
                    <img src={chevronRight}/>
                </button>
                <button className="icon-button icon-left teal">
                    <img src={pencilTeal}/>
                    <span>Edit profile</span>
                </button>
                <div style={{margin:"20px 0"}}>
                    <h3>Inputs</h3>
                    
                    <div className="gr-checkbox">
                        <input type="checkbox" id="checkbox1"/>
                        <label htmlFor="checkbox1">Label for checkbox</label>
                    </div>
                    <div className="todo-checkbox">
                        <label htmlFor="checkbox2">
                            <span>Label for todos</span> <input type="checkbox" id="checkbox2"/></label>
                    </div>
                    <div className="form-control">
                        <label htmlFor="input1">Sample input</label>
                        <input className="gr-input" type={"text"} id="input1" placeholder="input"/>
                    </div>
                    <div className="form-control">
                        <label htmlFor="input2">Sample number input</label>
                        <input className="gr-input" type={"number"} id="input2" placeholder="input"/>
                    </div>
                    <div className="form-control">
                        <label htmlFor="search1">Search</label>
                        <div className="search-input">
                            <span className="search-icon"><Icon icon="search" size="18"/></span>
                            <input className="gr-input" type={"text"} id="search1" placeholder="Search for something..."/>
                        </div>
                    </div>
                    
                </div>
                <div className="pal-container">
                    <button className="pal-button" aria-label="Pal"></button>
                </div>

                <div className="pal-container">
                    <button className="pal-button" aria-label="Pal"></button>
                    <div className="pal-bubble">
                        <p>Hey! Need some help?</p>
                    </div>
                </div>

                <div style={{margin:"20px 0", maxWidth: "400px"}}>
                    <ProgressBar complete="2" total="7" />
                </div>
                <div style={{margin:"20px 0", maxWidth: "400px"}}>
                    <ProfileProgressBar value="70" />
                </div>

                <div style={{margin:"20px 0", maxWidth: "400px"}}>
                    <DevelopmentProgress size="217" stroke="32" value={"56"} />
                </div>
                <div style={{margin:"20px 0", maxWidth: "400px"}}>
                    <h3>Chips</h3>
                    <div className="flex-row">
                        <div className="chip-highlight">
                            Label text
                            <span className="up"><Icon icon="trending-up" size="18"/></span>
                        </div>

                        <span className="chip-highlight teal">This week</span>
                        <span className="chip-highlight pink">This week</span>
                        <span className="chip-highlight purple">This week</span>
                        <span className="chip-highlight outline">2 new skills</span>

                    </div>
                    <h3>Tags</h3>
                    <div className="flex-row">
                        <span className="tag border">Speaking</span>
                        <span className="tag">
                        🔬 Science
                        </span>
                        <span className="tag dark">
                        🔬 Science
                        </span>
                        <span className="tag content">Speaking</span>
                        <span className="tag gray">Listening</span>
                    </div>
                    <h3>Clickable tags</h3>
                    <div className="flex-row">
                        <span className="chip-btn">Interest name</span>
                        <a className="chip-btn">Skill name</a>
                    </div>
                </div>
                <div style={{margin:"20px 0", maxWidth: "400px"}}>
                    <OverviewCard title="Students enrolled" highlight="59" trending={{amount:"+13", direction:"up"}} info={true} />
                    <OverviewCard title="Students enrolled" highlight="59" trending={{amount:"-7", direction:"down"}} info={true} />
                    <OverviewCard title="Students enrolled" highlight="59" trending={{amount:"+0", direction:"none"}} info={true} />
                    <OverviewCard title="Level" highlight="B1+" trending={false} info={false} />
                </div>
                <div style={{margin:"20px 0"}}>
                    <div className="flex-row">
                        <PersonCard user={testUser} variant="light"/>
                        <PersonCard user={testUser2} variant="dark"/>
                    </div>
                    {/* <StudentList/> */}
                </div>
                {/* <ChatBox/> */}

                
        </div>
    )
}

export default Sandbox