import { createSlice } from "@reduxjs/toolkit";
import { getUsersReducer, updateUsersReducer} from "./reducers/usersReducer";
import usersData from "./data/users";

const usersSlice = createSlice({
    name: "users",
    initialState: usersData,
    reducers: {
      getUsers: getUsersReducer,
      updateUsers: updateUsersReducer,
      usersLogout: (state) => {
        // Reset the state to initial values
        return [];
      },
    },
  });

  // Note: usersSlice.actions automatically creates actions based on our reducer names
  export const {getUsers, updateUsers, usersLogout} = usersSlice.actions;
  // export const {addRecord: addRecordToStateSlice} = UsersSlice.actions;
  
  // export  reducer, so it can be added to the store
  export default usersSlice.reducer;
  