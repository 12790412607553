import React from "react";
import DevelopmentProgress from "../infographics/DevelopmentProgress";
import Icon from "../global/Icon"
import { Link } from "react-router-dom";

//images
import clock from "../../assets/images/icons/clock.svg"

const Development = (props) => {

    return (
        <section className="instructor-development">
            <div className="section-header">
                <h3 className="section-title">My Development</h3>
                <div className="dropdown-container">
                    <span className='text-body bold'>Courses:</span>
                    <div className="gr-form-element">
                        <div className="gr-select-container">
                            <select className="gr-select" id="selectOne">
                                <option value="one">Teaching skills</option>
                            </select>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.84765 7H12.859C13.4081 7 13.8533 7.44827 13.8533 8.00124C13.8533 8.26845 13.7472 8.52458 13.5587 8.71261L10.553 11.7101C10.1655 12.0966 9.54112 12.0966 9.15359 11.7101L6.14793 8.71261C5.75776 8.32349 5.75473 7.68955 6.14117 7.29667C6.32792 7.10681 6.58228 7 6.84765 7Z" fill="#020917"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-body">
                <p className="card-title" >Teaching skills stats</p>
                <div>
                    <div className="datavis-container" style={{margin:"20px", maxWidth: "400px"}}>
                        <DevelopmentProgress size="217" stroke="32" value={"56"} />
                    </div>
                    <div className="key">
                        <div>
                            <span className="clock">
                                <img src={clock}/>
                            </span>
                            <span className="text-body bold">32h</span>
                            <span className="text-body">Leadership</span>
                        </div>
                        <div>
                            <span className="clock">
                                <img src={clock}/>
                            </span>
                            <span className="text-body bold">41h</span>
                            <span className="text-body">Communication</span>
                        </div>
                        <div>
                            <span className="clock">
                                <img src={clock}/>
                            </span>
                            <span className="text-body bold">28h</span>
                            <span className="text-body">Conflict resolution</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-footer">
                <Link className="link-button" to={process.env.PUBLIC_URL + "/mydevelopment"}>
                    <span>More</span>
                    <span><Icon icon="chevron-right" size="18"/></span>
                </Link>
            </div>

            <div className="pal-container">
                    <button className="pal-button" aria-label="Pal"></button>
                    <div className="pal-bubble">
                        <p>Check out how many hours of self-study you've completed! You're more than half way through this course!</p>
                    </div>
                </div>
        </section> 
    )
}

export default Development
