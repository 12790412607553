import React from "react";
// import clock from "../../assets/images/student_explore/clock.svg";

import bookmark from "../../assets/images/student_explore/bookmark-btm-right.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faBookmark } from "@fortawesome/free-regular-svg-icons";

const PlaylistCard = ({ title, image, imageText, description }) => {
  const descriptionWithLineBreak = description.split("\n");
  return (
    <div className="rounded playlist-card">
        <div className="playlist-image">
          <img src={image} alt={imageText} />
        </div>
      <div className="card-body">

        <div className="card-header">
          <h5 className="card-title">{title}</h5>
        <span>CONTENT</span>
        </div>
        <hr className="playlist-hr"/>
        <p className="playlist-desc">
          {descriptionWithLineBreak.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>

      <div className="card-footer">
        <div className="clock-container">
          <FontAwesomeIcon className="playlist-clock-style" icon={faClock} />
          <span className="playlist-clock-text playlist-clock-style">50 mins</span>
        </div>
        <div className="bookmark-container">
          <FontAwesomeIcon className="playlist-clock-style play-list-bookmark" icon={faBookmark} />
        </div>
      </div>
    </div>
  );
};

export default PlaylistCard;
