import React from "react";
import PageWrapper from "./global/PageWrapper";
import UserCard from "./profile/UserCard";
import { useSelector} from "react-redux";
import ProfileProgressBar from "./profile/ProfileProgressBar";
import ProfileSkills from "./profile/ProfileSkills";
import ProfileAchievements from "./profile/ProfileAchievements";
import OverviewCard from "./global/OverviewCard";
import LearningTimeline from "./infographics/LearningTimeline";

const Profile = (props) => {
    const user = useSelector((state) => state.auth).userdata;
    // const testUser = useSelector((state) => state.users)[0];
    let softSkills = [
      {
        title: 'Writing',
        skills: ['Spelling', 'Grammar', 'Organization', 'Style'],
        progress: 65,
      },
      {
        title: 'Reading',
        skills: ['Decoding', 'Vocabulary', 'Comprehension'],
        progress: 55,
      },
      {
        title: 'Speaking',
        skills: ['Pronunciation', 'Fluency', 'Cohesion', 'Interaction'],
        progress: 40,
      },
      {
        title: 'Listening',
        skills: ['Contextual Understanding', 'Decoding', 'Discerning'],
        progress: 77,
      },
    ];

    let achievements = [
      {
        title: "Study Group",
        progress: 40,
        icon: "group",
        desc: "<p>Connected with <strong>4/10</strong> students</p>",
        fill: "teal"
      },
      {
        title: "Bookworm",
        progress: 65,
        icon: "bookworm",
        desc: "<p>Read <strong>13/20</strong> articles</p>",
        fill: "teal"
      },
      {
        title: "Student of the week",
        progress: 43,
        icon: "students",
        desc: "<p>Held top leaderboard position for <strong>3/7</strong> days</p>",
        fill: "teal"
      },
      {
        title: "Night Owl",
        progress: 0,
        icon: "night",
        desc: "<p>Studied past 12PM</p>",
        fill: "teal"
      },
      {
        title: "Almost real",
        progress: 100,
        icon: "buddies",
        desc: "<p>Meet a student on the Lingoverse</p>",
        fill: "gold"
      }
    ]
    let performance = [
      {
        title: "Proficiency",
        number: "87%",
        trending: {direction: "up", amount: "+3%"}
      },
      {
        title: "Engagement",
        number: "A+",
        trending: {direction: "up", amount: "+2 grade levels"}
      },
      {
        title: "Lessons completed",
        number: "34",
        trending: {direction: "down", amount: "-2"}
      }
    ]
    let interests = ["Science","Gardening","Technology","Hiking","Traveling", "Coding"]
    return (
       <PageWrapper page='profile'>
            <div className="profile">
            <div className="background"></div>
            <UserCard user={user} profile={true}/>
            {props.progress ? 
            <div className="profile-progress-card card rounded centered">
              <h3 className="card-title">Almost there!</h3>
              <p>Complete your profile to help us recommend you personalized content.</p>
              <ProfileProgressBar value="70"/>
              <div className="actions">
                <button className="button primary">Continue</button>
              </div>   
            </div> : 
            <section className="learning-timeline">
              <LearningTimeline/>
            </section>}
            <section className="profile-interests">
              <div className="section-header">
                <h2 className="section-title">Interests</h2>
              </div>
              <div className="section-body">
                <div className="chip-list">
                  {interests.map((interest, index) => {
                    return (
                      <div className="chip-label" key={"int"+index}>{interest}</div>
                    )
                  })}
                </div>
              </div>
            </section>
            <ProfileAchievements data={achievements}/>
            <ProfileSkills data={softSkills}/>
            {user.occupation === 'student' && 
            <section className="profile-performance">
              <div className="section-header">
                <h2 className="section-title">Performance 
                {/* <span className="chip-highlight teal">This week</span> */}
                </h2>
              </div>
              
              <div className="card-row overflow-scroll">
                {performance.map((item,index) => {
                  return (
                    <OverviewCard key={"item"+index} title={item.title} highlight={item.number} trending={item.trending} info={true}/>
                  )
                })}
              </div>
            </section> }
            </div>
      </PageWrapper>
    )
}

export default Profile
