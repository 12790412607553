// do an auth function, and then show unath view or auth view
import React, { useEffect } from "react";
import { Children } from "react";
import Authorized from "./Authorized";
import Unauthorized from "./Unauthorized";
import { useSelector, useDispatch } from "react-redux";
import { checkAuthAsync } from "../../redux/authSlice";
import LoaderGif from "../../assets/images/loading.gif";

function Auth({ children }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // console.log("AUTH:", auth);

  useEffect(() => {
    dispatch(checkAuthAsync());
  }, [dispatch]);

  Children.toArray(children);
  // TODO: this is a fragile mapping... probably could do better
  // 0 is authorized, 1 is not authorized
  let child;

  if (auth.loading) {
    child = (
      <div className="loading">
        <div className="innerLoading">
          <img src={LoaderGif} />
        </div>
      </div>
    );
  } else if (auth?.loggedin) {
    child = children[0];
  } else {
    child = children[1];
  }

  return child;
}

export { Auth, Unauthorized, Authorized };
