/** @format */

import React from 'react';

const TimeInput = (props) => {
	return (
		<div className="form-control new-lesson-input-time">
			<label htmlFor="input-time">{props.label}</label>
			<input
				className="gr-input"
				type={'time'}
				id="input-time"
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
			/>
		</div>
	);
};

export default TimeInput;
