import React from "react";
import SkillCard from "./SkillCard";

const ProfileSkills = (props) => {

    return (
        <section className="employee-skills">
            <h2 className="section-title">Skills 
                {/* <span className="chip-highlight outline">2 new skills</span> */}
                </h2>
            <div className="overflow-scroll">
                <div className="card-row">
                    {props.data.map((group,index) => {

                        return <SkillCard data={group} key={"group"+index}/>
                    })}
                </div>
                
            </div>

        </section>
    )
}
export default ProfileSkills