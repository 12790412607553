/** @format */

import React from 'react';
import Toggle from './input/Toggle';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LessonSetting = (props) => {
	return (
		<Accordion
			className={`accordion ${props.active ? 'active' : ''}`}
			onClick={props.onClick}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				className="summary"
			>
				<h3>Lesson settings</h3>
			</AccordionSummary>
			<AccordionDetails className="detail">
				<Toggle
					labelLeft="Online"
					labelRight="In-person"
				/>
				<div className="check-group">
					<div className="gr-checkbox">
						<label htmlFor="checkbox1">Is this lesson mandatory?</label>
						<input
							type="checkbox"
							id="checkbox1"
						/>
					</div>

					<div className="gr-checkbox">
						<label htmlFor="checkbox2">Will there be homework?</label>
						<input
							type="checkbox"
							id="checkbox2"
						/>
					</div>

					<div className="gr-checkbox">
						<label htmlFor="checkbox3">
							Request everyone’s attendance for this class?
						</label>
						<input
							type="checkbox"
							id="checkbox3"
						/>
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default LessonSetting;
