import React from "react";
import AchievementCard from "./AchievementCard";

const ProfileAchievements = (props) => {

    return (
        <section className="profile-achievements">
            <div className="overflow-scroll">
                <div className="card-row">
                    {props.data.map((group,index) => {

                        return <AchievementCard data={group} key={"group"+index}/>
                    })}
                </div>
                
            </div>

        </section>
    )
}
export default ProfileAchievements