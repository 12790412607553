import React from "react";
import SearchInput from "../global/SearchInput";

const DirectoryHeader = ({ title, btnName}) => {
  return (
    <div className="directory-header-wrapper">
      <h3 className="directory-header-title">{title}</h3>
      <div className="directory-header">
        <SearchInput 
        placeholder={`Search ${btnName} directory`}
        isHeader={true}
        />
        <button className="button primary student-btn">
        <span className="assignment-btn-text">Register a {btnName}</span>
      </button>
      </div>
    </div>
  );
};

export default DirectoryHeader;
