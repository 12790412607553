import React from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../global/Breadcrumbs";
// import PageWrapper from "./global/PageWrapper";
import LessonAgenda from "./LessonAgenda";
import StudentList from "./StudentList";
import PerformanceChart from "../infographics/PerformanceChart";
import Icon from "../global/Icon";
import MaterialSuggestions from "./MaterialSuggestions";

const TeacherLesson = (props) => {
    // const lesson = useSelector((state) => state.courses)[3];
    const users = useSelector((state) => state.users);

    const lesson = props.lesson;
    const course = props.course;
    const filteredUsers = users.filter((user) => user.cohort.includes(course.id));
    // console.log(lesson)

    const breadcrumbs = [
        {
            page:"Courses",
            target: "/courses"
        },
        {
            page: `${course.cefrLevel} ${course.name} (${course.ageGroup})`,
            target: `/course/${course.id}`
        },
        {
            page: lesson.name,
            target: ""
        }
    ]

    return (
        <div className="teacher-lesson">
            <Breadcrumbs data={breadcrumbs}/>
            <section className="lesson-intro">
                <h1 className="text-h1 lesson-title">{lesson.name}</h1>
                <p className="course-title">{course.cefrLevel} {course.name} ({course.ageGroup})</p>
            </section>
            <section>
                <LessonAgenda lesson={lesson} location={course.location}/>
            </section>
            <section>
                <StudentList data={filteredUsers} />
            </section>
            {/* <section>
                <div className="section-header">
                    <h3>Class performance</h3>
                </div>
                <div className="section-body light-bg">
                <PerformanceChart />
                </div>
                <div className="section-footer">
                <a className="link-button">
                    <span>Performance breakdown</span>
                    <span><Icon icon="chevron-right" size="18"/></span>
                </a>
            </div>
                
            </section> */}
            <section>
                <div className="section-header">
                    <h3>Additional material suggestions</h3>
                    <p>This content is suitable for your class level and performance. You can add it to your lesson’s agenda at any time.</p>
                </div>
                <div className="section-body">
                    <MaterialSuggestions/>
                    
                </div>
                <div className="section-footer">
                    <a className="link-button">
                        <span>More</span>
                        <span><Icon icon="chevron-right" size="18"/></span>
                    </a>
                </div>
                
            </section>
        </div>
    )


}
export default TeacherLesson