import React from "react";

const UpcomingCard = ({ title, author, authorImage, dateTime }) => {
  return (
    <div className="upcoming-card card">
      <div className="author">
            <img src={authorImage}/>
            <div>
              <span>with</span>
              <span>{author}</span>
            </div>
        </div>
        <h5>{title}</h5>
        <p className="date-time">{dateTime}</p>
        <button className="primary">Count me in</button>
    </div>
  );
};

export default UpcomingCard
