import React from "react";
import PageWrapper from "./global/PageWrapper";

const Reports = (props) => {

    return (
        <PageWrapper>
            <p>Reports</p>
        </PageWrapper>
    )
}

export default Reports