import React, {useEffect, useState, useRef} from "react";
import Icon from "../global/Icon";
import {CSSTransition} from "react-transition-group";

//images
import pal from "../../assets/images/brand/pal.svg";
import mic from "../../assets/images/icons/microphone.svg";
import info from "../../assets/images/icons/info-outline.svg";
import back from "../../assets/images/icons/arrow-left-teal.svg";

const ChatBox = (props) => {

    const [showChatBox, setShowChatBox] = useState(false)
    const [showTip, setShowTip] = useState(true)

    useEffect(()=>{
        setTimeout(()=>{
            setShowTip(false)
        },4000)
    },[])

    return (

        <div id="chatbotExample">
        
        <CSSTransition
                in={showChatBox}
                classNames='chat-box'
                timeout={600}
                unmountOnExit={true}
            >
        <div className={`chat-box`}>
            <div className="chat-box-header">
                <button className="icon-button back-btn" aria-label="Leave chat" onClick={()=>{setShowChatBox(false)}}>
                    {/* <span className="mobile-only"><Icon icon="back" size="24"/></span> */}
                    {/* <span className="desktop-only"><Icon icon="close" size="24"/></span> */}
                    <img src={back}/>
                </button>
                <div className="user-info">
                    <div className="pal-avatar">
                        
                        <span className="dot active"/>
                    </div>
                    <h3><span className="user-name">PAL</span> <span className="status">Always online</span></h3>
                </div>
                
                <button className="icon-button" aria-label="Info">
                    <img src={info}/>
                </button>


            </div>
            <div className="chat-box-body">
                <div className="message-group pal">
                    <div className="avatar pal">
                        <img src={pal} alt="pal"/>
                    </div>
                    <div className="messages">
                        {props.user.occupation === "student" ? <>
                        <div className="pal message">
                            <p>Hi {props.user.firstName}! 😊</p>
                            <p><strong>Sounds like you need some help?</strong></p>
                            <p>No problem, I’ll help you with your English homework assignment:</p>
                        </div>
                        <div className="pal message assignment highlight">
                            <p className="msg-assignment-type">Homework Assignment</p>
                            <p className="msg-assignment-title">Maintain and develop interaction</p>
                            <p className="msg-assignment-due">Due in 4 days</p>
                        </div>
                        <div className="pal message">
                            <p>Looks like you need to practice pronunciation. Let's have a conversation and practice together.</p>
                            <div className="action-row">
                                <button className="button primary">Let's go!</button>
                                <button className="button secondary blue">Maybe later</button>
                            </div>
                        </div>
                        </> : <div className="pal message">
                            <p>Hi {props.user.firstName}, I'm PAL.</p>
                            <p>What can I help you with today?</p>
                            {/* <p><strong>What can I help you with today?</strong></p> */}
                        </div>}
                    </div>

                </div>
                {/* <div className="message-group user">
                    <div className="messages">
                        <div className="user message">
                            <p>I need help with my homework.</p>
                        </div>
                    </div>
                    <div className="avatar user">
                        <span className="initials">UT</span>
                    </div>
                </div> */}


            </div>
            <div className="chat-box-footer">
                <button className="icon-button" aria-label="Speak">
                    <img src={mic}/>
                </button>
                <div className="chat-input">
                    <input type="text" placeholder="Type here to chat..." aria-label="Type here to chat"/>
                </div>
                <button className="emoji-button">👍</button>
            </div>

        </div>
        </CSSTransition>

          <div className="pal-container">
            <button className="pal-button" aria-label="Pal" onClick={() => setShowChatBox(true)}></button>
            <CSSTransition
                 in={showTip}
                 classNames='pal-bubble'
                 timeout={300}
                 unmountOnExit={true}
            >
                <div className="pal-bubble">
                    <p>Hey! Need some help?</p>
                </div>
            </CSSTransition>
            
          </div>
        </div>
    )
}
export default ChatBox