import React from "react";
import PageWrapper from "./global/PageWrapper";

const Programs = (props) => {

    return (
        <PageWrapper>
            <p>Programs</p>
        </PageWrapper>
    )
}

export default Programs
