import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "./Avatar"
import Icon from "../global/Icon";
import CourseProgressBar from "../global/CourseProgressBar";

//images
import star from "../../assets/images/icons/star.svg"
import usersIcon from "../../assets/images/icons/users-black.svg"
import chevronRight from "../../assets/images/icons/chevron-right.svg"

const CourseCard = ({course, student}) => {

    const user = useSelector((state) => state.auth).userdata;
    const users = useSelector((state) => state.users);

    let ageGroup = course.ageGroup.toUpperCase().replace(/\s/g, '');

    const filteredUsers = users.filter((user) => user.ageGroupId !==  'GRP-INS' && user.cohort.includes(course.id));

    const getDate = (date) => {
        let dateObj = new Date(date)
        let month = dateObj.getUTCMonth() + 1; //months from 1-12
        let day = dateObj.getUTCDate();
        let year = dateObj.getUTCFullYear();
        return month + "/" + day + "/" + year
    }

    return (
        <div className="card course-card">
            <div className="bookmark">
                <img src={star}/>
            </div>
            {   user.occupation === "student" ?
                 <h3>{course.name}</h3> :
                <h3>{course.cefrLevel} {course.name} ({course.ageGroup})</h3>
            }
            <div className="course-students">
                <img src={usersIcon}/>
                <div>
                    {
                        filteredUsers.map((user, index)=>{
                            return (
                                <Avatar key={index} user={user} size='small' outline="white"/>
                            ) 
                        })
                    }
                </div>
            </div>
            {/* {   user.occupation === "instructor" && user.userId === course.instructor ? 
                <p className="teal bold">You are teaching lessons in this course</p> 
                : null
            } */}
            {   user.occupation === "instructor" ? <>
                <div className="performance">
                    <p>Students in this course are <br/> performing
                    <span className="teal bold"> Above level </span>
                    </p>
                    {/* <Icon icon="trending-up" size="18"/> */}
                </div>
                <p>Course duration: {getDate(course.lessons[0].date)} - {getDate(course.lessons[course.lessons.length - 1].date)}</p>
                <p>Assigned course book: <strong>{course.book}</strong></p>
                <CourseProgressBar value="70"/>
                </> : null
            }
            {   user.occupation === "student" ? 
                <p><strong>Course details: </strong>{course.cefrDescriptions}</p> : null
             }
             <div className="card-footer">
                {
                    student ? 
                    <button className="primary">
                        Go to course
                    </button>
                    :
                    <Link className="primary" to={"/course/"+course.id}>
                        Go to course
                    </Link> 
                        
                       
                }
                {/* <Link className="primary" to={"/course/"+course.id}>
                    Go to course
                </Link> */}
            </div>
        </div>
    )
}

export default CourseCard
