import React from "react";
import { PieChart, Pie, Label, Cell, ResponsiveContainer } from 'recharts';
import Icon from "../global/Icon";

function ReadingLabel() {
    return (
        <g transform="translate(125 85)" transform-origin="50% 50%">
            <g>
                <text x="0" y="12" fontSize={22} textAnchor="middle" fontWeight={"bold"}>Reading</text>
                <g transform="translate(-10 50)">
                    <text fontSize={22} textAnchor="middle" fill="#1F897C" fontWeight={"bold"}>Above level</text>
                    <path  transform="translate(70 -16)" fill-rule="evenodd" clip-rule="evenodd" d="M21.766 4.43039L17.0788 4.43039V0.573242H26.7217H28.6502V2.50181V12.1447H24.7931V6.88777L17.0049 14.0314L15.7023 15.2262L14.3988 14.0325L10.5427 10.5012L2.9499 17.4267L0.350586 14.5769L9.24563 6.46363L10.5479 5.27583L11.8478 6.46622L15.7002 9.99416L21.766 4.43039Z" fill="#0CC0AD"/>
                </g>
            </g>
            
            <path transform="translate(110 0)" fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 8.70681C-0.0976311 8.31629 -0.0976311 7.68312 0.292893 7.2926L6.65685 0.928635C7.04738 0.538111 7.68054 0.538111 8.07107 0.928635C8.46159 1.31916 8.46159 1.95232 8.07107 2.34285L2.91548 7.49843C3.00102 7.64581 3.05 7.81704 3.05 7.9997C3.05 8.18237 3.00102 8.3536 2.91548 8.50097L8.07107 13.6566C8.46159 14.0471 8.46159 14.6802 8.07107 15.0708C7.68054 15.4613 7.04738 15.4613 6.65685 15.0708L0.292893 8.70681ZM5.25 7.9997C5.25 7.44742 5.69772 6.9997 6.25 6.9997H12.55C13.1023 6.9997 13.55 7.44742 13.55 7.9997C13.55 8.55199 13.1023 8.9997 12.55 8.9997H6.25C5.69772 8.9997 5.25 8.55199 5.25 7.9997ZM19.95 7.9997C19.95 7.44742 20.3977 6.9997 20.95 6.9997H23.05C23.6023 6.9997 24.05 7.44742 24.05 7.9997C24.05 8.55199 23.6023 8.9997 23.05 8.9997H20.95C20.3977 8.9997 19.95 8.55199 19.95 7.9997ZM33.55 8.9997H27.25C26.6977 8.9997 26.25 8.55199 26.25 7.9997C26.25 7.44742 26.6977 6.9997 27.25 6.9997H33.55C34.1023 6.9997 34.55 7.44742 34.55 7.9997C34.55 8.55199 34.1023 8.9997 33.55 8.9997ZM40.95 7.9997C40.95 7.44742 41.3977 6.9997 41.95 6.9997H44.05C44.6023 6.9997 45.05 7.44742 45.05 7.9997C45.05 8.55199 44.6023 8.9997 44.05 8.9997H41.95C41.3977 8.9997 40.95 8.55199 40.95 7.9997ZM47.25 7.9997C47.25 7.44742 47.6977 6.9997 48.25 6.9997H54.55C55.1023 6.9997 55.55 7.44742 55.55 7.9997C55.55 8.55199 55.1023 8.9997 54.55 8.9997H48.25C47.6977 8.9997 47.25 8.55199 47.25 7.9997ZM61.95 7.9997C61.95 7.44742 62.3977 6.9997 62.95 6.9997H64C64.5523 6.9997 65 7.44742 65 7.9997C65 8.55199 64.5523 8.9997 64 8.9997H62.95C62.3977 8.9997 61.95 8.55199 61.95 7.9997Z" fill="#01CBB7" />
            
        </g>
    )
}

function WritingLabel() {
    return (
        <g transform="translate(175 325)" transform-origin="50% 50%">
            <g>
                <text x="0" y="12" fontSize={22} textAnchor="middle" fontWeight={"bold"}>Writing</text>
                <g transform="translate(-10 50)">
                    <text fontSize={22} textAnchor="middle" fill="#E5067C" fontWeight={"bold"}>Below level</text>
                    <path  transform="translate(70 -16)" fill-rule="evenodd" clip-rule="evenodd" d="M21.5698 13.4884H17.0665V17.4884H26.7088H28.7088V15.4884V5.8455H24.7088V10.9398L17.041 3.90614L15.6902 2.667L14.3383 3.90505L10.5307 7.39214L2.98668 0.510742L0.291016 3.46597L9.18555 11.5793L10.5361 12.8112L11.8842 11.5766L15.688 8.09297L21.5698 13.4884Z" fill="#C55554"/>
                </g>
            </g>
            
            <path transform="translate(110 0)" fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 8.70681C-0.0976311 8.31629 -0.0976311 7.68312 0.292893 7.2926L6.65685 0.928635C7.04738 0.538111 7.68054 0.538111 8.07107 0.928635C8.46159 1.31916 8.46159 1.95232 8.07107 2.34285L2.91548 7.49843C3.00102 7.64581 3.05 7.81704 3.05 7.9997C3.05 8.18237 3.00102 8.3536 2.91548 8.50097L8.07107 13.6566C8.46159 14.0471 8.46159 14.6802 8.07107 15.0708C7.68054 15.4613 7.04738 15.4613 6.65685 15.0708L0.292893 8.70681ZM5.25 7.9997C5.25 7.44742 5.69772 6.9997 6.25 6.9997H12.55C13.1023 6.9997 13.55 7.44742 13.55 7.9997C13.55 8.55199 13.1023 8.9997 12.55 8.9997H6.25C5.69772 8.9997 5.25 8.55199 5.25 7.9997ZM19.95 7.9997C19.95 7.44742 20.3977 6.9997 20.95 6.9997H23.05C23.6023 6.9997 24.05 7.44742 24.05 7.9997C24.05 8.55199 23.6023 8.9997 23.05 8.9997H20.95C20.3977 8.9997 19.95 8.55199 19.95 7.9997ZM33.55 8.9997H27.25C26.6977 8.9997 26.25 8.55199 26.25 7.9997C26.25 7.44742 26.6977 6.9997 27.25 6.9997H33.55C34.1023 6.9997 34.55 7.44742 34.55 7.9997C34.55 8.55199 34.1023 8.9997 33.55 8.9997ZM40.95 7.9997C40.95 7.44742 41.3977 6.9997 41.95 6.9997H44.05C44.6023 6.9997 45.05 7.44742 45.05 7.9997C45.05 8.55199 44.6023 8.9997 44.05 8.9997H41.95C41.3977 8.9997 40.95 8.55199 40.95 7.9997ZM47.25 7.9997C47.25 7.44742 47.6977 6.9997 48.25 6.9997H54.55C55.1023 6.9997 55.55 7.44742 55.55 7.9997C55.55 8.55199 55.1023 8.9997 54.55 8.9997H48.25C47.6977 8.9997 47.25 8.55199 47.25 7.9997ZM61.95 7.9997C61.95 7.44742 62.3977 6.9997 62.95 6.9997H64C64.5523 6.9997 65 7.44742 65 7.9997C65 8.55199 64.5523 8.9997 64 8.9997H62.95C62.3977 8.9997 61.95 8.55199 61.95 7.9997Z" fill="#E5067C" />
            
        </g>
    )
}

function ComprehensionLabel() {
    return (
        <g transform="translate(775 125)" transform-origin="50% 50%">
            <g transform="translate(110 0)">
                <text x="0" y="12" fontSize={22} textAnchor="middle" fontWeight={"bold"}>Comprehension</text>
                <g transform="translate(-10 50)">
                    <text fontSize={22} textAnchor="middle" fill="#EEA42E" fontWeight={"bold"}>At level</text>
                    <path  transform="translate(60 -16)" fill-rule="evenodd" clip-rule="evenodd" d="M16.7162 7.06608L13.1832 3.53313L15.9166 0.799805L22.7499 7.63312L24.1165 8.99978L22.7499 10.3664L15.9166 17.1998L13.1832 14.4664L16.7181 10.9316L0.882867 10.9318L0.882812 7.06631L16.7162 7.06608Z" fill="#A6A6A6"/>
                </g>
            </g>
            
            <path transform="translate(0 40) rotate(180)" fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 8.70681C-0.0976311 8.31629 -0.0976311 7.68312 0.292893 7.2926L6.65685 0.928635C7.04738 0.538111 7.68054 0.538111 8.07107 0.928635C8.46159 1.31916 8.46159 1.95232 8.07107 2.34285L2.91548 7.49843C3.00102 7.64581 3.05 7.81704 3.05 7.9997C3.05 8.18237 3.00102 8.3536 2.91548 8.50097L8.07107 13.6566C8.46159 14.0471 8.46159 14.6802 8.07107 15.0708C7.68054 15.4613 7.04738 15.4613 6.65685 15.0708L0.292893 8.70681ZM5.25 7.9997C5.25 7.44742 5.69772 6.9997 6.25 6.9997H12.55C13.1023 6.9997 13.55 7.44742 13.55 7.9997C13.55 8.55199 13.1023 8.9997 12.55 8.9997H6.25C5.69772 8.9997 5.25 8.55199 5.25 7.9997ZM19.95 7.9997C19.95 7.44742 20.3977 6.9997 20.95 6.9997H23.05C23.6023 6.9997 24.05 7.44742 24.05 7.9997C24.05 8.55199 23.6023 8.9997 23.05 8.9997H20.95C20.3977 8.9997 19.95 8.55199 19.95 7.9997ZM33.55 8.9997H27.25C26.6977 8.9997 26.25 8.55199 26.25 7.9997C26.25 7.44742 26.6977 6.9997 27.25 6.9997H33.55C34.1023 6.9997 34.55 7.44742 34.55 7.9997C34.55 8.55199 34.1023 8.9997 33.55 8.9997ZM40.95 7.9997C40.95 7.44742 41.3977 6.9997 41.95 6.9997H44.05C44.6023 6.9997 45.05 7.44742 45.05 7.9997C45.05 8.55199 44.6023 8.9997 44.05 8.9997H41.95C41.3977 8.9997 40.95 8.55199 40.95 7.9997ZM47.25 7.9997C47.25 7.44742 47.6977 6.9997 48.25 6.9997H54.55C55.1023 6.9997 55.55 7.44742 55.55 7.9997C55.55 8.55199 55.1023 8.9997 54.55 8.9997H48.25C47.6977 8.9997 47.25 8.55199 47.25 7.9997ZM61.95 7.9997C61.95 7.44742 62.3977 6.9997 62.95 6.9997H64C64.5523 6.9997 65 7.44742 65 7.9997C65 8.55199 64.5523 8.9997 64 8.9997H62.95C62.3977 8.9997 61.95 8.55199 61.95 7.9997Z" fill="#EEA42E" />
            
        </g>
    )
}
function ListeningLabel() {
    return (
        <g transform="translate(775 355)" transform-origin="50% 50%">
            <g transform="translate(90 0)">
                <text x="0" y="12" fontSize={22} textAnchor="middle" fontWeight={"bold"}>Listening</text>
                <g transform="translate(-10 50)">
                    <text fontSize={22} textAnchor="middle" fill="#1F897C" fontWeight={"bold"}>Above level</text>
                    <path  transform="translate(70 -16)" fill-rule="evenodd" clip-rule="evenodd" d="M21.766 4.43039L17.0788 4.43039V0.573242H26.7217H28.6502V2.50181V12.1447H24.7931V6.88777L17.0049 14.0314L15.7023 15.2262L14.3988 14.0325L10.5427 10.5012L2.9499 17.4267L0.350586 14.5769L9.24563 6.46363L10.5479 5.27583L11.8478 6.46622L15.7002 9.99416L21.766 4.43039Z" fill="#0CC0AD"/>
                </g>
            </g>
            
            <path transform="translate(0 30) rotate(180)" fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 8.70681C-0.0976311 8.31629 -0.0976311 7.68312 0.292893 7.2926L6.65685 0.928635C7.04738 0.538111 7.68054 0.538111 8.07107 0.928635C8.46159 1.31916 8.46159 1.95232 8.07107 2.34285L2.91548 7.49843C3.00102 7.64581 3.05 7.81704 3.05 7.9997C3.05 8.18237 3.00102 8.3536 2.91548 8.50097L8.07107 13.6566C8.46159 14.0471 8.46159 14.6802 8.07107 15.0708C7.68054 15.4613 7.04738 15.4613 6.65685 15.0708L0.292893 8.70681ZM5.25 7.9997C5.25 7.44742 5.69772 6.9997 6.25 6.9997H12.55C13.1023 6.9997 13.55 7.44742 13.55 7.9997C13.55 8.55199 13.1023 8.9997 12.55 8.9997H6.25C5.69772 8.9997 5.25 8.55199 5.25 7.9997ZM19.95 7.9997C19.95 7.44742 20.3977 6.9997 20.95 6.9997H23.05C23.6023 6.9997 24.05 7.44742 24.05 7.9997C24.05 8.55199 23.6023 8.9997 23.05 8.9997H20.95C20.3977 8.9997 19.95 8.55199 19.95 7.9997ZM33.55 8.9997H27.25C26.6977 8.9997 26.25 8.55199 26.25 7.9997C26.25 7.44742 26.6977 6.9997 27.25 6.9997H33.55C34.1023 6.9997 34.55 7.44742 34.55 7.9997C34.55 8.55199 34.1023 8.9997 33.55 8.9997ZM40.95 7.9997C40.95 7.44742 41.3977 6.9997 41.95 6.9997H44.05C44.6023 6.9997 45.05 7.44742 45.05 7.9997C45.05 8.55199 44.6023 8.9997 44.05 8.9997H41.95C41.3977 8.9997 40.95 8.55199 40.95 7.9997ZM47.25 7.9997C47.25 7.44742 47.6977 6.9997 48.25 6.9997H54.55C55.1023 6.9997 55.55 7.44742 55.55 7.9997C55.55 8.55199 55.1023 8.9997 54.55 8.9997H48.25C47.6977 8.9997 47.25 8.55199 47.25 7.9997ZM61.95 7.9997C61.95 7.44742 62.3977 6.9997 62.95 6.9997H64C64.5523 6.9997 65 7.44742 65 7.9997C65 8.55199 64.5523 8.9997 64 8.9997H62.95C62.3977 8.9997 61.95 8.55199 61.95 7.9997Z" fill="#01CBB7" />
            
        </g>
    )
}

const PerformanceChart = (props) => {

    const data = [
        {
            title: "Reading",
            amount: 25,
            fill: "#01CBB7"
        },
        {
            title: "Comprehension",
            amount: 25,
            fill: "#EEA42E"
        },
        {
            title: "Listening",
            amount: 25,
            fill: "#01CBB7"
        },
        {
            title: "Writing",
            amount: 25,
            fill: "#E5067C"
        }
    ]
    
    return (
        <div className="infographic performance-chart">
            <div className="top-labels">
            <div className="reading-label">
                <div className="label-text">
                    <p className="slice-label">Reading</p>
                    <p className="slice-performance trend-up">
                        <span>Above level</span>
                        <Icon icon="trending-up" size="18"/>
                    </p>
                </div>
                <div className="arrow">
                    <svg width="65" height="16" viewBox="0 0 65 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 8.70681C-0.0976311 8.31629 -0.0976311 7.68312 0.292893 7.2926L6.65685 0.928635C7.04738 0.538111 7.68054 0.538111 8.07107 0.928635C8.46159 1.31916 8.46159 1.95232 8.07107 2.34285L2.91548 7.49843C3.00102 7.64581 3.05 7.81704 3.05 7.9997C3.05 8.18237 3.00102 8.3536 2.91548 8.50097L8.07107 13.6566C8.46159 14.0471 8.46159 14.6802 8.07107 15.0708C7.68054 15.4613 7.04738 15.4613 6.65685 15.0708L0.292893 8.70681ZM5.25 7.9997C5.25 7.44742 5.69772 6.9997 6.25 6.9997H12.55C13.1023 6.9997 13.55 7.44742 13.55 7.9997C13.55 8.55199 13.1023 8.9997 12.55 8.9997H6.25C5.69772 8.9997 5.25 8.55199 5.25 7.9997ZM19.95 7.9997C19.95 7.44742 20.3977 6.9997 20.95 6.9997H23.05C23.6023 6.9997 24.05 7.44742 24.05 7.9997C24.05 8.55199 23.6023 8.9997 23.05 8.9997H20.95C20.3977 8.9997 19.95 8.55199 19.95 7.9997ZM33.55 8.9997H27.25C26.6977 8.9997 26.25 8.55199 26.25 7.9997C26.25 7.44742 26.6977 6.9997 27.25 6.9997H33.55C34.1023 6.9997 34.55 7.44742 34.55 7.9997C34.55 8.55199 34.1023 8.9997 33.55 8.9997ZM40.95 7.9997C40.95 7.44742 41.3977 6.9997 41.95 6.9997H44.05C44.6023 6.9997 45.05 7.44742 45.05 7.9997C45.05 8.55199 44.6023 8.9997 44.05 8.9997H41.95C41.3977 8.9997 40.95 8.55199 40.95 7.9997ZM47.25 7.9997C47.25 7.44742 47.6977 6.9997 48.25 6.9997H54.55C55.1023 6.9997 55.55 7.44742 55.55 7.9997C55.55 8.55199 55.1023 8.9997 54.55 8.9997H48.25C47.6977 8.9997 47.25 8.55199 47.25 7.9997ZM61.95 7.9997C61.95 7.44742 62.3977 6.9997 62.95 6.9997H64C64.5523 6.9997 65 7.44742 65 7.9997C65 8.55199 64.5523 8.9997 64 8.9997H62.95C62.3977 8.9997 61.95 8.55199 61.95 7.9997Z" fill="#01CBB7"/>
                    </svg>
                </div>
                
            </div>
            <div className="comprehension-label">
                <div className="label-text">
                    <p className="slice-label">Comprehension</p>
                    <p className="slice-performance trend-none">
                        <span>At level</span>
                        <Icon icon="trending-none" size="18"/>
                    </p>
                </div>
                <div className="arrow">
                <svg width="65" height="16" viewBox="0 0 65 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M58.3431 0.928635L64.7071 7.2926C65.0976 7.68312 65.0976 8.31629 64.7071 8.70681L58.3431 15.0708C57.9526 15.4613 57.3195 15.4613 56.9289 15.0708C56.5384 14.6802 56.5384 14.0471 56.9289 13.6566L62.0845 8.50097C61.999 8.3536 61.95 8.18237 61.95 7.9997C61.95 7.81704 61.999 7.64581 62.0845 7.49843L56.9289 2.34285C56.5384 1.95232 56.5384 1.31916 56.9289 0.928635C57.3195 0.538111 57.9526 0.538111 58.3431 0.928635ZM0 7.9997C0 7.44742 0.447715 6.9997 1 6.9997L2.05 6.9997C2.60228 6.9997 3.05 7.44742 3.05 7.9997C3.05 8.55199 2.60228 8.9997 2.05 8.9997H1C0.447715 8.9997 0 8.55199 0 7.9997ZM5.25 7.9997C5.25 7.44742 5.69772 6.9997 6.25 6.9997L12.55 6.9997C13.1023 6.9997 13.55 7.44742 13.55 7.9997C13.55 8.55199 13.1023 8.9997 12.55 8.9997L6.25 8.9997C5.69772 8.9997 5.25 8.55199 5.25 7.9997ZM19.95 7.9997C19.95 7.44742 20.3977 6.9997 20.95 6.9997H23.05C23.6023 6.9997 24.05 7.44742 24.05 7.9997C24.05 8.55199 23.6023 8.9997 23.05 8.9997H20.95C20.3977 8.9997 19.95 8.55199 19.95 7.9997ZM26.25 7.9997C26.25 7.44742 26.6977 6.9997 27.25 6.9997L33.55 6.9997C34.1023 6.9997 34.55 7.44742 34.55 7.9997C34.55 8.55199 34.1023 8.9997 33.55 8.9997L27.25 8.9997C26.6977 8.9997 26.25 8.55199 26.25 7.9997ZM40.95 7.9997C40.95 7.44742 41.3977 6.9997 41.95 6.9997H44.05C44.6023 6.9997 45.05 7.44742 45.05 7.9997C45.05 8.55199 44.6023 8.9997 44.05 8.9997H41.95C41.3977 8.9997 40.95 8.55199 40.95 7.9997ZM47.25 7.9997C47.25 7.44742 47.6977 6.9997 48.25 6.9997L54.55 6.9997C55.1023 6.9997 55.55 7.44742 55.55 7.9997C55.55 8.55199 55.1023 8.9997 54.55 8.9997L48.25 8.9997C47.6977 8.9997 47.25 8.55199 47.25 7.9997Z" fill="#EEA42E"/>
                    </svg>
                    
                </div>
               
            </div>
            </div>
            <div className="chart-container">
            <ResponsiveContainer>
                <PieChart width={440} height={440}>
                    {/* Background line */}
                    <Pie data={data} dataKey="amount" cx="50%" cy="50%" innerRadius={"85%"} outerRadius={"90%"} startAngle={180} stroke={"transparent"} endAngle={-180}  fillOpacity={0.5} />

                    {/* Reading  */}
                    <Pie data={[{amount:100}]} dataKey="amount" cx="50%" cy="50%" innerRadius={"8%"} outerRadius={"95%"} startAngle={180} stroke={"white"} fill={"#01CBB7"} endAngle={90}>
                        {
                        data.map((entry, index) => (
                            <Cell key={`cell1-${index}`} transform={"translate(-10, -10)"}/>
                        ))
                        }
                        {/* <Label content={<ReadingLabel/>} /> */}
                    </Pie>

                    {/* Comprehension  */}
                    <Pie data={[{amount:100}]} dataKey="amount" cx="50%" cy="50%" innerRadius={"7%"} outerRadius={"78%"} startAngle={90} stroke={"white"} fill={"#EEA42E"} endAngle={0}>
                        {
                        data.map((entry, index) => (
                            <Cell key={`cell2-${index}`}/>
                        ))
                        }
                        {/* <Label content={<ComprehensionLabel/>} /> */}
                    </Pie>

                    {/* Listening  */}
                    <Pie data={[{amount:100}]} dataKey="amount" cx="50%" cy="50%" innerRadius={"8%"} outerRadius={"97%"} startAngle={0} stroke={"white"} fill={"#01CBB7"} endAngle={-90}>
                        {
                        data.map((entry, index) => (
                            <Cell key={`cell3-${index}`} transform={"translate(10, 10)"}/>
                        ))
                        }
                        {/* <Label content={<ListeningLabel/>} /> */}
                    </Pie>
                    {/* Writing  */}
                    <Pie data={[{amount:100, title: "Writing"}]} dataKey="amount" cx="50%" cy="50%" innerRadius={"7%"} outerRadius={"66%"} startAngle={-90} stroke={"white"} fill={"#E5067C"} endAngle={-180}>
                        {
                        data.map((entry, index) => (
                            <Cell key={`cell4-${index}`}/>
                        ))
                        }
                        {/* <Label content={<WritingLabel/>} /> */}
                    </Pie>
                    
                </PieChart>
            </ResponsiveContainer>
            </div>
            <div className="bottom-labels">
            <div className="writing-label">
            <div className="arrow">
                    
                    <svg width="65" height="16" viewBox="0 0 65 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.65685 15.0714L0.292892 8.7074C-0.0976334 8.31688 -0.0976334 7.68371 0.292892 7.29319L6.65685 0.929229C7.04738 0.538704 7.68054 0.538704 8.07107 0.929229C8.46159 1.31975 8.46159 1.95292 8.07107 2.34344L2.91549 7.49903C3.00103 7.6464 3.05 7.81763 3.05 8.0003C3.05 8.18296 3.00103 8.35419 2.91549 8.50157L8.07107 13.6572C8.46159 14.0477 8.46159 14.6808 8.07107 15.0714C7.68054 15.4619 7.04738 15.4619 6.65685 15.0714ZM65 8.0003C65 8.55258 64.5523 9.0003 64 9.0003H62.95C62.3977 9.0003 61.95 8.55258 61.95 8.0003C61.95 7.44801 62.3977 7.0003 62.95 7.0003H64C64.5523 7.0003 65 7.44801 65 8.0003ZM59.75 8.0003C59.75 8.55258 59.3023 9.0003 58.75 9.0003L52.45 9.0003C51.8977 9.0003 51.45 8.55258 51.45 8.0003C51.45 7.44801 51.8977 7.0003 52.45 7.0003L58.75 7.0003C59.3023 7.0003 59.75 7.44801 59.75 8.0003ZM45.05 8.0003C45.05 8.55258 44.6023 9.0003 44.05 9.0003H41.95C41.3977 9.0003 40.95 8.55258 40.95 8.0003C40.95 7.44801 41.3977 7.0003 41.95 7.0003L44.05 7.0003C44.6023 7.0003 45.05 7.44801 45.05 8.0003ZM38.75 8.0003C38.75 8.55258 38.3023 9.0003 37.75 9.0003H31.45C30.8977 9.0003 30.45 8.55258 30.45 8.0003C30.45 7.44801 30.8977 7.0003 31.45 7.0003H37.75C38.3023 7.0003 38.75 7.44801 38.75 8.0003ZM24.05 8.0003C24.05 8.55258 23.6023 9.0003 23.05 9.0003H20.95C20.3977 9.0003 19.95 8.55258 19.95 8.0003C19.95 7.44801 20.3977 7.0003 20.95 7.0003H23.05C23.6023 7.0003 24.05 7.44801 24.05 8.0003ZM17.75 8.0003C17.75 8.55258 17.3023 9.0003 16.75 9.0003H10.45C9.89772 9.0003 9.45 8.55258 9.45 8.0003C9.45 7.44801 9.89772 7.0003 10.45 7.0003L16.75 7.0003C17.3023 7.0003 17.75 7.44801 17.75 8.0003Z" fill="#E5067C"/>
                        </svg>
                    </div>
                <div className="label-text">
                    <p className="slice-label">Writing</p>
                    <p className="slice-performance trend-down">
                        <span>Below level</span>
                        <Icon icon="trending-down" size="18"/>
                    </p>
                </div>
                
                
            </div>
            <div className="listening-label">
            <div className="arrow">
                <svg width="65" height="16" viewBox="0 0 65 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.3431 0.928635L64.7071 7.2926C65.0976 7.68312 65.0976 8.31629 64.7071 8.70681L58.3431 15.0708C57.9526 15.4613 57.3195 15.4613 56.9289 15.0708C56.5384 14.6802 56.5384 14.0471 56.9289 13.6566L62.0845 8.50097C61.999 8.3536 61.95 8.18237 61.95 7.9997C61.95 7.81704 61.999 7.64581 62.0845 7.49843L56.9289 2.34285C56.5384 1.95232 56.5384 1.31916 56.9289 0.928635C57.3195 0.538111 57.9526 0.538111 58.3431 0.928635ZM0 7.9997C0 7.44742 0.447715 6.9997 1 6.9997L2.05 6.9997C2.60228 6.9997 3.05 7.44742 3.05 7.9997C3.05 8.55199 2.60228 8.9997 2.05 8.9997H1C0.447715 8.9997 0 8.55199 0 7.9997ZM5.25 7.9997C5.25 7.44742 5.69772 6.9997 6.25 6.9997L12.55 6.9997C13.1023 6.9997 13.55 7.44742 13.55 7.9997C13.55 8.55199 13.1023 8.9997 12.55 8.9997L6.25 8.9997C5.69772 8.9997 5.25 8.55199 5.25 7.9997ZM19.95 7.9997C19.95 7.44742 20.3977 6.9997 20.95 6.9997H23.05C23.6023 6.9997 24.05 7.44742 24.05 7.9997C24.05 8.55199 23.6023 8.9997 23.05 8.9997H20.95C20.3977 8.9997 19.95 8.55199 19.95 7.9997ZM26.25 7.9997C26.25 7.44742 26.6977 6.9997 27.25 6.9997L33.55 6.9997C34.1023 6.9997 34.55 7.44742 34.55 7.9997C34.55 8.55199 34.1023 8.9997 33.55 8.9997L27.25 8.9997C26.6977 8.9997 26.25 8.55199 26.25 7.9997ZM40.95 7.9997C40.95 7.44742 41.3977 6.9997 41.95 6.9997H44.05C44.6023 6.9997 45.05 7.44742 45.05 7.9997C45.05 8.55199 44.6023 8.9997 44.05 8.9997H41.95C41.3977 8.9997 40.95 8.55199 40.95 7.9997ZM47.25 7.9997C47.25 7.44742 47.6977 6.9997 48.25 6.9997L54.55 6.9997C55.1023 6.9997 55.55 7.44742 55.55 7.9997C55.55 8.55199 55.1023 8.9997 54.55 8.9997L48.25 8.9997C47.6977 8.9997 47.25 8.55199 47.25 7.9997Z" fill="#01CBB7"/>
                </svg>

                </div>
                <div className="label-text">
                    <p className="slice-label">Listening</p>
                    <p className="slice-performance trend-up">
                        <span>Above level</span>
                        <Icon icon="trending-up" size="18"/>
                    </p>
                </div>
                
                
            </div>
            </div>
            

        </div>
    )
}
export default PerformanceChart