import React, { useState } from "react";
import LineChart from "./LineChart";

const data = [
    {
        name: '17 Feb',
        gse: 20,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '4 Mar',
        gse: 80,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '31 Mar',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '7 Apr',
        gse: 34,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 Apr',
        gse: 30,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '21 Apr',
        gse: 55,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '28 Apr',
        gse: 60,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '1 May',
        gse: 40,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '7 May',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 May',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '21 May',
        gse: 65,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '28 May',
        gse: 70,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '31 May',
        gse: 70,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '17 Jan',
        gse: 20,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '4 Mar',
        gse: 80,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '31 Mar',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '7 Apr',
        gse: 34,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 Apr',
        gse: 30,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '21 Apr',
        gse: 55,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '28 Apr',
        gse: 60,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '1 May',
        gse: 40,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '7 May',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 May',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '21 May',
        gse: 65,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '28 May',
        gse: 70,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '31 May',
        gse: 70,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '1 Jul',
        gse: 27,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 Jul',
        gse: 55,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '1 Aug',
        gse: 30,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '18 Aug',
        gse: 30,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '22 Sep',
        gse: 20,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 Oct',
        gse: 27,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '1 Nov',
        gse: 60,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 Nov',
        gse: 23,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '21 Nov',
        gse: 34,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '1 Dec',
        gse: 40,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '12 Dec',
        gse: 30,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '17 Feb',
        gse: 20,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '4 Mar',
        gse: 80,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '31 Mar',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '7 Apr',
        gse: 34,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 Apr',
        gse: 30,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '21 Apr',
        gse: 55,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '28 Apr',
        gse: 60,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '1 May',
        gse: 40,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '7 May',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '14 May',
        gse: 50,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '21 May',
        gse: 65,
        test: "B1 CEFR",
        desc: "Pearson Level Test"
      },
      {
        name: '28 May',
        gse: 70,
        test: "Pearson Benchmark Test"
      },
      {
        name: '31 May',
        gse: 70,
        test: "C1 CEFR",
        desc: "Pearson Level Test"
      }
    ];

const LearningTimeline = (props) => {
    const [timeFrame, setTimeFrame] = useState(1);
    const [chartData, setChartData] = useState(data.slice(-6));
 


    const formatData = (time) => {
        let originaData = [...data];
        let filteredData;
        switch(time) {
            case 1:
                filteredData = originaData.slice(-6);
                break;
            case 6:
                filteredData = originaData.slice(-16);
                break;
            case 12:
                filteredData = originaData.slice(-25);
                break;
            case 24:
                filteredData = originaData;
                break;
            default:
                filteredData = originaData;
        }
        setTimeFrame(time)
        setChartData(filteredData)
        
    }

    return (
        <div className='chart-container'>
        <div className='chart-header'>
                <h2 style={{marginBottom:0}} className="section-title">
                    Learning timeline
                </h2>
                <div className='chart-time'>
                    <fieldset>
                        <legend style={{visibility:"hidden"}}>Timeframe</legend>
                        <div className='radio-row'>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 1} onChange={() => formatData(1)}/><span>1M</span></label>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 6} onChange={() => formatData(6)}/><span>6M</span></label>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 12} onChange={() => formatData(12)}/><span>1Y</span></label>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 24} onChange={() => formatData(24)}/><span>2Y</span></label>
                        </div>
                    </fieldset>
                </div>
            </div>
            <LineChart data={chartData} time={12}/>
        </div>
    )
}
export default LearningTimeline