import React from "react";

const ProfileProgressBar = (props) => {

    return (
        <div className="progress-bar-container">
            <div className="progress-bar profile">
                <div className="bar" role="progressbar"
                aria-labelledby="PprogressBar"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow={props.value}
                style={{width:props.value + "%"}}
                />
        
            </div>
            <p id="PprogressBar">Your profile is {props.value}% complete</p>
        </div>
        
    )
}
export default ProfileProgressBar