/** @format */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Breadcrumbs from '../global/Breadcrumbs';
import PageWrapper from '../global/PageWrapper';

import LessonInfo from './LessonInfo';
import MaterialSetting from './MaterialSetting';
import LessonSetting from './LessonSetting';
import AccordionStepper from './input/AccordionStepper';

/**css */
import '../../assets/styles/scss/_newLesson.scss';

const NewLesson = (props) => {
	const params = useParams();
	const { courseId } = params;
	const courses = useSelector((state) => state.courses);

	const course = useSelector((state) =>
		courses.find((record) => record.id.toLowerCase() === courseId.toLowerCase())
	);

	const breadcrumbs = [
		{
			page: 'Courses',
			target: '/courses',
		},
		{
			page: `${course.cefrLevel} ${course.name} (${course.ageGroup})`,
			target: '',
		},
		{
			page: 'New Lesson',
			target: '/newlesson',
		},
	];

	/**Accordion stepper color activate when click the accordion */
	const [activeStep, setActiveStep] = useState(0);
	const handleStepClick = (stepId) => {
		setActiveStep(stepId);
	};

	return (
		<PageWrapper>
			<div className="course-detail">
				<Breadcrumbs data={breadcrumbs} />
				<h1 className="text-h3">New Lesson</h1>

				<div className="new-lesson">
					<section className="new-lesson-stepper">
						<AccordionStepper activeStep={activeStep} />
					</section>

					<section className="new-lesson-page">
						<LessonInfo
							onClick={() => handleStepClick(1)}
							active={activeStep === 1}
						/>
						<MaterialSetting
							onClick={() => handleStepClick(2)}
							active={activeStep === 2}
						/>
						<LessonSetting
							onClick={() => handleStepClick(3)}
							active={activeStep === 3}
						/>

						<div className="select-student-btn">
							<Link to={"/course/"+course.id+"/selectstudent"}>
								<button className="primary">Select Students</button>
							</Link>
						</div>
					</section>
				</div>
			</div>
		</PageWrapper>
	);
};

export default NewLesson;
