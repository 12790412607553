import React from "react";

const ProgressBar = (props) => {
    let percent = Math.floor(100 * props.complete / props.total)

    return (
        <div className="progress-bar-container">
            <p id="progressBar" className="space-between">
                <span className="">{props.complete + " of " + props.total + " Completed"}</span><span>{percent + "%"}</span></p>
            <div className="progress-bar">
                <div className="bar" role="progressbar"
                aria-labelledby="progressBar"
                aria-valuemin="0"
                aria-valuemax={props.total}
                aria-valuenow={props.complete}
                style={{width:percent + "%"}}
                />
        
            </div>
            
        </div>
        
    )
}
export default ProgressBar