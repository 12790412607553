import React from "react";
import writingIcon from "../../assets/images/icons/icons8-writing-50.png";
import listeningIcon from "../../assets/images/icons/icons8-listening-50.png";
import readingIcon from "../../assets/images/icons/icons8-reading-50.png";

const ProgressRing = (props) => {
    const radius = 0.5 * (props.size - props.stroke);
    const circumference = radius * 2 * Math.PI;

    const ring = circumference * props.value / 100;
    const random = props.id ? props.id : Math.floor(Math.random() * 10);

    const colorFill = () => {
        if (props.fill === 0) {
            return "url(#ringGradient" + random + ")"
        } else if (props.fill === 1) {
            return "#3B63B0"
        } else if (props.fill === 2) {
            return "url(#ringGradient2" + random + ")"
        } else if (props.fill === 3) {
            return "#8F36FE"
        } else {
            return "url(#ringGradient" + random + ")"
        }
    }


    const renderIcon = () => {

        if (props.icon === "writing") {
            return '<img src=' + writingIcon + ' alt=""/>'
        } else if (props.icon === "reading") {
            return '<img src=' + readingIcon + ' alt=""/>'
        } else if (props.icon === "listening") {
            return '<img src=' + listeningIcon + ' alt=""/>'
        } else {
            return '<svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.33351 0.987915H22.9753L22.9753 0.987982C23.5501 0.987982 23.9997 1.4378 23.9997 2.01241V14.6798C23.9997 15.3294 23.2252 15.6042 22.8254 15.1045L20.1021 11.6566H17.4786V7.60903C17.4786 6.20992 16.3292 5.06052 14.9301 5.06052H8.30908V2.01235C8.30908 1.43756 8.7589 0.987915 9.33351 0.987915ZM1.28835 6.58466H14.9302L14.93 6.58475C15.4797 6.58475 15.9544 7.03439 15.9295 7.58406V16.2039C15.9295 16.7787 15.4797 17.2283 14.9051 17.2283H4.16153L1.43821 20.6763C1.06353 21.176 0.263916 20.9011 0.263916 20.2765V7.60909C0.263916 7.0343 0.713736 6.58466 1.28835 6.58466ZM2.68755 11.8815C2.68755 12.5811 3.26216 13.1557 3.96174 13.1557C4.66132 13.1557 5.21101 12.606 5.23593 11.8815C5.23593 11.182 4.66132 10.6073 3.96174 10.6073C3.26216 10.6073 2.68755 11.182 2.68755 11.8815ZM6.83511 11.8815C6.83511 12.5811 7.40972 13.1557 8.1093 13.1557C8.80888 13.1557 9.38349 12.606 9.38349 11.8815C9.38349 11.182 8.80888 10.6073 8.1093 10.6073C7.40972 10.6073 6.83511 11.182 6.83511 11.8815ZM10.9827 11.8815C10.9827 12.5811 11.5573 13.1557 12.2569 13.1557C12.9564 13.1557 13.5311 12.606 13.5311 11.8815C13.5311 11.182 12.9564 10.6073 12.2569 10.6073C11.5573 10.6073 10.9827 11.182 10.9827 11.8815Z" fill="currentColor"/></svg>'
        }
    }

    return (
        <div className="progress-ring" style={{width: props.size + "px", height: props.size+"px"}} role="img" aria-label={props.ariaLabel}>
        <svg
            height={props.size}
            width={props.size}
        >
            <defs>
                <linearGradient id={"ringGradient"+random} x1="12.8571" y1="-19" x2="2.85714" y2="14.1429" gradientUnits="userSpaceOnUse" gradientTransform="rotate(35)">
                    <stop offset="0.03" stopColor="#0CC0AD"/>
                    <stop offset="0.61" stopColor="#2E839E"/>
                    <stop offset="1.07" stopColor="#29657A"/>
                </linearGradient>
                <linearGradient id={"ringGradient2"+random} x1="12.8571" y1="-19" x2="2.85714" y2="14.1429" gradientUnits="userSpaceOnUse" gradientTransform="rotate(35)">
                    <stop offset="0.03" stopColor="#597EFF"/>
                    {/* <stop offset="0.61" stopColor="#2E839E"/> */}
                    <stop offset="1.07" stopColor="#0AD6CA"/>
                </linearGradient>
            </defs>
            <circle
            stroke={props.dark ? "var(--color-black)" : "var(--color-light-200)"}
            strokeWidth={props.stroke}
            fill="transparent"
            r={radius}
            cx={props.size * 0.5}
                cy={props.size * 0.5}
            />
            <circle
                stroke={colorFill()}
                strokeWidth={props.stroke}
                fill="transparent"
                r={radius}
                cx={props.size * 0.5}
                cy={props.size * 0.5}
                strokeLinecap="round"
                strokeDasharray={`${ring} ${circumference - ring}`} strokeDashoffset={0.25 * circumference}>

            </circle>
        </svg>
        <span className={`ring-icon${props.percent ? " percentage":""}${props.fill ? props.fill :""}` }>
           <span dangerouslySetInnerHTML={{__html: props.icon ? renderIcon() : `${props.value}${props.percent ? "%":""}`}}/>

        </span>
    </div>

    )
}
export default ProgressRing
