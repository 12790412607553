/** @format */

import React from 'react';

const AccordionStepper = (props) => {
	const steps = [{ id: 1 }, { id: 2 }, { id: 3 }];

	return (
		<div className="stepper-wrapper">
			{steps.map((step) => (
				<div
					className={`stepper ${
						props.activeStep >= step.id ? 'stepper-active' : ''
					}`}
					key={step.id}
				>
					<div
						className={`step ${props.activeStep >= step.id ? 'active' : ''}`}
					></div>
				</div>
			))}
		</div>
	);
};
export default AccordionStepper;
