import rhianneSmileNarrow from "../images/student_explore/rhianne-smile-narrow.png";
import femaleDeskNarrow from "../images/student_explore/female-desk-narrow.png";
import rectangleNarrow from "../images/student_explore/rectangle-narrow.png";
import john from "../images/student_explore/john-wide.png";
import derek from "../images/student_explore/derek-wide.png";
import emily from "../images/student_explore/emily-wide.png";
import rhianneWide from "../images/student_explore/rhianne-wide.png";
import adam from "../images/student_explore/adam-wide.png";
import englishPlaylist from "../images/student_explore/englishPlaylist.png";
import james from "../images/student_explore/james-martinez.png";
import savannah from "../images/student_explore/savannahNguyen.png";
import annette from "../images/student_explore/annetteBlack.png";

const exploreData ={
    overview: [
      {
        id: 0,
        image: rhianneSmileNarrow,
        title: "New Releases For you",
      },
      {
        id: 1,
        image: femaleDeskNarrow,
        title: "Curated English Playlist"
      },    
      {
        id: 2,
        image: rectangleNarrow,
        title: "Suggested Content"
      },
    ],
    newRelease: [
      {
        id: 0,
        image: john,
        title: "Tenses with,\nJohn",
      },
      {
        id: 1,
        image: derek,
        title: "Grammar\nwith,Derek"
      },
      {
        id: 2,
        image: emily,
        title: "Punctuation\nwith, Emily"
      },
      {
        id: 3,
        image: rhianneWide,
        title: "Lexical choice\nwith, Rhianne"
      },
      {
        id: 4,
        image: adam,
        title: "Colloquialisms\nwith, Adam"
      },
    ],
    playlist: [
      {
        id: 0,
        image: englishPlaylist,
        imageText: "3 women at table with laptops",
        title: "English conversation",
        description: "How to say no without hurting someone's feelings?"
      },
      {
        id: 0,
        image: englishPlaylist,
        imageText: "3 women at table with laptops",
        title: "English conversation",
        description: "How to say no without hurting someone's feelings?"
      },
    ], 
    suggested: [
      {
        id: 0,
        image: englishPlaylist,
        imageText: "",
        title: "English in the Tech industry",
        authorImage: james,
        author: "James Martinez",
        description: "English for Portuguese Speakers wanting to work in tech."
      },
      {
        id: 0,
        image: englishPlaylist,
        imageText: "",
        title: "English in the Tech industry",
        authorImage: james,
        author: "James Martinez",
        description: "English for Portuguese Speakers wanting to work in tech."
      },
      {
        id: 0,
        image: englishPlaylist,
        imageText: "",
        title: "English in the Tech industry",
        authorImage: james,
        author: "James Martinez",
        description: "English for Portuguese Speakers wanting to work in tech."
      }
    ], 
    upcoming: [
      {
        id: 0,
        title: "Ordering in English",
        authorImage: savannah,
        author: "Savannah Nguyen",
        dateTime: "2:30pm - 3rd Aug"
      },
      {
        id: 0,
        title: "Telephone Talks",
        authorImage: annette,
        author: "Annette Black",
        dateTime: "9:45am - 4th Aug"
      }
    ]
  }

export default exploreData;
