/** @format */

import React, { useState } from 'react';
import MaterialCard from './input/MaterialCard';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '../../components/global/Icon';


const MaterialSetting = (props) => {
	const [searchText, setSearchText] = useState('');
	const [showResults, setShowResults] = useState(false);

	const handleInputChange = (e) => {
		setSearchText(e.target.value);
	};

	const handleSearchEnter = () => {
		setShowResults(true);
	};
	const handleIconClick = () => {
		if (searchText !== '') {
			setShowResults(true);
		}
	};

	return (
		<Accordion
			className={`accordion ${props.active ? 'active' : ''}`}
			onClick={props.onClick}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				className="summary"
			>
				<h3>What materials should this lesson include?</h3>
			</AccordionSummary>
			<AccordionDetails className="detail material">
				<div className="form-control">
					<label htmlFor="material-search">Search for lesson materials</label>
					<div className="material-search">
						<input
							id="material-search"
							type="search"
							placeholder="Search..."
							value={searchText}
							onChange={handleInputChange}
							onKeyPress={(e) => {
								if (e.key === 'Enter') handleSearchEnter();
							}}
						/>
						<button
							className="search-icon"
							onClick={handleIconClick}
						>
							<Icon
								icon="search"
								size="18"
							/>
						</button>
					</div>
				</div>
				{showResults && (
					<div className="material-card-page">
						<p className="results">
							Results: <i>4 results found</i>
						</p>
						<MaterialCard />
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default MaterialSetting;
