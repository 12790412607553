import React from "react";
import studyGroup from "../../assets/images/icons/achievement-group.png";
import bookWorm from "../../assets/images/icons/achievement-bookworm.png";
import buddies from "../../assets/images/icons/achievement-buddies.png";
import nightOwl from "../../assets/images/icons/achievement-night.png";
import students from "../../assets/images/icons/achievement-students.png";


const AchievementRing = (props) => {
    const radius = 0.5 * (props.size - props.stroke);
    const circumference = radius * 2 * Math.PI;

    const ring = circumference * props.value / 100;
    const random = props.id ? props.id : Math.floor(Math.random() * 10);

    const colorFill = () => {
        if (props.fill === 0) {
            return "#0CC0AD"
        } else if (props.fill === 1) {
            return "#FFBB1C"
        } else {
            return "#0CC0AD"
        }
    }


    const renderIcon = () => {

        if (props.icon === "buddies") {
            return '<img class="large" src=' + buddies + ' alt=""/>'
        } else if (props.icon === "students") {
            return '<img class="large" src=' + students + ' alt=""/>'
        } else if (props.icon === "group") {
            return '<img class="large" src=' + studyGroup + ' alt=""/>'
        } else if (props.icon === "bookworm") {
            return '<img class="large" src=' + bookWorm + ' alt=""/>'
        } else if (props.icon === "night") {
            return '<img class="large" src=' + nightOwl + ' alt=""/>'
        }
    }

    return (
        <div className="progress-ring" style={{width: props.size + "px", height: props.size+"px"}} role="img" aria-label={props.ariaLabel}>
        <svg
            height={props.size}
            width={props.size}
        >
            <defs>
                <linearGradient id={"ringGradient"+random} x1="12.8571" y1="-19" x2="2.85714" y2="14.1429" gradientUnits="userSpaceOnUse" gradientTransform="rotate(35)">
                    <stop offset="0.03" stopColor="#0CC0AD"/>
                    <stop offset="0.61" stopColor="#2E839E"/>
                    <stop offset="1.07" stopColor="#29657A"/>
                </linearGradient>
                <linearGradient id={"ringGradient2"+random} x1="12.8571" y1="-19" x2="2.85714" y2="14.1429" gradientUnits="userSpaceOnUse" gradientTransform="rotate(35)">
                    <stop offset="0.03" stopColor="#597EFF"/>
                    {/* <stop offset="0.61" stopColor="#2E839E"/> */}
                    <stop offset="1.07" stopColor="#0AD6CA"/>
                </linearGradient>
            </defs>
            <circle
            stroke={"#000000"}
            strokeWidth={props.stroke}
            fill="transparent"
            r={radius}
            cx={props.size * 0.5}
                cy={props.size * 0.5}
            />
            <circle
                stroke={colorFill()}
                strokeWidth={props.stroke}
                fill="transparent"
                r={radius}
                cx={props.size * 0.5}
                cy={props.size * 0.5}
                strokeLinecap="round"
                strokeDasharray={`${ring} ${circumference - ring}`} strokeDashoffset={0.25 * circumference}>

            </circle>
        </svg>
        <span className={`ring-icon${props.percent ? " percentage":""}` }>
           <span dangerouslySetInnerHTML={{__html: props.icon ? renderIcon() : `${props.value}${props.percent ? "%":""}`}}/>

        </span>
    </div>

    )
}
export default AchievementRing
