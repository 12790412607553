const skills = [
 {
   "id": "SK-STU-WRT",
   "name": "Writing",
   "components": ["Spelling", "Grammar", "Organization", "Style"],
   "icon": "write-image.png"
 },
 {
   "id": "SK-STU-SPK",
   "name": "Speaking",
   "components": ["Pronunciation", "Fluency", "Cohesion", "Interaction"],
   "icon": "speak-image.png"
 },
 {
   "id": "SK-STU-RED",
   "name": "Reading",
   "components": ["Decoding", "Vocabulary", "Comprehension"],
   "icon": "read-image.png"
 },
 {
   "id": "SK-STU-LSN",
   "name": "Listening",
   "components": ["Contextual Understanding", "Decoding", "Discerning"],
   "icon": "listen-image.png"
 },
 {
   "id": "SK-INS-LDR",
   "name": "Leadership",
   "components": "",
   "icon": "leadership.png"
 },
 {
   "id": "SK-INS-COM",
   "name": "Communication",
   "components": "",
   "icon": "communication.png"
 },
 {
   "id": "SK-INS-CFT",
   "name": "Conflict resolution",
   "components": "",
   "icon": "conflict-resolve.png"
 }
];

export default skills;