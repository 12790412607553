import React from "react";
import PageWrapper from "./global/PageWrapper";
import data from "../assets/data/exploreData";
import PlaylistCard from "./student_explore/PlaylistCard";
import OverviewCard from "./student_explore/OverviewCard";
import NewReleaseCard from "./student_explore/NewReleaseCard";
import SuggestedCard from "./student_explore/SuggestedCard";
import UpcomingCard from "./student_explore/UpcomingCard";
import SearchInput from "./global/SearchInputRight";

const Explore = (props) => {
  // console.log("Data1:", data);
  const displayItems = (dataItems, Card) => {
    // console.log("DATA:", data["playlist"])
    return dataItems?.map((item, index) => {
      // console.log("Item:", item)
      return (
        <Card
          title={item.title}
          image={item.image}
          imageText={item?.imageText}
          description={item?.description}
          author={item && item.author ? item.author : null}
          authorImage={item && item.authorImage ? item.authorImage : null}
          dateTime={item && item.dateTime ? item.dateTime : null}
          key={"playlist" + index}
        />
      );
    });
  };
  return (
    <PageWrapper>
      <div className="explore">
        <div className="background"></div>
        <h2>Explore</h2>

        {/* <section className="explore-section explore-new-release">
          <h3>New releases</h3>
          <div className="section-body">
            <div className="card-row overflow-scroll">
              {displayItems(data["newRelease"], NewReleaseCard)}
            </div>
          </div>
        </section> */}
        <section className="">
        <SearchInput
								placeholder={`What can we help you find?`}
								isHeader={true}
							/>
        </section>
        <section className="explore-section explore-suggested">
          <h3>
            Suggested English <br />
            Content
          </h3>
          <div className="section-body">
            <div className="card-row overflow-scroll">
              {displayItems(data["suggested"], SuggestedCard)}
            </div>
          </div>
        </section>

        <section className="explore-section explore-playlist">
          <h3>
            Curated English <br />
            Content
          </h3>
          <div className="section-body">
            <div className="card-row overflow-scroll">
              {displayItems(data["playlist"], PlaylistCard)}
            </div>
          </div>
        </section>

        <section className="explore-section explore-upcoming">
          <h3>
            Upcoming <br />
            drop-in classes
          </h3>
          <div className="section-body">
            <p>Choose one and join in!</p>
            <div className="card-row overflow-scroll">
              {displayItems(data["upcoming"], UpcomingCard)}
            </div>
          </div>
        </section>
      </div>
    </PageWrapper>
  );
};

export default Explore;
