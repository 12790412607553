import React from "react";
import ChannelCard from './ChannelCard'

const ChannelsList = (props) => {

    const getChannels = () => {
        switch(props.interest) {
			case 'Science':
			  return ['Biology', 'General Chemistry', 'Biochemistry']
			  break;
            case 'Politics':
                return ['Public Administration', 'International Relations', 'Macroeconomics']
                break;
			default:
				return ['Biology', 'General Chemistry', 'Biochemistry']
        }
    }

    return (
        <div>
            <p className="channels-list-subtitle">Because you're interested in <span className="bold">{props.interest}</span></p>
            <div className="channels-list">
                {
                    getChannels().map((channel,index)=>{
                        return <ChannelCard key={index} channel={channel}/>
                    })
                }
               
            </div>
        </div>
        
    )
}

export default ChannelsList