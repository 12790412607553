import React from "react";
import Icon from "../global/Icon"
import { Link } from "react-router-dom";

//images
import placeholder from "../../assets/images/placeholder-image.png"
import calendar from "../../assets/images/icons/calendar-dark.svg"
import clock from "../../assets/images/icons/clock.svg"
import add from "../../assets/images/icons/add.svg"

const LessonsList = (props) => {

    const getDate = (date) => {
        let lessonDate = new Date(date)
        return lessonDate.toLocaleDateString()
    }

    return (
        <>
            <h3>Lessons</h3>
            <div className="lessons-list">
                {
                    props.lessons ? <>
                        {
                            props.lessons.map((lesson, index)=>{
                                return(
                                    <div key={index} className="card lesson-card">
                                        <div className="image-container">
                                            <img src={lesson.image}/>
                                        </div>
                                        <p className="name text-h5">{lesson.name}</p>
                                        <div className="meta">
                                            <img src={calendar}/>
                                            <span>{getDate(lesson.date)}</span>
                                        </div>
                                        <div className="meta">
                                            <img src={clock}/>
                                            <span>{lesson.startTime} - {lesson.endTime}</span>
                                        </div>
                                        <p className="description">{lesson.description}</p>
                                        <div className="card-footer">
                                            <Link className="primary" to={"/course/"+props.course.id+"/"+lesson.id}>
                                                Go to lesson
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </> : null
                }
                   <Link to={"/course/"+props.course.id+"/newlesson"}>
                        <img src={add}/>
                    </Link>
            </div>
        </>
    )
}

export default LessonsList