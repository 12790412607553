import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector} from "react-redux";
import Avatar from '../global/Avatar'

//images
import clock from "../../assets/images/icons/clock.svg"
import clockGrey from "../../assets/images/icons/clock-grey-small.svg"
import location from "../../assets/images/icons/location.svg"
import capGrey from "../../assets/images/icons/cap-grey.svg"
import peopleGrey from "../../assets/images/icons/people-grey.svg"
import lessonGrey from "../../assets/images/icons/lesson-grey.svg"
import courseImgDefault from "../../assets/images/courses/C1_Corporate_English_Adults.jpg"
import courseImg1 from "../../assets/images/courses/A1.png"
import courseImg2 from "../../assets/images/courses/A1YL.png"
import courseImg3 from "../../assets/images/courses/B1+.png"

const ScheduleCard = (props) => {

    const users = useSelector((state) => state.users);
    const user = useSelector((state) => state.auth).userdata;

    let ageGroup = props.course.ageGroup.toUpperCase().replace(/\s/g, '');

    const userType = {
        YOUNGLEARNERS: "GRP-YTS",
        TEEN: "GRP-TNS",
        ADULTS: "GRP-ADS",
        // ADMIN: "GRP-ADM",
        // INSTRUCTOR: "GRP-INS"
    }

    const displayStudents = () => {
        const filteredUsers = users.filter((user) => user.ageGroupId === userType[ageGroup] && user.cohort.includes(props.course.id));
        //const filteredUsers = users.filter((user) => user.cohort.includes(props.course.id));

        return filteredUsers.map((user, index)=>{
            return <Avatar key={index} outline='white' size='small' user={user}/>
        })
    }

    const getStudentCount = () => {
        //const filteredUsers = users.filter((user) => user.ageGroupId === userType[ageGroup] && user.cohort.includes(props.course.id));
        const filteredUsers = users.filter((user) => user.cohort.includes(props.course.id));
        return filteredUsers.length 
    }

    const getCourseImage = () => {
        let fullCourseName = `${props.course.cefrLevel} ${props.course.name} (${props.course.ageGroup})`
        if(fullCourseName === 'A1 General English (Adults)'){
                return courseImg1
        }else if(fullCourseName === 'A1 General English (Young Learners)'){
                return courseImg2
        }else if(fullCourseName === 'B1+ General English (Adults)'){
            return courseImg3
        }else{
            return courseImgDefault
        }
    }

    return (
        <div className="schedule-card">
            <div className='square image'>
                    {/* {getCourseImage(course)} */}
                    <img src={getCourseImage(props.course)}/>
            </div>
            <div className="name-info">
                    <h5>
                        <Link to={process.env.PUBLIC_URL + "/course/"+props.course.id}>
                        {props.course.cefrLevel} {props.course.name} ({props.course.ageGroup})
                        </Link>
                        </h5>
            
                        <div className="meta">
                            <div className="instructor">
                                <span class="icon-grey-circle">
                                    <img src={capGrey}/>
                                </span>
                                <span>{props.course.instructor}</span>
                            </div>
                            <div className="students">
                                <span class="icon-grey-circle">  
                                    <img src={peopleGrey}/>
                                </span>
                                <span>{getStudentCount()} students</span>
                            </div>
                            <div className="meta-time">
                                <span class="icon-grey-circle" >
                                    <img src={clockGrey}/>
                                </span>
                                <span>{props.course.startTime} - {props.course.endTime}</span>
                            </div>
                            <div className="lesson-type">
                                <span class="icon-grey-circle">
                                    <img src={lessonGrey}/>
                                </span>
                                <span>{props.course.location}</span>
                            </div>
                        </div>
                </div>
                {/* Add link to view group? */}
                <Link className="cta secondary" to={"/home"}>View group</Link>
        </div>
    )
}

export default ScheduleCard
