import React from "react";
import Avatar from '../global/Avatar'

//images
import location from "../../assets/images/icons/location-teal.svg"
import { Link } from "react-router-dom";

const PersonCard = (props) => {

    return (
        <div className={`card user ${props.variant} ${props.newWidth}`}>
        {/* <div className={`card user ${props.variant}`}> */}
            <Avatar outline='white' size='default' user={props.user}/>
            <div className="name-container">
                <Link to={"/student/"+props.user.id}><p className="name">{`${props.user.firstName} ${props.user.lastName}`}</p></Link>
            </div>
            <div className="location">
                <img src={location}/>
                <span>{`${props.user.city}, ${props.user.country}`}</span>
            </div>
            <p className="level">{props.user.englishLevel ? props.user.englishLevel : "A1Genera"}</p>
            <p className="sublevel">General English</p>
        {/* </div> */}
        </div>
    )
}

export default PersonCard
