import { createSlice } from "@reduxjs/toolkit";
import groupsData from "./data/groups";
import { getGroupsReducer} from "./reducers/groupsReducer";

const groupsSlice= createSlice({
    name: "groups",
    initialState: groupsData,
    reducers: {
      getGroups: getGroupsReducer,
      groupsLogout: (state) => {
        // Reset the state to initial values
        return [];
      },
    },
  });
  
  // Note: groupsSlice.actions automatically creates actions based on our reducer names
  export const {getGroups, groupsLogout} = groupsSlice.actions;
  
  // export  reducer, so it can be added to the store
  export default groupsSlice.reducer;