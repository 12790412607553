import React from "react";

//images
import biology from "../../assets/images/channels/biology.png"
import generalChemistry from "../../assets/images/channels/general_chemistry.png"
import biochemistry from "../../assets/images/channels/biochemistry.png"
import publicAdministration from "../../assets/images/channels/public_administration.png"
import internationalRelations from "../../assets/images/channels/international_relations.png"
import macroeconomics from "../../assets/images/channels/macroeconomics.png"


const ChannelCard = (props) => {

    const getBackgroundImg = () => {
        switch(props.channel) {
			case 'Biology':
			  return biology
			  break;
            case 'General Chemistry':
			  return generalChemistry
			  break;
            case 'Biochemistry':
			  return biochemistry
			  break;
            case 'Public Administration':
			  return publicAdministration
			  break;
            case 'International Relations':
			  return internationalRelations
			  break;
            case 'Macroeconomics':
			  return macroeconomics
			  break;
			default:
				return biology
        }
    }

    return (
        <div className="channel-card">
           <img className='background' src={getBackgroundImg()}/>
           <span className="title bold">{props.channel}</span>
           <div className="instructor">
                <img className="instructor-image avatar"/>
                <span>with </span>
                <span className="instructor-name bold">Oliver Ngo</span>
           </div>
           <span className="meta">
                <span className="bold">29 hours </span>
                <span>across </span>
                <span className="bold">101 videos</span>
                <span className="divider"></span>
                <span className="bold">482 questions</span>
           </span>
          
        </div>
    )
}

export default ChannelCard