export const getUsersReducer = (state, action) => {
  console.log("Get Users state:", state[0].id);
  return state;
};
export const updateUsersReducer = (state, action) => {
  console.log("Get Users PROD state:", state[0].id);
  console.log("STATE:", state)
  // console.log("STATE3:", state.users);
  // console.log("In Update Avatar:", action);
  console.log("Update Avatar Payload:", action);
  const {ids, avatars} = action.payload;
  // console.log("AVATARS:", avatars);
  state?.forEach((user) => {
    const index = ids.indexOf(user.id);
    // console.log("INDEX:", index)
        if (index !== -1) {
          user.avatar = avatars[index].picture.large;
    }
  });
};