/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

const Tag = (props) => {
	const renderMobile = true; //render mobile link to sheet depending on instance/page.

	if (props.link) {
		return (
			<>
				<Link
					className='chip-btn desktop-only'
					to={'/' + props.type + '/' + props.id}
				>
					{props.title}
				</Link>
				{renderMobile && (
					<a
						href={props.id}
						type={props.type}
						className='chip-btn mobile-only'
						onClick={props.clickHandler}
					>
						{props.title}
					</a>
				)}
			</>
		);
	} else {
		return <span className={`chip-label ${props.variant}`}>{props.title}</span>;
	}
};
export default Tag;
