import React from "react";

const SuggestedCard = ({ title, image, imageText, description, author, authorImage }) => {
  const descriptionWithLineBreak = description.split("\n");
  return (
    <div className="suggested-card card">
        <div className="image-container">
          <img src={image} alt={imageText} />
        </div>
      <div className="card-body">
        <h5>{title}</h5>
        <div className="author">
            <img src={authorImage}/>
            <span>with</span>
            <span>{author}</span>
        </div>
        <p>
          {descriptionWithLineBreak.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
{/* 
      <div className="card-footer">
      
      </div> */}
    </div>
  );
};

export default SuggestedCard
