/** @format */

import React, { useState } from 'react';

const Toggle = ({ labelLeft, labelRight }) => {
	const [isSwitchOn, setIsSwitchOn] = useState(false);

	const handleChange = () => {
		setIsSwitchOn(!isSwitchOn);
	};

	return (
		<div className="toggle-container">
			<div className="toggle-labels">
				<span>{labelLeft}</span>
			</div>
			<div
				className={`toggle ${isSwitchOn ? 'switch-on' : ''}`}
				onClick={handleChange}
			>
				<div className="toggle-track">
					<div className={`toggle-thumb ${isSwitchOn ? 'switch-on' : ''}`} />
				</div>
			</div>
			<div className="toggle-labels">
				<span>{labelRight}</span>
			</div>
		</div>
	);
};

export default Toggle;
