import React from "react";
import Avatar from "../global/Avatar";
import { useSelector } from "react-redux";

//images
import crown from "../../assets/images/icons/crown.svg";
import upGreen from "../../assets/images/icons/up-green.svg";
import downRed from "../../assets/images/icons/down-red.svg";

const Leaderboard = (props) => {
    let topThreeRecords = [];
  const userRecords = useSelector((state) => {
    const filteredUsers = state.users.filter(
      (user) => user.ageGroupId === "GRP-TNS"
    );
    return filteredUsers;
  });

  // console.log("User Records:", userRecords);
  if (userRecords.length > 0) {
    // console.log("1:", typeof userRecords[0].weeklyPoints);
    // Sort the parsed records in descending order based on weekly points
    const sortedRecords = userRecords.sort(
      (a, b) => b.weeklyPoints - a.weeklyPoints
    );

    // Retrieve the top three records with the highest weekly points
    topThreeRecords = sortedRecords.slice(0, 23);
    // console.log("TopThree:", topThreeRecords);
  }
  return (
    <div className="leaderboard">
      <p>Leaderboard</p>
      <p>This week</p>
      <div className="featured">
        <div className="feature feature-2">
          <span className="rank">2</span>
          <img className="rank-icon" src={upGreen} alt="" />
          <Avatar outline="blue" size="medium" user={topThreeRecords[1]} />
          <span className="name">{topThreeRecords[1].firstName}</span>
          <span className="score">{topThreeRecords[1].weeklyPoints}</span>
        </div>

        <div className="feature feature-1">
          <span className="rank">1</span>
          <img className="rank-icon" src={crown} alt="" />
          <Avatar outline="blue" size="medium" user={topThreeRecords[0]} />
          <span className="name">{topThreeRecords[0].firstName}</span>
          <span className="score">{topThreeRecords[0].weeklyPoints}</span>
        </div>

        <div className="feature feature-3">
          <span className="rank">3</span>
          <img className="rank-icon" src={downRed} alt="" />
          <Avatar outline="blue" size="medium" user={topThreeRecords[2]} />
          <span className="name">{topThreeRecords[2].firstName}</span>
          <span className="score">{topThreeRecords[2].weeklyPoints}</span>
        </div>
      </div>
      <ul className="leaderboard-list">
        <li>
          <span className="rank">4</span>
          <div>
            <Avatar size="small" user={topThreeRecords[3]} />
            <span className="name">{topThreeRecords[3].firstName}</span>
          </div>
          <span className="score">{topThreeRecords[3].weeklyPoints} pts.</span>
        </li>
        <li>
          <span className="rank">5</span>
          <div>
            <Avatar size="small" user={topThreeRecords[4]}/>
            <span className="name">{topThreeRecords[4].firstName}</span>
          </div>
          <span className="score">{topThreeRecords[4].weeklyPoints} pts.</span>
        </li>
        <li>
          <span className="rank">23</span>
          <div>
            <Avatar size="small" user={topThreeRecords[5]} />
            <span className="name">{topThreeRecords[5].firstName}</span>
          </div>
          <span className="score">{topThreeRecords[5].weeklyPoints} pts.</span>
        </li>
      </ul>
    </div>
  );
};

export default Leaderboard;
