import React, { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import useWindowResize from '../../util/hooks/useWindowResize';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ChatBox from "../pal/ChatBox";

//data
import sidebarData from '../../assets/data/sidebar-data.json'

// images
import logo from "../../assets/images/brand/pearson_logo_reverse.svg"
import mobileMenu from "../../assets/images/icons/mobile-menu.svg"
import gridWhite from "../../assets/images/icons/grid-white.svg"
import gridGrey from "../../assets/images/icons/grid-grey.svg"
import peopleWhite from "../../assets/images/icons/people-white.svg"
import peopleGrey from "../../assets/images/icons/people-grey.svg"
import capWhite from "../../assets/images/icons/cap-white.svg"
import capGrey from "../../assets/images/icons/cap-grey.svg"
import paperWhite from "../../assets/images/icons/content-white.svg"
import paperGrey from "../../assets/images/icons/content-grey.svg"
import cardsWhite from "../../assets/images/icons/cards-white.svg"
import cardsGrey from "../../assets/images/icons/cards-grey.svg"
import chartWhite from "../../assets/images/icons/reports-white.svg"
import chartGrey from "../../assets/images/icons/reports-grey.svg"
import profileWhite from "../../assets/images/icons/user-white.svg"
import profileGrey from "../../assets/images/icons/user-grey.svg"
import developmentGrey from "../../assets/images/icons/development.svg"
import developmentWhite from "../../assets/images/icons/development-white.svg"
import companyLogo from "../../assets/images/brand/company_logo.svg"
import notifications from "../../assets/images/icons/notifications.svg"
import email from "../../assets/images/icons/email.svg"
import downArrow from "../../assets/images/icons/arrow-down.svg"

function PageWrapper(props) {

	const user = useSelector((state) => state.auth).userdata;

	const [location, setLocation] = useState(useLocation().pathname.split('/')[1]);
	const [showNav, setShowNav] = useState(null)
	const [showAvatarMenu, setShowAvatarMenu] = useState(null)

	const navigate = useNavigate();
	const [width, height] = useWindowResize()

	useEffect(()=>{
		if(width && width <= 737 && showNav === true){
			// console.log('hide')
			setShowNav(false)
		}else if(width && width > 737 && showNav === false){
			// console.log('show')
			setShowNav(true)
		}
	},[width])

	useEffect(()=>{
		if(window.innerWidth <= 737){
			setShowNav(false)
		}else if(window.innerWidth > 737){
			setShowNav(true)
		}
	},[])

	const handleMobileMenu = () => {
		setShowNav(!showNav)
	}

	const getIcon = (name, active) => {
		let iconWhite, iconGrey;
		switch(name) {
			case 'grid':
			  iconWhite = gridWhite
			  iconGrey = gridGrey
			  break;
			  case 'people':
				iconWhite = peopleWhite
				iconGrey = peopleGrey
			  break;
			  case 'cap':
				iconWhite = capWhite
				iconGrey = capGrey
			  break;
			  case 'paper':
				iconWhite = paperWhite
				iconGrey = paperGrey
			  break;
			  case 'cards':
				iconWhite = cardsWhite
				iconGrey = cardsGrey
			  break;
			  case 'chart':
				iconWhite = chartWhite
				iconGrey = chartGrey
			  break;
			  case 'development':
				iconWhite = developmentWhite
				iconGrey = developmentGrey
			  break;
			  case 'profile':
				iconWhite = profileWhite
				iconGrey = profileGrey
			  break;
			default:
				iconWhite = gridWhite
				iconGrey = gridGrey
		  }
		  if(active){
			return iconWhite
			console.log(iconWhite)
		  }else{
			return iconGrey
		  }
	}

	const getInitials = () => {
		if(user){
			let firstInitial = user.firstName.charAt(0);
			let lastInitial = user.lastName.charAt(0);
			return firstInitial + lastInitial
		}else{
			return 'TE'
		}
		
	}
	
	return (
		<div className={`page-wrapper ${props.page} ${user.occupation}`}>
			<ChatBox user={user}/>
			<nav className={`${showNav ? 'show-mobile-menu' : ''}`}>
				<div>
					<img src={logo}/>
					<button className='mobile-menu' onClick={handleMobileMenu}>
						<img src={mobileMenu}/>
					</button>
				</div>
				<ul className={`${showNav ? 'show' : ''}`}>
					{
						sidebarData.map((item, i)=>{
							if(item.roles.includes(user.occupation)){

								let name = item.name.toLowerCase()
								if(name === "my courses"){
									name = "courses"
								}
								let url = process.env.PUBLIC_URL+`/${name.replace(/\s/g, '')}`

								return(
									<li key={i}>
										<Link to={url} 
											aria-pressed={location === `${name.replace(/\s/g, '')}` ? "true" : "false"}
											className={location === `${name.replace(/\s/g, '')}` ? 'active' : ''}
										>
											<img src={location === `${name.replace(/\s/g, '')}` ? getIcon(item.icon, true) : getIcon(item.icon)}/>
											<span>{item.name}</span>
										</Link>
									</li>
								)
							}
							
						})
					}
					{
						window.innerWidth <= 737 ? <>
							<li>
								<button className={`mobile-only ${location === `profile` ? 'active' : ''}`} onClick={()=>{
								navigate('/profile')
							}}>
								<img src={location === `profile` ? profileWhite : profileGrey}/>
								<span>Profile</span>
								</button></li>
							<li><button className="mobile-only" onClick={()=>{
								navigate('/sign-out')
							}}><span style={{marginLeft:0}}>Log out</span></button></li>
						</> : null
					}
				</ul>
			</nav>
			<main>
				<div className='main-content'>
					<header>
						<img src={companyLogo}/>
						<div className='header-actions'>
							<button>
								<img src={notifications}/>
							</button>
							<button>
								<img src={email}/>
							</button>
							<button className='avatar' aria-expanded={showAvatarMenu}
								onClick={()=>{
									setShowAvatarMenu(!showAvatarMenu)
								}} 
							>
								<span>{getInitials()}</span>
								<img className='arrow' src={downArrow}/>
							</button>
							{
								showAvatarMenu ? <div className='avatar-menu'>
									<button onClick={()=>{
										navigate('/profile')
									}}>Profile</button>
									<button onClick={()=>{
										navigate('/sign-out')
									}}>Log out</button>
								</div> : null
							}
						</div>
					</header>
					{props.children}
				</div>
			</main>
		</div>
	);
}


export default PageWrapper
