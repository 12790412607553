import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const DevelopmentDonut = (props) => {

    let data = [
        {
            name: `${props.title}`,
            hoursLeft: 10
        },
        {
            name: `total`,
            hoursLeft: 0
        }
    ]
    return (
    <div className="progress-ring development" style={{width: props.size + "px", height: props.size+"px"}} role="img" aria-label={props.ariaLabel}>
        <div className="chart-container">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width="130" height="130" cx="50%" cy="50%" >
                    <defs>
                        <linearGradient id={"gradientTealD"+props.title.replace(" ","")} x1="0" y1="1" x2="1" y2="0">
                            <stop offset={"30%"} stopColor="#2ABAAF" stopOpacity={0.8}/>
                            <stop offset={"150%"} stopColor="#2ABAAF" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                    <Pie data={data} innerRadius={"55"} outerRadius={"65"} dataKey="hoursLeft" startAngle={90} endAngle={450} stroke={0}>
            <Cell fill={"url(#gradientTealD"+props.title.replace(" ","") + ")"} />
            <Cell fill="#eaeaea" />

            </Pie>
                </PieChart>
            </ResponsiveContainer>
            <span className={`ring-icon percentage` }>
            <span className="percent">{props.remaining}hrs</span>
            </span>
        </div>
       
       
        <p className="chart-label">You have {props.remaining} hours left of this course</p>
    </div>
    )
}
export default DevelopmentDonut