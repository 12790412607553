/** @format */

import React, { useState } from 'react';
import Avatar from '../global/Avatar';
import MessageTextInput from './MessageTextInput';
//images
import chevronRight from '../../assets/images/icons/chevron-right.svg';

const MessageWindow = (props) => {
	//text type and enter
	const [messages, setMessages] = useState([]);
	const textEnter = (text) => {
		const newMessage = {
			id: messages.length + 1,
			text: text,
			windowId: props.user.id,
		};
		setMessages([...messages, newMessage]);
	};
	const windowMessages = messages.filter(
		(message) => message.windowId === props.user.id
	);

	const ReturnToMessages = () => {
		props.onReturnToMessages();
	};

	return (
		<>
			<div className="return-msg">
				<button
					className="icon-button"
					onClick={ReturnToMessages}
				>
					<img
						src={chevronRight}
						alt="Back"
					/>
					<span>Return to Messages</span>
				</button>
			</div>

			<div className="msg-window">
				<div className="chat-container">
					<section className="user-msg-group">
						<Avatar
							size="small"
							user={props.user}
						/>
						<div className="user-chat">
							<p className="name">
								<b>
									{props.user.firstName} {props.user.lastName}
								</b>
							</p>
							<p>
								<i>{props.randomText}</i>
							</p>
						</div>
					</section>

					<section className="my-msg-group">
						{windowMessages.map((message) => (
							<>
								<div className="my-chat">
									<p key={message.id}>
										<i>{message.text}</i>
									</p>
								</div>
								<div className="my-avatar">
									<span>UT</span>
								</div>
							</>
						))}
					</section>
				</div>

				<MessageTextInput
					placeholder="Type here to chat"
					onEnter={textEnter}
					windowId={props.user.id}
				/>
			</div>
		</>
	);
};

export default MessageWindow;
