import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

/**
 * Displays content inside the wrapper
 * if the toggle button is clicked
 * @param {*} param0
 * @returns
 */
const ContentToggle = ({ sectionName, header, children, showContent }) => {
  // console.log("ContentToggle: ShowContent:", showContent);
  const [displayProjectToggle, setDisplayProjectToggle] = useState(true);

  const handleShow = (err, sectionName) => {
    err.preventDefault();
    // console.log("HandleShow:", sectionName);
    setDisplayProjectToggle(!displayProjectToggle);
  };

  return (
    <div className={showContent ? "content-toggle-show" : "content-toggle-hide"}>
      <div className="content-toggle-chevron">
        {/*Header*/}
        <p className="content-toggle-header">
          {header} {sectionName}
        </p>
        <FontAwesomeIcon
          className="mg-l-4"
          icon={
            displayProjectToggle
              ? faChevronUp
              : faChevronDown
          }
          style={{ color: 'black' }}
          onClick={(e) => handleShow(e, sectionName)}
        />
      </div>{" "}
      {/*Header*/}
      {/*Content*/}
      <div className={displayProjectToggle ? "content-toggle-show" : "content-toggle-hide"}>{children}</div>
      {/*Content*/}
    </div>
  );
};

export default ContentToggle;
