import React from "react";
import Icon from "../global/Icon";
import RecommendationCard from "../global/RecommendationCard";
// images
import rec1 from "../../assets/images/recommendations/teaching.png";
import rec2 from "../../assets/images/recommendations/mindfulness.png";
import rec3 from "../../assets/images/recommendations/classroom.png";
import rec4 from "../../assets/images/recommendations/trinity.png";

const RecommendationsList = (props) => {

    const recommendations = [
        {
            title: "Effective Teaching Strategies",
            image: {
                url: rec1,
                alt: "Stack of books with a red apple on top"
            },
            description: "Establishing clear classroom boundaries is crucial for creating a productive and respectful learning environment. "
        },
        {
            title: "Mindfulness for Educators",
            image: {
                url: rec2,
                alt: "Woman in glasses in front of computer holding a book"
            },
            description: "Mindfulness is the practice of training the mind to enhance awareness of our thoughts, feelings, and sensations."
        },
        {
            title: "Advancing your Online Teaching Practices",
            image: {
                url: rec3,
                alt: "Group of people at a table looking at a screen"
            },
            description: "Online teaching has the potential to create a significant impact on students' learning and growth. Here are some strategies..."
        },
        {
            title: "Trinity College Certification",
            image: {
                url: rec4,
                alt: "Trinity College London GESE"
            },
            description: "Trinity College London conducts examinations in music (including instrumental and vocal), drama, speech and communication..."
        }
    ]

    return (
        <section className="recommendations">
            <div className="section-header">
                <h3 className="section-title">Recommended modules for you</h3>
                {props.development && <p>These modules will help you with the above areas of study for <strong className="course-name">{props.development}</strong>!</p>}
            </div>
            <div className="section-body">
                <div className="card-row overflow-scroll">
                    {recommendations.map((rec, index) => {
                        return (
                            <RecommendationCard data={rec} key={"rec"+index}/>
                        )
                    })}
                </div>

            </div>
            <div className="section-footer">
                <a className="link-button">
                    <span>More professional development content</span>
                    <span><Icon icon="chevron-right" size="18"/></span>
                </a>
            </div>
        </section>
    )
}
export default RecommendationsList