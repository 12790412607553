import React, {useEffect, useState} from "react";
import PageWrapper from "./global/PageWrapper";
import PALRecommendation from "./global/PALRecommendation";
import Leaderboard from "./student_home/Leaderboard";
import News from "./student_home/News";
import Podcasts from "./student_home/Podcasts";
import { useSelector, useDispatch } from "react-redux";
import LearningTimeline from "./infographics/LearningTimeline";
import Channels from "./student_home/Channels";
import RecommendationsList from "./instructor_home/RecommendationsList";
import ToDoList from "./instructor_home/ToDoList";
import InstructorSchedule from "./instructor_home/ScheduleList";
import AdminSchedule from "./admin_home/ScheduleList";
import Development from "./instructor_home/Development";
import Overview from "./admin_home/Overview";
import ChatBox from "./pal/ChatBox";

const Home = (props) => {

  const user = useSelector((state) => state.auth).userdata;
  const [chatOpen, setChatOpen] = useState(false);

  return (
    <PageWrapper page="home">
      <div className="hero-banner">
        <h2>Welcome back, {user.firstName}!</h2>
      </div>
      <div className="page">
        {
          user.occupation === "student" ? (
            <>
              <div className="column1">
                <PALRecommendation />
                {/* <div className="learning-timeline">
                  <LearningTimeline/>
                </div> */}
                <Channels/>
              </div>
              <div className="column2">
                <Leaderboard />
                <News />
                <Podcasts />
              </div>
            </>
          ) : null
        }

        {
          user.occupation === "instructor" ? <>
               <PALRecommendation />
               <InstructorSchedule />
               <div className="row">
                <div className="column1">
                    <ToDoList/>
                </div>
                <div className="column2">
                    <Development/>
                </div>
               </div>
               <RecommendationsList/>
              
          </> : null
        }

        {
          user.occupation === "administrator" ? <>
               <PALRecommendation />
               <Overview/>
               <AdminSchedule />
          </> : null
        }

      </div>
    </PageWrapper>
  );
};

export default Home;
