/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Breadcrumbs from '../global/Breadcrumbs';
import PageWrapper from '../global/PageWrapper';

/**components */
import SelectStudentCard from './input/SelectStudentCard';
import SelectStudentFilterToggle from './input/SelectStudentFilterToggle';
import SearchInput from '../global/SearchInput';

const SelectStudent = (props) => {
	const Users = useSelector((state) => state.users);
	const [shuffledUsers, setShuffledUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);

	/**shuffling userCard logic only once */
	useEffect(() => {
		const shuffledArray = [...Users];
		for (let i = shuffledArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffledArray[i], shuffledArray[j]] = [
				shuffledArray[j],
				shuffledArray[i],
			];
		}
		setShuffledUsers(shuffledArray);

		if (shuffledArray.length > 0) {
			setSelectedUser(shuffledArray[0]);
		}
	}, []);
	const slicedUsers = shuffledUsers.slice(0, 12);

	const params = useParams();
	const { courseId } = params;
	const courses = useSelector((state) => state.courses);

	const course = useSelector((state) =>
		courses.find((record) => record.id.toLowerCase() === courseId.toLowerCase())
	);

	const breadcrumbs = [
		{
			page: 'Courses',
			target: '/courses',
		},
		{
			page: `${course.cefrLevel} ${course.name} (${course.ageGroup})`,
			target: '',
		},
		{
			page: 'New Lesson',
			target: '/newlesson',
		},
	];

	return (
		<PageWrapper>
			<div className="course-detail">
				<Breadcrumbs data={breadcrumbs} />
				<h1 className="text-h3">Who should attend this lesson?</h1>

				<section className="select-student header">
					<div className="section-header">
						<h3>Suggested</h3>
						<p>
							These students will benefit from this class based on their current
							course progression.
						</p>
					</div>

					<div className="select-student-row ">
						{slicedUsers.map((user, index) => (
							<SelectStudentCard
								key={index}
								user={user}
								bgColor="white"
							/>
						))}
					</div>
				</section>

				<section className="select-student body">
					<div className="toggle-box">
						<SelectStudentFilterToggle />
					</div>
					<div className="search-student-container">
						<div className="search-box">
							<SearchInput
								placeholder={`Search student directory`}
								isHeader={true}
							/>
							<div className="student-card-box">
								{slicedUsers.map((user, index) => (
									<SelectStudentCard
										key={index}
										user={user}
										bgColor="dark"
									/>
								))}
							</div>
						</div>
					</div>
				</section>
				<div className="create-lesson-btn">
					<Link to={'/course/' + course.id}>
						<button className="primary">Create lesson</button>
					</Link>
				</div>
			</div>
		</PageWrapper>
	);
};

export default SelectStudent;
