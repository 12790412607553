import React from "react";

const Avatar = (props) => {


    return (
        <img className={`avatar outline-${props.outline} ${props.size}`} 
            src={props.user ? props.user.avatar : 'https://assets.pearsonct.design/avatar/63d6e420807516d2739002ee/jeremy.jpg'}
        />
    )
}

export default Avatar
