import React, {useState, useEffect} from "react";
import PageWrapper from "./global/PageWrapper";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CourseCard from "./global/CourseCard"
import Icon from "./global/Icon"
import Avatar from "./global/Avatar";

//images
import pal from "../assets/images/brand/pal.svg"
import filterWhite from "../assets/images/icons/filter-white.svg"
import filterDark from "../assets/images/icons/filter-dark.svg"
import clockWhite from "../assets/images/icons/clock-white.svg"
import search from "../assets/images/icons/search-white.svg"
import plusButton from "../assets/images/icons/plus-button.svg"
import location from "../assets/images/icons/location.svg"

const Courses = (props) => {

    const user = useSelector((state) => state.auth).userdata;
    const users = useSelector((state) => state.users);
    const courses = useSelector((state) => state.courses);

    const newCourses = [
        {
          "id": "ENG-ADS-A1-000",
          "location": "In person",
          "semester": "SEM-SPR",
          "instructor": "INS-000",
          "name": "Wider World 2nd Edition, Level 2",
          "ageGroup": "",
          "cefrLevel": "",
          "gseRange": [22,29],
          "gseThreshhold": 29,
          "Proficiency": "beginner-1",
          "cefrDescriptions": "In this course, you will look at family life for an array of people all over the world! This will give you a deeper look into different cultures and customs.",
          "book":"Roadmap A1",
        }
    ]

    const newLessons = [
        {
            "id": "L1",
            "name": "B1+ General English",
            "description": "Looking at Grammar and working with the Future tense. It is recommended that students have, The Future is Now, text book.",
            "startTime": "10am",
            "endTime": "18:00",
            "course": "B1+ General English",
            "id": "ENG-ADS-A1-000",
            "ageGroup": "Adults",
            "location": "Online"
        },
        {
            "id": "L1",
            "name": "Speakout B1",
            "description": "Looking at Grammar and working with the Future tense. It is recommended that students have, The Future is Now, text book.",
            "startTime": "11am",
            "endTime": "18:00",
            "course": "Speakout B1",
            "id": "ENG-ADS-B1P-000",
            "ageGroup": "Adults",
            "location": "In person"
        },
        {
            "id": "L1",
            "name": "Roadmap B1",
            "description": "Looking at Grammar and working with the Future tense. It is recommended that students have, The Future is Now, text book.",
            "startTime": "4pm",
            "endTime": "18:00",
            "course": "Roadmap B1",
            "id": "ENG-ADS-A1-000",
            "ageGroup": "Adults",
            "location": "Online"
        },
    ]

    const [filteredLessons, setFilteredLessons] = useState([])

    const userType = {
        YOUNGLEARNERS: "GRP-YTS",
        TEEN: "GRP-TNS",
        ADULTS: "GRP-ADS",
        // ADMIN: "GRP-ADM",
        // INSTRUCTOR: "GRP-INS"
    }

    // useEffect(()=>{
    //     if(courses){
    //         getTodaysCourses()
    //     }
    // },[courses])

    // const getTodaysCourses = () => {
    //     let newArray = []
    //     courses.forEach(course=>{
    //         let lessons = course.lessons
    //         course.lessons.forEach(lesson=>{
    //             let today = new Date()
    //             let dayCheck = today.getDay()
                
    //             //if you're testing this prototype over the weekend
    //             //change "today" to be Monday
    //             if(dayCheck === 0){
    //                today.setDate(today.getDate() + 1);
    //             } 
    //             else if(dayCheck === 6){
    //                 today.setDate(today.getDate() + 2);
    //             }

    //             if(new Date(lesson.date).getDate().toString() === today.getDate().toString()){
    //                 let lessonCopy = {...lesson}
    //                 let courseCopy = {...course}
    //                 delete courseCopy.lessons;
    //                 lessonCopy.course = courseCopy
    //                 newArray.push(lessonCopy)
    //                 return
    //             }
    //         })
    //     })
    //     setFilteredLessons(newArray)
    // }

    const getLessonUsers = (lesson) => {
        // console.log(lesson)
        let ageGroup = lesson.ageGroup.toUpperCase().replace(/\s/g, '');
        let filteredUsers = users.filter((user) => user.ageGroupId === userType[ageGroup] && user.cohort.includes(lesson.id));
        console.log(filteredUsers)
        return (
            filteredUsers.map((user, index)=>{
                return <Avatar key={index} user={user} size='small' outline="white"/>
            })
        )
    }



    return (
        <PageWrapper>
        {
            user.occupation === "instructor" || user.occupation === "administrator" ?
            (
                <div className="courses">
                    <h2>My Courses</h2>
                    <div className="controls">
                        <div className="search-container">
                            <input type="search" placeholder="Search..."/>
                            <button>
                                <img src={search}/>
                            </button>
                        </div>
                        <button className="secondary">
                            Filter
                            <img src={filterDark}/>
                        </button>
                    </div>
                    
                    <div className="course-cards">
                        {
                            courses.map((course, index)=>{
                                return (
                                    <CourseCard course={course}/>
                                )
                            })
                        }
                    </div>
                </div>

            ) : (
                <div className="student-courses">
                        <div className="background"></div>
                        <h2>My Courses</h2>
                        <div className="assignment-card">
                            <div className="pal-badge">
                                <img src={pal}/>
                                <span><span>PAL</span> Assignment due today</span>
                            </div>
                            <div className="date">
                                <p className="bold">Today</p>
                                <p>5pm</p>
                            </div>
                            <p className="name">Speak Out, B1</p>
                            <p className="author">Grammar</p>
                            <p>Take this Quick Quiz to test your Grammar. Use the Speak Out text book for help.</p>
                            {/* <a className="bold">Go to assignment</a> */}
                        </div>
                        <section className="lessons">
                            <h3>Today’s schedule</h3>
                            {  
                                newLessons.map((lesson, index)=>{
                                    return (
                                        <div className="lesson" key={index}>
                                            <div className="date">
                                                <p className="bold">Today</p>
                                                <p>{lesson.startTime}</p>
                                            </div>
                                            <div>
                                                <div className="course-info">
                                                    <p className="name bold">{lesson.course}</p>
                                                    <div className="location">
                                                        <img src={location}/>
                                                        <p>{lesson.location}</p>
                                                    </div>
                                                </div>
                                                <div className="students">      
                                                    {getLessonUsers(lesson)}
                                                </div>
                                            </div>
                                        </div>
                                    ) 
                                    
                                })
                            }
                            <div className="section-footer">
                                <a className="link-button">
                                    <span>This week</span>
                                    <span><Icon icon="chevron-right" size="18"/></span>
                                </a>
                            </div>
                            
                        </section>
                        <section>
                            <h3>Suggested courses</h3>
                            <CourseCard course={newCourses[0]} student={true}/>
                        </section>
                </div>
            )
        }
        </PageWrapper>
    )

   
}

export default Courses
