import React from "react";

//images
import clock from "../../assets/images/icons/clock-grey.svg"

const News = (props) => {

    return (
        <div className="news">
            <h4>News</h4>
            <ul>
                <li>
                    <p>South American’s presidents gather in Brazil for first regional summit in 9 years</p>
                    <div>
                        <img src={clock}/>
                        <span>1 hour ago</span>
                    </div>
                </li>
                <li>
                    <p>Joelinton earns first Brazil call-up, joins Bruno</p>
                    <div>
                        <img src={clock}/>
                        <span>2 hour ago</span>
                    </div>
                </li>
                <li>
                    <p>Goals and highlights: Brazil 2-0 Nigeria in U-20 World Cup</p>
                    <div>
                        <img src={clock}/>
                        <span>4 hour ago</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default News