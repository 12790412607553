import React from "react";
import ChannelsList from './ChannelsList'

const Channels = (props) => {

    return (
        <div className="channels">
            <h4 className="bold">Watch & Learn with Pearson+ Channels</h4>
            <p>Immerse yourself in English with content based on your interests!</p>
            <ChannelsList interest='Science'/>
            <ChannelsList interest='Politics'/>
        </div>
        
    )
}

export default Channels