import React from "react";
import Avatar from '../global/Avatar'

//images
import locationTeal from "../../assets/images/icons/location-teal.svg"
import locationWhite from "../../assets/images/icons/location-white.svg"

const UserCard = (props) => {

    return (
        <div className={`user-card ${props.profile ? 'profile' : ''}`}>
            <Avatar outline='white' size='large' user={props.user}/>
            <p className="name">{`${props.user.firstName} ${props.user.lastName}`}</p>
            <div className="location">
                <img src={props.profile ? locationWhite : locationTeal} alt=""/>
                <span>{`${props.user.city}, ${props.user.country}`}</span>
            </div>
            <p className="level">English level: {props.user.englishLevel}</p>
        </div>
    )
}

export default UserCard
