import React from "react";

//images
import award from "../../assets/images/icons/PEIC-logo.svg"
import courseImg from "../../assets/images/courses/C1_Corporate_English_Adults.jpg"
import capGrey from "../../assets/images/icons/cap-grey.svg"
import calendarGrey from "../../assets/images/icons/calendar-grey.svg"
import lessonGrey from "../../assets/images/icons/lesson-grey.svg"
import clockGrey from "../../assets/images/icons/clock-grey-small.svg"
import digitalHuman from "../../assets/images/study/study-digitalhuman.png"
import article from "../../assets/images/study/study-article.png"
import games from "../../assets/images/study/study-games.png"
import channels from "../../assets/images/study/study-channels.png";

const LearningPlan = (props) => {

    return (
        <div className="learning-plan">
            <div className="learning-step active">
                <div className="learning-step-sync">
                    <div className="card">
                        <div className="card-header"><h4>Course 1</h4></div>
                        <div className="card-body">
                            <div className="course-info">
                                <div className={`square image`}>
                                    <img src={courseImg}/>
                                </div>
                                <div className="name-info">
                                    <h5>B1+ General English (Teens)</h5>
                                    <p>Start date: Jun 2023</p>
                                </div>
                            </div>

                            <div className="meta">
                                    <div className="instructor">
                                        <span class="icon-grey-circle">
                                            <img src={capGrey}/>
                                        </span>
                                        <span>Márcia Da Silva</span>
                                    </div>
                                    <div className="students">
                                        <span class="icon-grey-circle">  
                                            <img src={lessonGrey}/>
                                        </span>
                                        <span>Online</span>
                                    </div>
                                    <div className="meta-time">
                                        <span class="icon-grey-circle" >
                                            <img src={clockGrey}/>
                                        </span>
                                        <span>7am - 8am</span>
                                    </div>
                                    <div className="lesson-type">
                                        <span class="icon-grey-circle">
                                            <img src={calendarGrey}/>
                                        </span>
                                        <span>Monday & Thursday<br/>120 hours</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4 className="text-h5">Benchmark test</h4>
                    </div>
                    <div className="card">
                        <div className="card-header"><h4>Course 2</h4></div>
                        <div className="card-body">
                            <div className="course-info">
                                <div className={`square image`}>
                                    <img src={courseImg}/>
                                </div>
                                <div className="name-info">
                                    <h5>B2 General English (Teens)</h5>
                                    <p>Start date: Mar 2024</p>
                                </div>
                            </div>

                            <div className="meta">
                                    <div className="instructor">
                                        <span class="icon-grey-circle">
                                            <img src={capGrey}/>
                                        </span>
                                        <span>Márcia Da Silva</span>
                                    </div>
                                    <div className="students">
                                        <span class="icon-grey-circle">  
                                            <img src={lessonGrey}/>
                                        </span>
                                        <span>Online</span>
                                    </div>
                                    <div className="meta-time">
                                        <span class="icon-grey-circle" >
                                            <img src={clockGrey}/>
                                        </span>
                                        <span>6pm - 7pm</span>
                                    </div>
                                    <div className="lesson-type">
                                        <span class="icon-grey-circle">
                                            <img src={calendarGrey}/>
                                        </span>
                                        <span>Tuesday & Friday<br/>120 hours</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="learning-step-async">
                    <div className="card">
                        <div className="card-header"> <h4>Self-study</h4></div>
                        <div className="card-body">
                            <p>Based on his interests in 🍕 Food, ⚽ Soccer and 🗞️ Politics, and the B1+ General English (Secondary) course. </p>
                            <div className="study-list">
                                <ul>
                                    <li>
                                        <div className="study-rec"> {/*Not sure if this should be a link */}
                                            <div className="study-info">
                                                <img src={digitalHuman} alt=""/>
                                                <p className="study-name">
                                                    <span className="study-label">Digital human</span>
                                                    News & Politics
                                                </p>
                                            </div>
                                            <div className="tag gray">Speaking</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="study-rec"> {/*Not sure if this should be a link */}
                                            <div className="study-info">
                                                <img src={channels} alt=""/>
                                                <p className="study-name">
                                                    <span className="study-label">Pearson Channels</span>
                                                    Food Around the World
                                                </p>
                                            </div>
                                            <div className="tag gray">Listening</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="study-rec"> {/*Not sure if this should be a link */}
                                            <div className="study-info">
                                                <img src={digitalHuman} alt=""/>
                                                <p className="study-name">
                                                    <span className="study-label">Digital human</span>
                                                    Hobbies & Interests
                                                </p>
                                            </div>
                                            <div className="tag gray">Speaking</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="study-rec"> {/*Not sure if this should be a link */}
                                            <div className="study-info">
                                                <img src={article} alt=""/>
                                                <p className="study-name">
                                                    <span className="study-label">Daily article</span>
                                                    Football in the News
                                                </p>
                                            </div>
                                            <div className="tag gray">Reading</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="study-rec"> {/*Not sure if this should be a link */}
                                            <div className="study-info">
                                                <img src={games} alt=""/>
                                                <p className="study-name">
                                                    <span className="study-label">Games</span>
                                                    Building Complex Sentences
                                                </p>
                                            </div>
                                            <div className="tag gray">Writing</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="learning-step full-width">
                <div className="card">
                    <h4 className="text-body">
                        <strong>Readiness Test</strong> for Pearson English International Certificate (PEIC) Test
                    </h4>
                </div>
                <div className="card full-width learning-goal">
                    <span className="icon">
                        <img src={award} alt="" />
                    </span>
                    <h4>Pearson English International Certificate (PEIC) test</h4>
                </div>
            </div>

        </div>
    )
}

export default LearningPlan