import React from "react";

const Card = (props) => {

    return (
        <div className={`card ${props.modifier ? props.modifier : ""}`}>
            {props.children}
        </div>
    )
}
export default Card