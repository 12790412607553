import React from "react";
import Icon from "../global/Icon";

const LessonAgenda = (props) => {

    const lesson = props.lesson

    const getDate = (date) => {
        let lessonDate = new Date(date)
        return lessonDate.toLocaleDateString()
    }


    return (
        <div className="lesson-agenda">
            <h2 className="agenda-header">
                <span className="day">
                    <Icon icon="date" size="24"></Icon>
                    <span>{getDate(lesson.date)}</span>
                </span>
                <span className="time">
                    <Icon icon="time" size="24"></Icon>
                    <span>{lesson.startTime} - {lesson.endTime}</span>
                </span>
                <span className="location">
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3295 28.2143C15.7313 28.2143 16.1079 28.1083 16.4595 27.8962C16.8111 27.6842 17.0761 27.394 17.2547 27.0257L23.3485 14.0681C23.7168 13.2868 23.9009 12.2879 23.9009 11.0714C23.9009 8.70536 23.0639 6.68527 21.3898 5.01116C19.7156 3.33705 17.6956 2.5 15.3295 2.5C12.9634 2.5 10.9433 3.33705 9.26922 5.01116C7.59511 6.68527 6.75806 8.70536 6.75806 11.0714C6.75806 12.2879 6.94221 13.2868 7.31051 14.0681L13.421 27.0257C13.5884 27.394 13.8479 27.6842 14.1995 27.8962C14.551 28.1083 14.9277 28.2143 15.3295 28.2143ZM15.3295 15.3571C14.1464 15.3571 13.1364 14.9386 12.2994 14.1016C11.4623 13.2645 11.0438 12.2545 11.0438 11.0714C11.0438 9.88839 11.4623 8.87835 12.2994 8.04129C13.1364 7.20424 14.1464 6.78571 15.3295 6.78571C16.5125 6.78571 17.5226 7.20424 18.3596 8.04129C19.1967 8.87835 19.6152 9.88839 19.6152 11.0714C19.6152 12.2545 19.1967 13.2645 18.3596 14.1016C17.5226 14.9386 16.5125 15.3571 15.3295 15.3571Z" fill="currentColor"/>
                    </svg>
                    <span>{props.location}</span>
                </span>
            </h2> 
            <div className="agenda-body">
                <h3>Lesson agenda</h3>
                {lesson.agenda.map((item, index) => {
                    return (
                        <div className="agenda-item" key={"agenda"+index}>
                            <span className="agenda-time">{item.time}</span>
                            <span className="bar"></span>
                            <span className="agenda-activity">{item.activity}</span>
                        </div>
                    )
                })}

                <button className="link-button">
                    <span>Edit</span>
                    <span><Icon icon="chevron-right" size="18"/></span>
                </button>
            </div>
        </div>
    )
}
export default LessonAgenda