import React from "react";
import PageWrapper from "./global/PageWrapper";

const Games = (props) => {

    return (
        <PageWrapper>
            <p>Games</p>
        </PageWrapper>
    )
}

export default Games
