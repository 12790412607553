import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageWrapper from "../global/PageWrapper";
import TeacherLesson from "./TeacherLesson";
import Card from "../achieve_archive/global/cards/Card";

//images
import assignments from "../../assets/images/assignments/assignments.png";
import pal from "../../assets/images/assignments/pal.png";

const Lesson = (props) => {

  const params = useParams();
  const { courseId, lessonId } = params;

  const user = useSelector((state) => state.auth).userdata;

  const courses = useSelector((state) => state.courses);
  const course = useSelector((state) => {
    return courses.find(
      (record) => record.id?.toLowerCase() === courseId.toLowerCase()
    )
  });

  const lesson = useSelector((state) => {
    return course.lessons.find((record) => record.id?.toLowerCase() === lessonId.toLowerCase())
  });

  return (
    <PageWrapper page="lesson">
       <TeacherLesson course={course} lesson={lesson} />
    </PageWrapper>
  );
};

export default Lesson
