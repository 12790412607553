import React from "react";
import PageWrapper from "./global/PageWrapper";
import { Link } from "react-router-dom";

//images
import pal from "../assets/images/brand/pal.svg"
import rec1 from "../assets/images/student_mylearning/rec-card-1.png"
import rec2 from "../assets/images/student_mylearning/rec-card-2.png"

const MyLearning = (props) => {

    return (
        <PageWrapper>
            <div className="mylearning">
                <div className="background"></div>
                <h2>My learning</h2>
                <div className="hero-card">
                    <div className="pal-badge">
                        <img src={pal}/>
                        <span><span>PAL</span> Your virtual assistant</span>
                    </div>
                    <p className="title">Your personalized learning plan</p>
                    <p>Reach a <strong>B2</strong> level of English <strong>in 18 months</strong >and then take the <strong>Pearson English International Certificate</strong></p>
                </div>
                <div className="card rec-card">
                    <div className="image-container">
                        <img src={rec1}/>
                        <div className="tag">Online/In-person</div>
                    </div>
                    <div className="card-body">
                        <p className="title">Join a language school</p>
                        <p>Enrol in a school partnered with Pearson and learn with a teacher and classmates</p>
                        <button className="primary">Find schools near me</button>
                    </div>
                </div>

                <div className="card rec-card">
                    <div className="pal-badge">
                        <img src={pal}/>
                        <span><span>PAL</span> Recommends</span>
                    </div>
                    <div className="image-container">
                        <img src={rec2}/>
                        <div className="tag">Online/In-person</div>
                    </div>
                    <div className="card-body">
                        <p className="title">Learn at your own pace</p>
                        <p>Reach your learning goals with a study plan made just for you. Mix it up with 1:1 lessons.</p>
                        <Link className="primary" to="/mylearning/detail">View recommended plan</Link>
                    </div>
                </div>

                <a>I just want to explore</a>
            </div>
        </PageWrapper>
    )
}

export default MyLearning