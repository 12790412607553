import React from "react";
import Icon from "./Icon";

const RecommendationCard = (props) => {
    let data = props.data

    return (
        <div className="card recommendation rounded">
            <div className="card-header">
                <h4 className="card-title">{data.title}</h4>
            </div>
            <div className="card-body">
                <div className="image" style={{backgroundImage: "url(" + data.image.url + ")"}}>
                    <img src={data.image.url} alt={data.image.alt}/>
                </div>
                <p className="desc">{data.description}</p>
            </div>
            <div className="card-footer">
                <a className="link-button">
                    <span>Read</span>
                    <span><Icon icon="chevron-right" size="18"/></span>
                </a>
            </div>      
        </div>
    )
}
export default RecommendationCard