import React from 'react';
import Checkbox from './Checkbox';

const CheckList = ({checkListNames}) => {
    // console.log('CheckList:', checkListNames)
    const displayCheckBoxes = () => { 
        return checkListNames.map((checkListName, index) => {
            return <Checkbox key={index} label={checkListName} />
        })
    }   
    return (
        <div>
            {displayCheckBoxes()}
        </div>
    );
};

export default CheckList;