import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AUTH_BASE_URL from '../util/AUTH_BASE_URL';
import { coursesLogout } from './coursesSlice';
import { groupsLogout } from './groupsSlice';
import { semestersLogout } from './semestersSlice';
import { skillsLogout } from './skillsSlice';
import { usersLogout } from './usersSlice';
import { sessionLogout } from './sessionSlice';

const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

export const checkAuthAsync = createAsyncThunk(
  'auth/checkAuthAsync',
  async () => {
    const date = +new Date();
    const resp = await fetch(`${AUTH_BASE_URL}auth/session/?d=${date}`, { credentials: 'include' });
    if (resp.ok) {
      const json = await resp.json();
      let authReturn = {};
      if (json.user) {
        authReturn.loggedin = true;
        authReturn.userdata = json.user;
      } else {
        authReturn.loggedin = false;
      }
      return { authReturn };
    }
  }
);

export const deleteAuthAsync = createAsyncThunk(
  'auth/deleteAuthAsync',
  async () => {
    const resp = await fetch(`${AUTH_BASE_URL}auth/session/`, { method: 'DELETE', credentials: 'include' });
    const data = await resp.json();
    let authReturn = {};
    authReturn.loggedin = false;
    authReturn.userdata = {};
    return { authReturn };
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    // Dispatch logout actions from other slices here
    dispatch(coursesLogout());
    dispatch(groupsLogout());
    dispatch(semestersLogout());
    dispatch(skillsLogout());
    dispatch(usersLogout());
    dispatch(sessionLogout());
    // ...dispatch other logout actions...

    // Reset auth state
    return { loggedin: false, userdata: {} };
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: { loading: true, loggedin: false, userdata: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkAuthAsync.fulfilled, (state, action) => {
      return action.payload.authReturn;
    });
    builder.addCase(checkAuthAsync.pending, (state, action) => {
      return { loading: true, loggedin: false, userdata: {} };
    });
    builder.addCase(deleteAuthAsync.fulfilled, (state, action) => {
      return action.payload.authReturn;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export default authSlice.reducer;
