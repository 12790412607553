/** @format */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

const MessageTextInput = (props) => {
	const [inputText, setInputText] = useState('');

	const enterKeyPress = (event) => {
		if (event.key === 'Enter') {
			props.onEnter(inputText, props.windowId); 
			setInputText('');
		}
	};

	const textShow = (event) => {
		setInputText(event.target.value);
	};

	return (
		<div className="form-control">
			<input
				className="gr-input"
				type="text"
				placeholder={props.placeholder}
				value={inputText}
				onChange={textShow}
				onKeyPress={enterKeyPress}
				style={{ borderRadius: '8vh' }}
			/>
			<div className="mic-icon-container">
				<FontAwesomeIcon icon={faMicrophone} />
			</div>
		</div>
	);
};

export default MessageTextInput;
