/** @format */

import React, { useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const TextEditor = () => {
	const editorRef = useRef(null);
	const toolbarRef = useRef(null);

	useLayoutEffect(() => {
		if (editorRef.current && toolbarRef.current) {
			const quill = new Quill(editorRef.current, {
				theme: 'snow',
				modules: {
					toolbar: toolbarRef.current,
				},
			});

			quill.on('text-change', () => {});
		}
	});

	return (
		<div className="text-editor">
			<div
				ref={toolbarRef}
				className="toolbar"
			>
				<button className="ql-bold">Bold</button>
				<button className="ql-italic">Italic</button>
				<button className="ql-underline">Underline</button>
				<button className="ql-align">
					<select>
						<option></option>
						<option value="center">Center</option>
						<option value="right">Right</option>
						<option value="justify">Justify</option>
					</select>
				</button>
				<button
					className="ql-list"
					value="ordered"
				></button>
				<button
					className="ql-list"
					value="bullet"
				></button>
				<button className="ql-link"></button>
				<button className="ql-image"></button>
			</div>
			<div
				ref={editorRef}
				className="editor-textarea"
			/>
		</div>
	);
};

export default TextEditor;
