/** @format */

import React, { useState, useEffect } from 'react';
import Avatar from '../../global/Avatar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
//images
import Increase from '../../../assets/images/icons/Increase.svg';

const SelectStudentCard = (props) => {
	const [cardSelected, setCardSelected] = useState(false);
	const handleCardClick = () => {
		setCardSelected(!cardSelected);
	};

	return (
		<>
			<div
				className={`select-student-card ${props.bgColor} ${
					cardSelected ? 'card-selected' : ''
				}`}
				onClick={handleCardClick}
			>
				<Avatar
					outline="white"
					size="default"
					user={props.user}
				/>
				<div className="name-container">
					<p className="name">{`${props.user.firstName} ${props.user.lastName}`}</p>
				</div>

				<p className="eng-level">{props.user.englishLevel}</p>

				<p className="invite">{cardSelected ? 'Invited' : 'Available'}</p>

				<div className="average-level">
					<span>[Above Level]</span>
					<img
						src={Increase}
						alt="increase"
					/>
				</div>
			</div>
		</>
	);
};

export default SelectStudentCard;
