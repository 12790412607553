import React from "react";
import PageWrapper from "./global/PageWrapper";
import { useSelector } from "react-redux";
import UserCard from "./profile/UserCard";
import PersonCard from "./global/PersonCard";
import FilteredList from "./students/FilteredList";
import ContentToggle from "./students/ContentToggle";
import Card from "../components/achieve_archive/global/cards/Card";
import DirectoryHeader from "./students/DirectoryHeader";

const userType = {
  YOUTH: "GRP-YTS",
  TEEN: "GRP-TNS",
  ADULTS: "GRP-ADS",
  ADMIN: "GRP-ADM",
  INSTRUCTORS: "GRP-INS",
};
const languageList = [
  "German",
  "Mandarin",
  "Portuguese",
  "Spanish",
  "Turkish",
  "Ukrainian",
];
const teacherList = ["Marcia de Silva", "Steven Peters", "Ximena Flora"];

const countryList = [
  "Brazil",
  "China",
  "Germany",
  "Spain",
  "Turkey",
  "Ukraine",
  "Venezuela",
];

const allLists = [languageList, teacherList, countryList];

const Teachers = (props) => {
  const users = useSelector((state) => state.users);
  const courses = useSelector((state) => state.courses);
  // console.log("Users:", users);
  // console.log("Courses:", courses);
  const searchGroup = userType.INSTRUCTORS;

  const displayPeople = (groupId) => {
    const filteredUsers = users.filter((user) => user.ageGroupId === groupId);
    return filteredUsers.map((user, index) => {
      return <PersonCard variant={"dark"} user={user} newWidth={"directory-card-width"}/>;
    });
  };
  return (
    <PageWrapper page="students">
      <DirectoryHeader
        title="Teacher directory"
        btnName="teacher"
      />
      <div className="student-two-column-grid">
        <div className="student-filter-grid">
          {/* First Card */}
          <div className="student-filter-gap">
            <Card
            >
              <ContentToggle
                sectionName={""}
                header={"Native language"}
                showContent={true}
              >
                <FilteredList 
                checkLists={allLists[0]} 
                />
                <div className="student-filteredlist-link">
                  <a href="#">More</a>
                </div>
              </ContentToggle>
            </Card>
          </div>

          {/* Second Card */}
          <div className="student-filter-gap">
            <Card>
              <ContentToggle
                sectionName={""}
                header={"Teacher"}
                showContent={true}
              >
                <FilteredList checkLists={allLists[1]} />
                <div className="student-filteredlist-link">
                  <a href="#">More</a>
                </div>
              </ContentToggle>
            </Card>
          </div>
          {/* Third Card */}
          <div className="student-filter-gap">
            <Card>
              <ContentToggle
                sectionName={""}
                header={"Country of residence"}
                showContent={true}
              >
                <FilteredList checkLists={allLists[2]} />
                <div className="student-filteredlist-link">
                  <a href="#">More</a>
                </div>
              </ContentToggle>
            </Card>
          </div>
        </div>
        <div className="students-grid student-grid-width">{displayPeople(searchGroup)}</div>
      </div>
    </PageWrapper>
  );
};

export default Teachers;
