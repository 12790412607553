import React from "react";
import ProgressRing from "../infographics/ProgressRing";
import Tag from "../global/Tag";

const SkillCard = (props) => {

    return (
        <div className="card rounded">
            <h3>{props.data.title}</h3>
            <div className="card-row">
                <ProgressRing size="64" stroke="8" value={props.data.progress} icon={props.data.title.toLowerCase()}/>
                <ul className="chip-list">
                    {props.data.skills.map((skill, index) => {
                        return <li key={"skill" + index}><Tag title={skill}></Tag></li>
                    })}
                    
                </ul>
            </div>
           
        </div>
    )
}
export default SkillCard