import React from "react";
import Icon from "../global/Icon";

const ToDoList = () => {

    return (
        <section className="instructor-todo">
            <div className="section-header">
                <h3 className="section-title">To-do list</h3>
            </div>
            <div className="section-body">

                <div className="todo-list">
                    <div className="todo-list-header">
                        <button className="icon-button">
                            <Icon icon="edit" size="18" />
                        </button>
                    </div>
                    <div className="todo-list-body">
                        <h4>Must finish by EOD!</h4>
                        <ul>
                            <li>
                                <div className="todo-checkbox">
                                    <label><span>Check on lessons for the day</span> <input type={"checkbox"}/></label>
                                </div>
                            </li>
                            <li>
                                <div className="todo-checkbox">
                                    <label><span>Finish marking mid-course writing assignments</span> <input type={"checkbox"}/></label>
                                </div>
                            </li>
                            <li>
                                <div className="todo-checkbox">
                                    <label><span>Prepare for open evening with parents</span> <input type={"checkbox"}/></label>
                                </div>
                            </li>
                            <li>
                                <div className="todo-checkbox">
                                    <label><span>Review results of mid-course tests</span> <input type={"checkbox"}/></label>
                                </div>
                            </li>
                            <li>
                                <div className="todo-checkbox">
                                    <label><span>Complete online course on teaching digital literacy</span> <input type={"checkbox"}/></label>
                                </div>
                            </li>
                            <li>
                                <div className="todo-checkbox">
                                    <label><span>Read module 5 (teaching strategies course)</span> <input type={"checkbox"}/></label>
                                </div>
                            </li>
                        </ul>
                    </div>
              
            </div>
            </div>
        </section>
        
    )
}
export default ToDoList