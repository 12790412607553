import React from "react";

const DevelopmentProgress = (props) => {
    const radius = 0.5 * (props.size - props.stroke);
    const circumference = radius * 2 * Math.PI;

    const ring = circumference * props.value / 100;
    const random = props.id ? props.id : Math.floor(Math.random() * 10);



    return (
        <div className="progress-ring development" style={{width: props.size + "px", height: props.size+"px"}} role="img" aria-label={props.ariaLabel}>
        <svg
            height={props.size}
            width={props.size}
        >
            <circle
            stroke={"white"}
            strokeWidth={props.stroke}
            fill="transparent"
            r={radius}
            cx={props.size * 0.5}
                cy={props.size * 0.5}
            />
            <circle
                stroke={"#0CC0AD"}
                strokeWidth={props.stroke}
                fill="transparent"
                r={radius}
                cx={props.size * 0.5}
                cy={props.size * 0.5}
                strokeLinecap="round"
                strokeDasharray={`${ring} ${circumference - ring}`} strokeDashoffset={0.25 * circumference}>

            </circle>
        </svg>
        <span className={`ring-icon percentage` }>
           <span className="percent">{props.value}%</span>
            <span>Complete</span>
        </span>
    </div>

    )
}
export default DevelopmentProgress
