import React, { useState } from "react";
import PageWrapper from "./global/PageWrapper";
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from "./global/Breadcrumbs";
import Icon from "./global/Icon";
import PersonDetailHeader from "./global/PersonDetailHeader";
import AvailabilityTable from "./global/AvailabilityTable";
import TestResults from "./student_details/TestResults";
//import PALRecommendation from "./global/PALRecommendation";

//images 
import phone from "../assets/images/icons/phone.svg"
import email from "../assets/images/icons/email-teal.svg"
import location from "../assets/images/icons/location-teal.svg"
import pal from "../assets/images/brand/pal.svg"
import LearningPlan from "./student_details/LearningPlan";

const StudentDetail = (props) => {
    const params = useParams();
    const { studentId } = params;
    const users = useSelector((state) => state.users);
    const [placementTest, setPlacementTest] = useState(false);

    const student = useSelector((state) =>
        users.find((record) => record.id.toLowerCase() === studentId.toLowerCase())
        );
        const breadcrumbs = [
            {
                page:"Student directory",
                target: "/students"
            },
            {
                page: `${student.firstName} ${student.lastName}`,
                target: ''
            }
        ]
        const breadcrumbsLP = [
            {
                page:"Student directory",
                target: "/students"
            },
            {
                page: `${student.firstName} ${student.lastName}`,
                target: `/student/${studentId}`
            },
            {
                page: `Learning Plan`,
                target: ''
            }
        ]

        const PAL = {
            title: `A personalized learning plan for ${student.firstName}!`, 
            subtitle: `With this plan, ${student.firstName} can reach a <strong>B2</strong> level of English in <strong>18 months</strong> and then take the <strong>Pearson English International Certificate</strong> test.`
        }
       
    return (
        <PageWrapper>
            <div className="person-details student-details">
            <Breadcrumbs data={props.plan ? breadcrumbsLP : breadcrumbs}/>
            <PersonDetailHeader type="student" user={student} plan={props.plan} test={placementTest} click={() => setPlacementTest(true)}/>
            {props.plan ? <section className="person-detail-body">
                {/* <h2>Placement test results</h2> */}
                <TestResults/>
                <div className="pal-rec-container">
                    <div className="pal-badge">
                        <img src={pal}/>
                        <span><span>PAL</span> Your virtual assistant</span>
                    </div>
                    <div className="pal-rec-body">
                        <div>
                            <h3>{PAL.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: PAL.subtitle}}/>
                        </div>
                    </div>
                </div>
                <LearningPlan/>
                <div className="learning-plan-actions button-row">
                    <button className="button secondary">Customize</button>
                    <button className="button primary">Confirm learning plan and send</button>
                </div>
            </section>

            : 
            <section className="person-detail-body">
                <h2>Student information</h2>
                <div className="card-row">
                    <div className="card-column">
                        <div className="card">
                            <div className="card-header"><h3>Contact</h3></div>
                            <div className="card-body">
                                <p className="contact"><span className="icon"><img src={email} alt=""/></span>Email: <span className="teal">{student.email}</span></p>
                                <p className="contact"><span className="icon"><img src={phone} alt=""/></span>Phone: <span className="teal"> +{student.countryCode} ({student.areaCode}) {student.phone}</span></p>
                                <div className="contact">
                                    <span className="icon"><img src={location} alt=""/></span>
                                    <p className="address">
                                        <span>{student.address}</span>
                                        <span>{student.city}, {student.country}</span>
                                        <span>{student.zipCode}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* add logic to show if test results exist */}
                        {placementTest && 
                        <div className="card fadeIn">
                            <div className="card-header"><h3>Placement test results</h3></div>
                            <div className="card-body">
                                <Link className="button secondary" to="./learningplan">Go to learning plan</Link>
                            </div>
                        </div> }
                    </div>
                    <div className="card-column">
                        <div className="card">
                            <div className="card-header"><h3>Languages</h3></div>
                            <div className="card-body">
                                <p>Native language: <span className="teal">{student.nativeLanguage}</span></p>
                                <p>English level: <span className="teal">{student.englishLevel ? student.englishLevel : "Unknown"}</span></p>
                                <p>English learning goal: <span className="teal">{student.learningGoal ? student.learningGoal : "Unknown"}</span></p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header"><h3>Availability</h3></div>
                            <div className="card-body">
                                <AvailabilityTable/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
            </div>
        </PageWrapper>
    )
}

export default StudentDetail
