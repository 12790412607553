import React from "react";
import Avatar from "../global/Avatar";
import { useSelector} from "react-redux";
import Icon from "../global/Icon";

//images
import dataVis from "../../assets/images/data-vis-placeholder.svg"
import more from "../../assets/images/icons/more.svg"

const StudentsTable = (props) => {

    // console.log(props.course)

    const users = useSelector((state) => state.users);

    let ageGroup = props.course.ageGroup.toUpperCase().replace(/\s/g, '');

    const getRows = () => {
        //const filteredUsers = users.filter((user) => user.ageGroupId === userType[ageGroup] && user.cohort.includes(props.course.id));
        const filteredUsers = users.filter((user) => user.ageGroupId !==  'GRP-INS' && user.cohort.includes(props.course.id));
        // console.log(filteredUsers)
        return filteredUsers.map((user, index)=>{
            return(
                <tr key={index}>
                    <td>
                        <div className="student">
                            <Avatar key={index} outline='white' size='small' user={user}/>
                            <p className="bold" >{user.firstName} {user.lastName}</p>
                        </div>
                    </td>
                    <td>
                        <div className="pie-chart-td">
                            <div class="pie-chart" style={{"--value": `${user.attendance}`, "--percentage": `${user.attendance}%`}}>
                                <div class="divider"></div>
                                <div class="divider"></div>
                            </div>
                            <span>{user.attendance}%</span>
                        </div>
                    </td>
                    <td>
                        <div className="pie-chart-td">
                            <div class="pie-chart" style={{"--value": `${user.coursePerformance}`, "--percentage": `${user.coursePerformance}%`}}>
                                <div class="divider"></div>
                                <div class="divider"></div>
                            </div>
                            <span>{user.coursePerformance}%</span>
                        </div>
                    </td>
                    <td>
                        <div className="performance">
                            <Icon icon="trending-up" size="18"/>
                            <p className="bold" >Above level</p>
                            <img className="more" src={more}/>
                        </div>
                    </td>
                </tr>
                )
        })
    }

    return (
       <div className="students-table">
        <h3>Students</h3>
        <table>
            <thead>
                <tr>
                    <th>Student</th>
                    <th>Attendance %</th>
                    <th>Avg. course <br/> performance %</th>
                    <th>Course trajectory</th>
                </tr>
            </thead>
            <tbody>
                {getRows()}
            </tbody>
            </table>
       </div>
    )
}

export default StudentsTable