import React from "react";
import PageWrapper from "./global/PageWrapper";
import Icon from "./global/Icon";
import RecommendationsList from "./instructor_home/RecommendationsList";
import DevelopmentDonut from "./instructor_development/DevelopmentDonut";
import LearningJourney from "./infographics/LearningJourney";

const MyDevelopment = (props) => {

    return (
        <PageWrapper>
            <div className="my-development home">
                <div className="development-header hero">
                    <h2>My Development</h2>
                    <div className="form-input course-dropdown">
                        <label htmlFor="courseName">Course:</label>
                        <div className='gr-select-container'>
                            <select
                                className='gr-select'
                                defaultValue={0}
                                id={"courseName"}
                            >
                                <option value='0'>Teaching skills</option>
                                <option value='1'>Course 2</option>
                                <option value='2'>Course 3</option>
                            </select>
                            <Icon
                                icon='drop-down'
                                size='18'
                            />
                        </div>
                    </div>
                </div>

                <section className="development-completion">
                    <div className="section-header">
                        <h3 className="section-title">You've completed <span className="teal">56%</span> of this course!</h3>
                        <p>Below is a break down of your study hours for <strong className="course-name">Teaching skills</strong>.</p>
                    </div>
                    <div className="section-body">
                        <div className="card-row">
                            <div className="card rounded development-completion">
                                <div className="card-header">
                                    <h4 className="teal">Leadership</h4>
                                    <button className="icon-btn">
                                        <Icon icon="time" size="24"/>
                                    </button>
                                </div>
                                <div className="card-body">
                                    <DevelopmentDonut title="Leadership" remaining={"00"}/>
                                </div>

                            </div>
                            <div className="card rounded development-completion">
                                <div className="card-header">
                                    <h4 className="teal">Communication</h4>
                                    <button className="icon-btn">
                                        <Icon icon="time" size="24"/>
                                    </button>
                                </div>
                                <div className="card-body">
                                    <DevelopmentDonut title="Communication" remaining={"00"}/>
                                </div>

                            </div>
                            <div className="card rounded development-completion">
                                <div className="card-header">
                                    <h4 className="teal">Conflict resolution</h4>
                                    <button className="icon-btn">
                                        <Icon icon="time" size="24"/>
                                    </button>
                                </div>
                                <div className="card-body">
                                    <DevelopmentDonut title="Conflict resolution" remaining={"00"}/>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                </section>
                <section className="development-learning-journey">
                    <div className="section-header">
                        <h3 className="section-title">Learning Journey</h3>
                        <p>You have new highlights in your learning journey!</p>
                    </div>
                    <div className="section-body">
                        <LearningJourney/>
                    </div>
                </section>
               <RecommendationsList development={"Teaching skills"}/>
            </div>
            
        </PageWrapper>
    )
}

export default MyDevelopment