import {configureStore} from '@reduxjs/toolkit';

import coursesReducer from './coursesSlice';
import groupsReducer from './groupsSlice';
import semestersReducer from './semestersSlice';
import skillsReducer  from './skillsSlice';
import usersReducer from './usersSlice';
import authReducer from './authSlice'
import sessionReducer from './sessionSlice';

const store = configureStore({
    reducer: {
        auth : authReducer,
       courses: coursesReducer,
       groups: groupsReducer,
       semesters: semestersReducer,
       skills: skillsReducer,
       users: usersReducer,
       session: sessionReducer,
    }
});
export default store;
