import React from "react";
import PersonCard from "../global/PersonCard";
import { useSelector } from "react-redux";

const StudentList = (props) => {
  // Sample data
  // const users = useSelector((state) => state.users)
  // let studentArr = users.slice(-6);

  return (
    <section className="students-list">
      <div className="section-header">
        <h3>Students</h3>
      </div>
      <div className="card-row student-row overflow-scroll">
        {props.data.length > 0 ? (
          props.data.map((user, index) => {
            console.log("User:", user);
            if (user.ageGroupId !== "GRP-INS") {
              return <PersonCard user={user} key={"user" + index} />;
            }
          })
        ) : (
          <p>No students in this lesson.</p>
        )}
      </div>
    </section>
  );
};

export default StudentList;
