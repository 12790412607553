import React from "react";
import Icon from "../global/Icon";

const TestResults = (props) => {
    const sampleTestResults = {
        score: "CEFR: B1 • GSE: 43-50",
        date: "01/06/2023",
        listening: "At",
        reading: "Above",
        speaking: "Below",
        writing: "At"
    }
    const results = sampleTestResults //for now

    const renderIcon = (type) => {
        if (type.toLowerCase() === "above") {
            return <span className="trend-up"><Icon icon="trending-up" size="18"/></span>
        } else if (type.toLowerCase() === "below") {
            return <span className="trend-down"><Icon icon="trending-down" size="18"/></span>
        } else {
            return <span className="trend-none"><Icon icon="trending-none" size="18"/></span>
        }
    }

    return (
        <div className="card placement-results">
            <div className="card-header">
                <h3>Placement test results</h3>
                <button className="link-button icon-left">
                    <Icon icon="download" size="18"/> 
                    <span>Download score report</span>
                </button>
            </div>
            <div className="card-body">
                <div className="test-details">
                    <p>Overall score: <span className="teal">{results.score}</span> </p>
                    <p>Date: <span className="teal">{results.date}</span></p>
                </div>
                <div className="test-categories">
                    <p>Listening: <span className="teal">{results.listening} level</span> {renderIcon(results.listening)}</p>
                    <p>Reading: <span className="teal">{results.reading} level</span> {renderIcon(results.reading)}</p>
                    <p>Speaking: <span className="teal">{results.speaking} level</span> {renderIcon(results.speaking)}</p>
                    <p>Writing: <span className="teal">{results.writing} level</span> {renderIcon(results.writing)}</p>
                </div>
            </div>
        </div>
    )
}
export default TestResults