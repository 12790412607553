const users = [
 {
   "id": "STU-000",
   "email": "rico.almeida.06@gmail.com",
   "firstName": "Rico",
   "lastName": "Almeida",
   "avatar": "https://assets.pearsonct.design/avatar/63d6e420807516d2739002ee/Rico.png",
   "gender": "male",
   "age": 17,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "B1",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 11,
   "phone": "3638-3836",
   "address": "Rua José Rodrigues Vale 1415",
   "city": "Nova Iguaçu",
   "region": "Rio de Janeiro (RJ) ",
   "country": "Brazil",
   "zipCode": "26270-350",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{"id": "SK-WRT", "percent": 65}, {"id": "SK-LSN", "percent": 80}, {"id": "SK-SPK", "percent": 44}, {"id": "SK-RED", "percent": 60}]},
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "01/06/2023", "skills": [{"id": "SK-WRT", "score": 50, "message": "At level"}, {"id": "SK-LSN", "score": 50, "message": "At level"}, {"id": "SK-SPK", "score": 17.4, "message": "Below level"}, {"id": "SK-RED",  "score": 56, "message": "Above level"}]},
   "weeklyPoints": 1028,
   "articlesRead": 13,
   "totalArticles": 30,
   "studyContacts": 4,
   "totalStudyContacts": 10,
   "status":"online", 
   "attendance": 78,
   "coursePerformance": 78
 },
 {
   "id": "STU-001",
   "email": "kofi.mitchell@gmail.com",
   "firstName": "Kofi",
   "lastName": "Mitchell",
   "avatar": "https://randomuser.me/api/portraits/men/91.jpg",
   "gender": "male",
   "age": 16,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 3,
   "phone": "234-5678",
   "address": "1234 Calle de la Rosa",
   "city": "Madrid",
   "region": "Community of Madrid",
   "country": "Spain",
   "zipCode": "12345",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 72 }, { "id": "SK-LSN", "percent": 58 }, { "id": "SK-SPK", "percent": 83 }, { "id": "SK-RED", "percent": 68 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "12/15/2022", "skills": [{ "id": "SK-WRT", "score": 30, "message": "Below level" }, { "id": "SK-LSN", "score": 45, "message": "At level" }, { "id": "SK-SPK", "score": 55, "message": "Above level" }, { "id": "SK-RED", "score": 48, "message": "At level" }] },
   "weeklyPoints": 2437,
   "articlesRead": 22,
   "totalArticles": 30,
   "studyContacts": 3,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 54,
   "coursePerformance": 60
 },
 {
   "id": "STU-002",
   "email": "lucia.silva@gmail.com",
   "firstName": "Lucia",
   "lastName": "Silva",
   "avatar": "https://randomuser.me/api/portraits/women/50.jpg",
   "gender": "female",
   "age": 15,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "9876-5432",
   "address": "Calle Principal 123",
   "city": "Madrid",
   "region": "Madrid",
   "country": "Spain",
   "zipCode": "28001",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 76 }, { "id": "SK-LSN", "percent": 92 }, { "id": "SK-SPK", "percent": 54 }, { "id": "SK-RED", "percent": 73 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "12/28/2022", "skills": [{ "id": "SK-WRT", "score": 50, "message": "At level" }, { "id": "SK-LSN", "score": 35, "message": "Below level" }, { "id": "SK-SPK", "score": 40, "message": "At level" }, { "id": "SK-RED", "score": 52, "message": "Above level" }] },
   "weeklyPoints": 1426,
   "articlesRead": 14,
   "totalArticles": 30,
   "studyContacts": 1,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 57,
   "coursePerformance": 92
 },
 {
   "id": "STU-003",
   "email": "mateo.rodriguez@gmail.com",
   "firstName": "Mateo",
   "lastName": "Rodriguez",
   "avatar": "https://randomuser.me/api/portraits/men/84.jpg",
   "gender": "male",
   "age": 19,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "8765-4321",
   "address": "Calle Secundaria 456",
   "city": "Madrid",
   "region": "Madrid",
   "country": "Spain",
   "zipCode": "28002",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 42 }, { "id": "SK-LSN", "percent": 79 }, { "id": "SK-SPK", "percent": 62 }, { "id": "SK-RED", "percent": 80 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "12/22/2022", "skills": [{ "id": "SK-WRT", "score": 53, "message": "Above level" }, { "id": "SK-LSN", "score": 42, "message": "At level" }, { "id": "SK-SPK", "score": 25, "message": "Below level" }, { "id": "SK-RED", "score": 47, "message": "At level" }] },
   "weeklyPoints": 1789,
   "articlesRead": 17,
   "totalArticles": 30,
   "studyContacts": 2,
   "totalStudyContacts": 10,
   "status":"online",
   "attendance": 63,
   "coursePerformance": 62
 },
 {
   "id": "STU-004",
   "email": "carla.fuentes@gmail.com",
   "firstName": "Carla",
   "lastName": "Fuentes",
   "avatar": "https://randomuser.me/api/portraits/women/71.jpg",
   "gender": "female",
   "age": 18,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "7654-3210",
   "address": "Calle Principal 789",
   "city": "Seville",
   "region": "Andalusia",
   "country": "Spain",
   "zipCode": "41001",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 89 }, { "id": "SK-LSN", "percent": 47 }, { "id": "SK-SPK", "percent": 75 }, { "id": "SK-RED", "percent": 53 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "01/02/2023", "skills": [{ "id": "SK-WRT", "score": 48, "message": "At level" }, { "id": "SK-LSN", "score": 57, "message": "Above level" }, { "id": "SK-SPK", "score": 33, "message": "Below level" }, { "id": "SK-RED", "score": 44, "message": "At level" }] },
   "weeklyPoints": 2185,
   "articlesRead": 19,
   "totalArticles": 30,
   "studyContacts": 4,
   "totalStudyContacts": 10,
   "status":"online",
   "attendance": 51,
   "coursePerformance": 74
 },
 {
   "id": "STU-005",
   "email": "juan.rodriguez@gmail.com",
   "firstName": "Juan",
   "lastName": "Rodriguez",
   "avatar": "https://randomuser.me/api/portraits/men/49.jpg",
   "gender": "male",
   "age": 18,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "6543-2109",
   "address": "Calle Secundaria 012",
   "city": "Seville",
   "region": "Andalusia",
   "country": "Spain",
   "zipCode": "41002",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 61 }, { "id": "SK-LSN", "percent": 82 }, { "id": "SK-SPK", "percent": 93 }, { "id": "SK-RED", "percent": 70 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "01/18/2023", "skills": [{ "id": "SK-WRT", "score": 39, "message": "Below level" }, { "id": "SK-LSN", "score": 49, "message": "At level" }, { "id": "SK-SPK", "score": 50, "message": "At level" }, { "id": "SK-RED", "score": 56, "message": "Above level" }] },
   "weeklyPoints": 2856,
   "articlesRead": 26,
   "totalArticles": 30,
   "studyContacts": 0,
   "totalStudyContacts": 10,
   "status":"busy",
   "attendance": 80,
   "coursePerformance": 67
 },
 {
   "id": "STU-006",
   "email": "gabriela.oliver@gmail.com",
   "firstName": "Gabriela",
   "lastName": "Oliver",
   "avatar": "https://randomuser.me/api/portraits/women/31.jpg",
   "gender": "female",
   "age": 16,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "5432-1098",
   "address": "Calle Principal 345",
   "city": "Madrid",
   "region": "Madrid",
   "country": "Spain",
   "zipCode": "28003",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 74 }, { "id": "SK-LSN", "percent": 57 }, { "id": "SK-SPK", "percent": 88 }, { "id": "SK-RED", "percent": 79 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "01/12/2023", "skills": [{ "id": "SK-WRT", "score": 42, "message": "At level" }, { "id": "SK-LSN", "score": 31, "message": "Below level" }, { "id": "SK-SPK", "score": 52, "message": "Above level" }, { "id": "SK-RED", "score": 46, "message": "At level" }] },
   "weeklyPoints": 1472,
   "articlesRead": 8,
   "totalArticles": 30,
   "studyContacts": 5,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 95,
   "coursePerformance": 74
 },
 {
   "id": "STU-007",
   "email": "pedro.santos@gmail.com",
   "firstName": "Pedro",
   "lastName": "Santos",
   "avatar": "https://randomuser.me/api/portraits/men/59.jpg",
   "gender": "male",
   "age": 17,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 11,
   "phone": "4321-0987",
   "address": "Avenida Central 678",
   "city": "Sao Paulo",
   "region": "Sao Paulo",
   "country": "Brazil",
   "zipCode": "4566",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 66 }, { "id": "SK-LSN", "percent": 78 }, { "id": "SK-SPK", "percent": 71 }, { "id": "SK-RED", "percent": 62 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "02/05/2023", "skills": [{ "id": "SK-WRT", "score": 36, "message": "Below level" }, { "id": "SK-LSN", "score": 51, "message": "Above level" }, { "id": "SK-SPK", "score": 38, "message": "At level" }, { "id": "SK-RED", "score": 43, "message": "At level" }] },
   "weeklyPoints": 3169,
   "articlesRead": 27,
   "totalArticles": 30,
   "studyContacts": 2,
   "totalStudyContacts": 10,
   "status":"online",
   "attendance": 64,
   "coursePerformance": 60
 },
 {
   "id": "STU-008",
   "email": "sofia.duarte@gmail.com",
   "firstName": "Sofia",
   "lastName": "Duarte",
   "avatar": "https://randomuser.me/api/portraits/women/93.jpg",
   "gender": "female",
   "age": 12,
   "ageGroupId": "GRP-TNS",
   "occupation": "student",
   "cohort": ["ENG-TNS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 11,
   "phone": "3210-9876",
   "address": "Avenida Principal 901",
   "city": "Sao Paulo",
   "region": "Sao Paulo",
   "country": "Brazil",
   "zipCode": "4567",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-TNS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 55 }, { "id": "SK-LSN", "percent": 67 }, { "id": "SK-SPK", "percent": 76 }, { "id": "SK-RED", "percent": 63 }] },
   "cefrTest": {"id": "ENG-TNS-B1-000", "name": "General English", "testDate": "02/20/2023", "skills": [{ "id": "SK-WRT", "score": 54, "message": "Above level" }, { "id": "SK-LSN", "score": 47, "message": "At level" }, { "id": "SK-SPK", "score": 29, "message": "Below level" }, { "id": "SK-RED", "score": 40, "message": "At level" }] },
   "weeklyPoints": 678,
   "articlesRead": 6,
   "totalArticles": 30,
   "studyContacts": 0,
   "totalStudyContacts": 10,
   "status":"busy",
   "attendance": 98,
   "coursePerformance": 70
 },
/***/
{
  "id": "STU-025",
  "email": "maria.gonzalez@gmail.com",
  "firstName": "María",
  "lastName": "González",
  "avatar": "https://randomuser.me/api/portraits/women/55.jpg",
  "gender": "female",
  "age": 15,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-000"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 8,
  "phone": "9876-5432",
  "address": "Calle Gran Vía 123",
  "city": "Madrid",
  "region": "Madrid",
  "country": "Spain",
  "zipCode": "28001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "skills": [
      {"id": "SK-WRT", "percent": 76},
      {"id": "SK-LSN", "percent": 92},
      {"id": "SK-SPK", "percent": 54},
      {"id": "SK-RED", "percent": 73}
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "12/28/2022",
    "skills": [
      {"id": "SK-WRT", "score": 50, "message": "At level"},
      {"id": "SK-LSN", "score": 35, "message": "Below level"},
      {"id": "SK-SPK", "score": 40, "message": "At level"},
      {"id": "SK-RED", "score": 52, "message": "Above level"}
    ]
  },
  "weeklyPoints": 1273,
  "articlesRead": 16,
  "totalArticles": 30,
  "studyContacts": 1,
  "totalStudyContacts": 10,
  "status":"busy",
  "attendance": 86,
   "coursePerformance": 70
},
{
  "id": "STU-026",
  "email": "javier.lopez@gmail.com",
  "firstName": "Javier",
  "lastName": "López",
  "avatar": "https://randomuser.me/api/portraits/men/85.jpg",
  "gender": "male",
  "age": 19,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-000"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 8,
  "phone": "8765-4321",
  "address": "Calle Sagrada Familia 456",
  "city": "Barcelona",
  "region": "Catalonia",
  "country": "Spain",
  "zipCode": "08002",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "skills": [
      {"id": "SK-WRT", "percent": 42},
      {"id": "SK-LSN", "percent": 79},
      {"id": "SK-SPK", "percent": 62},
      {"id": "SK-RED", "percent": 80}
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "12/22/2022",
    "skills": [
      {"id": "SK-WRT", "score": 53, "message": "Above level"},
      {"id": "SK-LSN", "score": 42, "message": "At level"},
      {"id": "SK-SPK", "score": 25, "message": "Below level"},
      {"id": "SK-RED", "score": 47, "message": "At level"}
    ]
  },
  "weeklyPoints": 1485,
  "articlesRead": 17,
  "totalArticles": 30,
  "studyContacts": 2,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 98,
   "coursePerformance": 86
},
{
  "id": "STU-027",
  "email": "laura.sanchez@gmail.com",
  "firstName": "Laura",
  "lastName": "Sánchez",
  "avatar": "https://randomuser.me/api/portraits/women/68.jpg",
  "gender": "female",
  "age": 18,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-000"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 9,
  "phone": "7654-3210",
  "address": "Calle Alhambra 789",
  "city": "Granada",
  "region": "Andalusia",
  "country": "Spain",
  "zipCode": "18001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "skills": [
      {"id": "SK-WRT", "percent": 89},
      {"id": "SK-LSN", "percent": 47},
      {"id": "SK-SPK", "percent": 75},
      {"id": "SK-RED", "percent": 53}
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/02/2023",
    "skills": [
      {"id": "SK-WRT", "score": 48, "message": "At level"},
      {"id": "SK-LSN", "score": 57, "message": "Above level"},
      {"id": "SK-SPK", "score": 33, "message": "Below level"},
      {"id": "SK-RED", "score": 44, "message": "At level"}
    ]
  },
  "weeklyPoints": 1485,
  "articlesRead": 19,
  "totalArticles": 30,
  "studyContacts": 4,
  "totalStudyContacts": 10,
  "status":"offline",
  "attendance": 97,
   "coursePerformance": 62
},
/** */
{
  "id": "STU-028",
  "email": "santiago.fernandez@gmail.com",
  "firstName": "Santiago",
  "lastName": "Fernandez",
  "avatar": "https://randomuser.me/api/portraits/men/62.jpg",
  "gender": "male",
  "age": 18,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-001"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 91,
  "phone": "6543-2109",
  "address": "Calle Principal 456",
  "city": "Barcelona",
  "region": "Catalonia",
  "country": "Spain",
  "zipCode": "08001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-001",
    "name": "General English",
    "skills": [
      {"id": "SK-WRT", "percent": 61},
      {"id": "SK-LSN", "percent": 82},
      {"id": "SK-SPK", "percent": 93},
      {"id": "SK-RED", "percent": 70}
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/18/2023",
    "skills": [
      {"id": "SK-WRT", "score": 39, "message": "Below level"},
      {"id": "SK-LSN", "score": 49, "message": "At level"},
      {"id": "SK-SPK", "score": 50, "message": "At level"},
      {"id": "SK-RED", "score": 56, "message": "Above level"}
    ]
  },
  "weeklyPoints": 2703,
  "articlesRead": 23,
  "totalArticles": 30,
  "studyContacts": 0,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 66,
   "coursePerformance": 60
},
{
  "id": "STU-029",
  "email": "isabella.garcia@gmail.com",
  "firstName": "Isabella",
  "lastName": "Garcia",
  "avatar": "https://randomuser.me/api/portraits/women/54.jpg",
  "gender": "female",
  "age": 16,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-001"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 93,
  "phone": "5432-1098",
  "address": "Calle Secundaria 678",
  "city": "Valencia",
  "region": "Valencia",
  "country": "Spain",
  "zipCode": "46001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-001",
    "name": "General English",
    "skills": [
      {"id": "SK-WRT", "percent": 74},
      {"id": "SK-LSN", "percent": 57},
      {"id": "SK-SPK", "percent": 88},
      {"id": "SK-RED", "percent": 79}
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/12/2023",
    "skills": [
      {"id": "SK-WRT", "score": 42, "message": "At level"},
      {"id": "SK-LSN", "score": 31, "message": "Below level"},
      {"id": "SK-SPK", "score": 52, "message": "Above level"},
      {"id": "SK-RED", "score": 46, "message": "At level"}
    ]
  },
  "weeklyPoints": 1319,
  "articlesRead": 5,
  "totalArticles": 30,
  "studyContacts": 5,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 51,
   "coursePerformance": 96
},
{
  "id": "STU-030",
  "email": "alejandro.morales@gmail.com",
  "firstName": "Alejandro",
  "lastName": "Morales",
  "avatar": "https://randomuser.me/api/portraits/men/69.jpg",
  "gender": "male",
  "age": 17,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-001"],
  "nativeLanguage": "Portuguese",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 52,
  "areaCode": 55,
  "phone": "4321-0987",
  "address": "Calle Principal 789",
  "city": "Mexico City",
  "region": "Mexico City",
  "country": "Mexico",
  "zipCode": "12345",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-001",
    "name": "General English",
    "skills": [
      {"id": "SK-WRT", "percent": 66},
      {"id": "SK-LSN", "percent": 78},
      {"id": "SK-SPK", "percent": 71},
      {"id": "SK-RED", "percent": 62}
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "02/05/2023",
    "skills": [
      {"id": "SK-WRT", "score": 36, "message": "Below level"},
      {"id": "SK-LSN", "score": 51, "message": "Above level"},
      {"id": "SK-SPK", "score": 38, "message": "At level"},
      {"id": "SK-RED", "score": 43, "message": "At level"}
    ]
  },
  "weeklyPoints": 3016,
  "articlesRead": 25,
  "totalArticles": 30,
  "studyContacts": 2,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 82,
   "coursePerformance": 74
},
/** */
{
  "id": "STU-031",
  "email": "alejandro.martinez@gmail.com",
  "firstName": "Alejandro",
  "lastName": "García",
  "avatar": "https://randomuser.me/api/portraits/men/28.jpg",
  "gender": "male",
  "age": 18,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-000"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 9,
  "phone": "7654-3210",
  "address": "Calle Principal 567",
  "city": "Madrid",
  "region": "Madrid",
  "country": "Spain",
  "zipCode": "28004",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "skills": [
      { "id": "SK-WRT", "percent": 72 },
      { "id": "SK-LSN", "percent": 85 },
      { "id": "SK-SPK", "percent": 90 },
      { "id": "SK-RED", "percent": 78 }
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/15/2023",
    "skills": [
      { "id": "SK-WRT", "score": 45, "message": "At level" },
      { "id": "SK-LSN", "score": 41, "message": "At level" },
      { "id": "SK-SPK", "score": 50, "message": "At level" },
      { "id": "SK-RED", "score": 48, "message": "At level" }
    ]
  },
  "weeklyPoints": 2054,
  "articlesRead": 16,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 54,
   "coursePerformance": 58
},
{
  "id": "STU-032",
  "email": "david.hernandez@gmail.com",
  "firstName": "David",
  "lastName": "López",
  "avatar": "https://randomuser.me/api/portraits/men/32.jpg",
  "gender": "male",
  "age": 17,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B1-001"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 9,
  "phone": "8765-4321",
  "address": "Calle Segunda 789",
  "city": "Barcelona",
  "region": "Catalonia",
  "country": "Spain",
  "zipCode": "08002",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B1-001"
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/15/2023",
    "skills": [
      { "id": "SK-WRT", "score": 45, "message": "At level" },
      { "id": "SK-LSN", "score": 41, "message": "At level" },
      { "id": "SK-SPK", "score": 50, "message": "At level" },
      { "id": "SK-RED", "score": 48, "message": "At level" }
    ]
  },
  "weeklyPoints": 2054,
  "articlesRead": 16,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"offline",
  "attendance": 56,
   "coursePerformance": 73
},
/** */
{
  "id": "STU-033",
  "email": "juliana.richards@gmail.com",
  "firstName": "Juliana",
  "lastName": "González",
  "avatar": "https://randomuser.me/api/portraits/women/59.jpg",
  "gender": "female",
  "age": 19,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B2-002"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 9,
  "phone": "9876-5432",
  "address": "Calle Tercera 123",
  "city": "Valencia",
  "region": "Valencia",
  "country": "Spain",
  "zipCode": "46001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B2-002"
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/15/2023",
    "skills": [
      { "id": "SK-WRT", "score": 45, "message": "At level" },
      { "id": "SK-LSN", "score": 41, "message": "At level" },
      { "id": "SK-SPK", "score": 50, "message": "At level" },
      { "id": "SK-RED", "score": 48, "message": "At level" }
    ]
  },
  "weeklyPoints": 2054,
  "articlesRead": 16,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"offline",
  "attendance": 58,
   "coursePerformance": 79
},
{
  "id": "STU-034",
  "email": "manuel.gomez@gmail.com",
  "firstName": "Manuel",
  "lastName": "Serrano",
  "avatar": "https://randomuser.me/api/portraits/men/10.jpg",
  "gender": "male",
  "age": 18,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B2-002"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 9,
  "phone": "2345-6789",
  "address": "Calle Cuarta 456",
  "city": "Seville",
  "region": "Andalusia",
  "country": "Spain",
  "zipCode": "41001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B2-002"
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/15/2023",
    "skills": [
      { "id": "SK-WRT", "score": 45, "message": "At level" },
      { "id": "SK-LSN", "score": 41, "message": "At level" },
      { "id": "SK-SPK", "score": 50, "message": "At level" },
      { "id": "SK-RED", "score": 48, "message": "At level" }
    ]
  },
  "weeklyPoints": 2054,
  "articlesRead": 16,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 66,
   "coursePerformance": 100
},
{
  "id": "STU-035",
  "email": "sofia.rodriguez@gmail.com",
  "firstName": "Sofía",
  "lastName": "Morales",
  "avatar": "https://randomuser.me/api/portraits/women/95.jpg",
  "gender": "female",
  "age": 17,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B2-002"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 9,
  "phone": "6543-2109",
  "address": "Calle Quinta 789",
  "city": "Barcelona",
  "region": "Catalonia",
  "country": "Spain",
  "zipCode": "08003",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B2-002"
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/15/2023",
    "skills": [
      { "id": "SK-WRT", "score": 45, "message": "At level" },
      { "id": "SK-LSN", "score": 41, "message": "At level" },
      { "id": "SK-SPK", "score": 50, "message": "At level" },
      { "id": "SK-RED", "score": 48, "message": "At level" }
    ]
  },
  "weeklyPoints": 2054,
  "articlesRead": 16,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"busy",
  "attendance": 95,
   "coursePerformance": 86
},
{
  "id": "STU-036",
  "email": "andrea.perez@gmail.com",
  "firstName": "Andrea",
  "lastName": "Romero",
  "avatar": "https://randomuser.me/api/portraits/women/15.jpg",
  "gender": "female",
  "age": 19,
  "ageGroupId": "GRP-TNS",
  "occupation": "student",
  "cohort": ["ENG-TNS-B2-002"],
  "nativeLanguage": "Spanish",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 9,
  "phone": "9876-5432",
  "address": "Calle Sexta 123",
  "city": "Valencia",
  "region": "Valencia",
  "country": "Spain",
  "zipCode": "46002",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-B2-002"
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/15/2023",
    "skills": [
      { "id": "SK-WRT", "score": 45, "message": "At level" },
      { "id": "SK-LSN", "score": 41, "message": "At level" },
      { "id": "SK-SPK", "score": 50, "message": "At level" },
      { "id": "SK-RED", "score": 48, "message": "At level" }
    ]
  },
  "weeklyPoints": 2054,
  "articlesRead": 16,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 78,
   "coursePerformance": 71
},
/** */
{
  "id": "STU-037",
  "email": "gustavo.silva@gmail.com",
  "firstName": "Gustavo",
  "lastName": "Silva",
  "avatar": "https://randomuser.me/api/portraits/men/42.jpg",
  "gender": "male",
  "age": 24,
  "ageGroupId": "GRP-ADS",
  "occupation": "student",
  "cohort": ["ENG-ADS-A1-000"],
  "nativeLanguage": "Portuguese",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 91,
  "phone": "987-654-321",
  "address": "Rua do Comércio 123",
  "city": "Lisbon",
  "region": "Lisbon District",
  "country": "Portugal",
  "zipCode": "1000-001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-A1-000",
    "name": "General English",
    "skills": [
      { "id": "SK-WRT", "percent": 65 },
      { "id": "SK-LSN", "percent": 80 },
      { "id": "SK-SPK", "percent": 44 },
      { "id": "SK-RED", "percent": 60 }
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/06/2023",
    "skills": [
      { "id": "SK-WRT", "score": 50, "message": "At level" },
      { "id": "SK-LSN", "score": 50, "message": "At level" },
      { "id": "SK-SPK", "score": 17.4, "message": "Below level" },
      { "id": "SK-RED", "score": 56, "message": "Above level" }
    ]
  },
  "weeklyPoints": 2284 - 153,
  "articlesRead": 25,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"busy",
  "attendance": 63,
   "coursePerformance": 75
},
{
  "id": "STU-038",
  "email": "mariana.rodrigues@gmail.com",
  "firstName": "Mariana",
  "lastName": "Rodrigues",
  "avatar": "https://randomuser.me/api/portraits/women/88.jpg",
  "gender": "female",
  "age": 26,
  "ageGroupId": "GRP-ADS",
  "occupation": "student",
  "cohort": ["ENG-ADS-A1-000"],
  "nativeLanguage": "Portuguese",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 93,
  "phone": "123-456-789",
  "address": "Avenida Principal 456",
  "city": "Porto",
  "region": "Porto District",
  "country": "Portugal",
  "zipCode": "4000-001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-A1-000",
    "name": "General English",
    "skills": [
      { "id": "SK-WRT", "percent": 65 },
      { "id": "SK-LSN", "percent": 80 },
      { "id": "SK-SPK", "percent": 44 },
      { "id": "SK-RED", "percent": 60 }
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/06/2023",
    "skills": [
      { "id": "SK-WRT", "score": 50, "message": "At level" },
      { "id": "SK-LSN", "score": 50, "message": "At level" },
      { "id": "SK-SPK", "score": 17.4, "message": "Below level" },
      { "id": "SK-RED", "score": 56, "message": "Above level" }
    ]
  },
  "weeklyPoints": 2437 - 153,
  "articlesRead": 25,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"busy",
  "attendance": 81,
   "coursePerformance": 51
},
{
  "id": "STU-039",
  "email": "hugo.fernandes@gmail.com",
  "firstName": "Hugo",
  "lastName": "Fernandes",
  "avatar": "https://randomuser.me/api/portraits/men/72.jpg",
  "gender": "male",
  "age": 45,
  "ageGroupId": "GRP-ADS",
  "occupation": "student",
  "cohort": ["ENG-ADS-A1-000"],
  "nativeLanguage": "Portuguese",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 96,
  "phone": "321-654-987",
  "address": "Rua das Flores 789",
  "city": "Faro",
  "region": "Faro District",
  "country": "Portugal",
  "zipCode": "8000-001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-A1-000",
    "name": "General English",
    "skills": [
      { "id": "SK-WRT", "percent": 65 },
      { "id": "SK-LSN", "percent": 80 },
      { "id": "SK-SPK", "percent": 44 },
      { "id": "SK-RED", "percent": 60 }
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/06/2023",
    "skills": [
      { "id": "SK-WRT", "score": 50, "message": "At level" },
      { "id": "SK-LSN", "score": 50, "message": "At level" },
      { "id": "SK-SPK", "score": 17.4, "message": "Below level" },
      { "id": "SK-RED", "score": 56, "message": "Above level" }
    ]
  },
  "weeklyPoints": 2284 - 153,
  "articlesRead": 25,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 94,
   "coursePerformance": 57
},
{
  "id": "STU-040",
  "email": "isabella.santos@gmail.com",
  "firstName": "Isabella",
  "lastName": "Santos",
  "avatar": "https://randomuser.me/api/portraits/women/84.jpg",
  "gender": "female",
  "age": 20,
  "ageGroupId": "GRP-ADS",
  "occupation": "student",
  "cohort": ["ENG-ADS-A1-000"],
  "nativeLanguage": "Portuguese",
  "englishLevel": "Unknown",
  "learningGoal": "Unknown",
  "countryCode": 34,
  "areaCode": 94,
  "phone": "555-888-777",
  "address": "Avenida Central 123",
  "city": "Funchal",
  "region": "Madeira",
  "country": "Portugal",
  "zipCode": "9000-001",
  "organization": "Avalon School of English",
  "enrollments": {
    "id": "ENG-TNS-A1-000",
    "name": "General English",
    "skills": [
      { "id": "SK-WRT", "percent": 65 },
      { "id": "SK-LSN", "percent": 80 },
      { "id": "SK-SPK", "percent": 44 },
      { "id": "SK-RED", "percent": 60 }
    ]
  },
  "cefrTest": {
    "id": "ENG-TNS-B1-000",
    "name": "General English",
    "testDate": "01/06/2023",
    "skills": [
      { "id": "SK-WRT", "score": 50, "message": "At level" },
      { "id": "SK-LSN", "score": 50, "message": "At level" },
      { "id": "SK-SPK", "score": 17.4, "message": "Below level" },
      { "id": "SK-RED", "score": 56, "message": "Above level" }
    ]
  },
  "weeklyPoints": 2437 - 153,
  "articlesRead": 25,
  "totalArticles": 30,
  "studyContacts": 3,
  "totalStudyContacts": 10,
  "status":"online",
  "attendance": 57,
   "coursePerformance": 79
},
/** */
 {
   "id": "STU-009",
   "email": "carmen.martinez@gmail.com",
   "firstName": "Carmen",
   "lastName": "Martinez",
   "avatar": "https://media.istockphoto.com/id/629497028/photo/twelve-years-old-hispanic-schoolgirl.jpg?s=612x612&w=0&k=20&c=iuAkbCPgJs4C8INYt1swEgeFUDZI6VI_42K5VzbHZWw=",
   "gender": "female",
   "age": 10,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "2109-8765",
   "address": "Calle Principal 123",
   "city": "Madrid",
   "region": "Madrid",
   "country": "Spain",
   "zipCode": "28001",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 90 }, { "id": "SK-LSN", "percent": 87 }, { "id": "SK-SPK", "percent": 76 }, { "id": "SK-RED", "percent": 65 }] },
   "cefrTest": {},
   "weeklyPoints": 1250,
   "articlesRead": 11,
   "totalArticles": 30,
   "studyContacts": 3,
   "totalStudyContacts": 10,
   "status":"online",
   "attendance": 74,
   "coursePerformance": 63
 },
 {
   "id": "STU-010",
   "email": "marcelo.santos@gmail.com",
   "firstName": "Marcelo",
   "lastName": "Santos",
   "avatar": "https://media.istockphoto.com/id/1399611777/photo/portrait-of-a-smiling-little-brown-haired-boy-looking-at-the-camera-happy-kid-with-good.jpg?s=612x612&w=0&k=20&c=qZ63xODwrnc81wKK0dwc3tOEf2lghkQQKmotbF11q7Q=",
   "gender": "male",
   "age": 9,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 11,
   "phone": "4321-0987",
   "address": "Avenida Central 456",
   "city": "Sao Paulo",
   "region": "Sao Paulo",
   "country": "Brazil",
   "zipCode": "4566",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 72 }, { "id": "SK-LSN", "percent": 58 }, { "id": "SK-SPK", "percent": 83 }, { "id": "SK-RED", "percent": 68 }] },
   "cefrTest": {},
   "weeklyPoints": 750,
   "articlesRead": 6,
   "totalArticles": 30,
   "studyContacts": 3,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 94,
   "coursePerformance": 95
 },
 {
   "id": "STU-011",
   "email": "ana.rodriguez@gmail.com",
   "firstName": "Ana",
   "lastName": "Rodriguez",
   "avatar": "https://media.istockphoto.com/id/155375561/photo/smiling-little-girl.jpg?s=612x612&w=0&k=20&c=-e1vAil4rAe69gOcK31olBL0a09bL-f7BFpZLvO8A7g=",
   "gender": "female",
   "age": 8,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "6543-2109",
   "address": "Calle Secundaria 789",
   "city": "Seville",
   "region": "Andalusia",
   "country": "Spain",
   "zipCode": "41001",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 86 }, { "id": "SK-LSN", "percent": 67 }, { "id": "SK-SPK", "percent": 76 }, { "id": "SK-RED", "percent": 91 }] },
   "cefrTest": {},
   "weeklyPoints": 375,
   "articlesRead": 3,
   "totalArticles": 30,
   "studyContacts": 1,
   "totalStudyContacts": 10,
   "status":"online",
   "attendance": 65,
   "coursePerformance": 55
 },
 {
   "id": "STU-012",
   "email": "manuel.silva@gmail.com",
   "firstName": "Manuel",
   "lastName": "Silva",
   "avatar": "https://media.istockphoto.com/id/639535614/photo/ten-years-old-little-boy.jpg?s=612x612&w=0&k=20&c=iMSDHHJ4AYCRwPbWF9YzrOc6fhyB7ONsHUtdoPZg0LQ=",
   "gender": "male",
   "age": 11,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 11,
   "phone": "8765-4321",
   "address": "Avenida Principal 012",
   "city": "Sao Paulo",
   "region": "Sao Paulo",
   "country": "Brazil",
   "zipCode": "4567",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 42 }, { "id": "SK-LSN", "percent": 79 }, { "id": "SK-SPK", "percent": 62 }, { "id": "SK-RED", "percent": 80 }] },
   "cefrTest": {},
   "weeklyPoints": 187,
   "articlesRead": 2,
   "totalArticles": 30,
   "studyContacts": 0,
   "totalStudyContacts": 10,
   "status":"busy",
   "attendance": 70,
   "coursePerformance": 77
 },
 {
   "id": "STU-013",
   "email": "isabel.garcia@gmail.com",
   "firstName": "Isabel",
   "lastName": "Garcia",
   "avatar": "https://images.unsplash.com/photo-1498674202614-ac0172c6c61a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDA2fHxoYXBweXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
   "gender": "female",
   "age": 9,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "0987-6543",
   "address": "Calle Principal 345",
   "city": "Madrid",
   "region": "Madrid",
   "country": "Spain",
   "zipCode": "28002",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 90 }, { "id": "SK-LSN", "percent": 87 }, { "id": "SK-SPK", "percent": 76 }, { "id": "SK-RED", "percent": 65 }] },
   "cefrTest": {},
   "weeklyPoints": 1750,
   "articlesRead": 15,
   "totalArticles": 30,
   "studyContacts": 3,
   "totalStudyContacts": 10,
   "status":"busy",
   "attendance": 96,
   "coursePerformance": 52
 },
 {
   "id": "STU-014",
   "email": "gabriel.rodrigues@gmail.com",
   "firstName": "Gabriel",
   "lastName": "Rodrigues",
   "avatar": "https://media.istockphoto.com/id/641805614/photo/little-boy-headshot.jpg?s=612x612&w=0&k=20&c=fHTl6oDDq2N5BSQ3bFt90zoIu3XEEM4GMJsnh-5C8hc=",
   "gender": "male",
   "age": 10,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 11,
   "phone": "2109-8765",
   "address": "Avenida Central 678",
   "city": "Sao Paulo",
   "region": "Sao Paulo",
   "country": "Brazil",
   "zipCode": "4568",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 72 }, { "id": "SK-LSN", "percent": 58 }, { "id": "SK-SPK", "percent": 83 }, { "id": "SK-RED", "percent": 68 }] },
   "cefrTest": {},
   "weeklyPoints": 800,
   "articlesRead": 8,
   "totalArticles": 30,
   "studyContacts": 3,
   "totalStudyContacts": 10,
   "status":"busy",
   "attendance": 75,
   "coursePerformance": 93
 },
 {
   "id": "STU-015",
   "email": "sofia.martinez@gmail.com",
   "firstName": "Sofia",
   "lastName": "Martinez",
   "avatar": "https://media.istockphoto.com/id/996572980/photo/little-girl-looking-at-the-camera.jpg?s=612x612&w=0&k=20&c=arklvC8xYVs4o7p01ynfEHC2_SVi-U5zuQ2SUov96Bk=",
   "gender": "female",
   "age": 12,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "8765-4321",
   "address": "Calle Secundaria 901",
   "city": "Seville",
   "region": "Andalusia",
   "country": "Spain",
   "zipCode": "41002",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 86 }, { "id": "SK-LSN", "percent": 67 }, { "id": "SK-SPK", "percent": 76 }, { "id": "SK-RED", "percent": 91 }] },
   "cefrTest": {},
   "weeklyPoints": 425,
   "articlesRead": 6,
   "totalArticles": 30,
   "studyContacts": 0,
   "totalStudyContacts": 10,
   "status":"busy",
   "attendance":97,
   "coursePerformance": 59
 },
 {
   "id": "STU-016",
   "email": "sofia.rodriguez@gmail.com",
   "firstName": "Sofia",
   "lastName": "Rodriguez",
   "avatar": "https://media.istockphoto.com/id/472230187/photo/happy-hispanic-little-girl.jpg?s=612x612&w=0&k=20&c=JbcegMrpMc02zJnG9s39LRlx9yZuNGqFFYp2XcxGNTQ=",
   "gender": "female",
   "age": 11,
   "ageGroupId": "GRP-YTS",
   "occupation": "student",
   "cohort": ["ENG-YTS-A1-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 9,
   "phone": "6543-2109",
   "address": "Calle Principal 567",
   "city": "Madrid",
   "region": "Madrid",
   "country": "Spain",
   "zipCode": "28003",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-YTS-A1-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 42 }, { "id": "SK-LSN", "percent": 79 }, { "id": "SK-SPK", "percent": 62 }, { "id": "SK-RED", "percent": 80 }] },
   "cefrTest": {},
   "weeklyPoints": 230,
   "articlesRead": 3,
   "totalArticles": 30,
   "studyContacts": 0,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 91,
   "coursePerformance": 100
 },
 {
   "id": "STU-017",
   "email": "olivia.pereira@example.com",
   "firstName": "Olivia",
   "lastName": "Pereira",
   "avatar": "https://randomuser.me/api/portraits/women/82.jpg",
   "gender": "female",
   "age": 45,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 21,
   "phone": "987654321",
   "address": "1234 Maple Street",
   "city": "Rio de Janeiro",
   "region": "Rio de Janeiro",
   "country": "Brazil",
   "zipCode": "12345",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{"id": "SK-WRT", "percent": 65}, {"id": "SK-LSN", "percent": 80}, {"id": "SK-SPK", "percent": 44}, {"id": "SK-RED",  "percent": 60}]},
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "01/06/2023", "skills": [{"id": "SK-WRT", "score": 50, "message": "At level"}, {"id": "SK-LSN", "score": 50, "message": "At level"}, {"id": "SK-SPK", "score": 17.4, "message": "Below level"}, {"id": "SK-RED",  "score": 56, "message": "Above level"}]},
   "weeklyPoints": 2437,
   "articlesRead": 25,
   "totalArticles": 30,
   "studyContacts": 3,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 57,
   "coursePerformance": 77
 },
 {
   "id": "STU-018",
   "email": "nia.jardim@example.com",
   "firstName": "Nia",
   "lastName": "Jardim",
   "avatar": "https://randomuser.me/api/portraits/women/56.jpg",
   "gender": "female",
   "age": 25,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 21,
   "phone": "123456789",
   "address": "5678 Oak Avenue",
   "city": "São Paulo",
   "region": "São Paulo",
   "country": "Brazil",
   "zipCode": "56789",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 72 }, { "id": "SK-LSN", "percent": 58 }, { "id": "SK-SPK", "percent": 83 }, { "id": "SK-RED", "percent": 68 }] },
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "12/15/2022", "skills": [{ "id": "SK-WRT", "score": 30, "message": "Below level" }, { "id": "SK-LSN", "score": 45, "message": "At level" }, { "id": "SK-SPK", "score": 55, "message": "Above level" }, { "id": "SK-RED", "score": 48, "message": "At level" }] },
   "weeklyPoints": 1426,
   "articlesRead": 14,
   "totalArticles": 30,
   "studyContacts": 1,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 81,
   "coursePerformance": 95
 },
 {
   "id": "STU-019",
   "email": "ricardo.gomez@example.com",
   "firstName": "Ricardo",
   "lastName": "Gómez",
   "avatar": "https://randomuser.me/api/portraits/men/46.jpg",
   "gender": "male",
   "age": 38,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 91,
   "phone": "456789012",
   "address": "9101 Elm Street",
   "city": "Barcelona",
   "region": "Catalonia",
   "country": "Spain",
   "zipCode": "91011",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 76 }, { "id": "SK-LSN", "percent": 92 }, { "id": "SK-SPK", "percent": 54 }, { "id": "SK-RED", "percent": 73 }] },
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "12/28/2022", "skills": [{ "id": "SK-WRT", "score": 50, "message": "At level" }, { "id": "SK-LSN", "score": 35, "message": "Below level" }, { "id": "SK-SPK", "score": 40, "message": "At level" }, { "id": "SK-RED", "score": 52, "message": "Above level" }] },
   "weeklyPoints": 1789,
   "articlesRead": 17,
   "totalArticles": 30,
   "studyContacts": 2,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 51,
   "coursePerformance": 61
 },
 {
   "id": "STU-020",
   "email": "maria.lima@example.com",
   "firstName": "Maria",
   "lastName": "Lima",
   "avatar": "https://randomuser.me/api/portraits/women/78.jpg",
   "gender": "female",
   "age": 32,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 91,
   "phone": "765432109",
   "address": "2345 Oak Avenue",
   "city": "Madrid",
   "region": "Community of Madrid",
   "country": "Spain",
   "zipCode": "23456",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 42 }, { "id": "SK-LSN", "percent": 79 }, { "id": "SK-SPK", "percent": 62 }, { "id": "SK-RED", "percent": 80 }] },
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "12/22/2022", "skills": [{ "id": "SK-WRT", "score": 53, "message": "Above level" }, { "id": "SK-LSN", "score": 42, "message": "At level" }, { "id": "SK-SPK", "score": 25, "message": "Below level" }, { "id": "SK-RED", "score": 47, "message": "At level" }] },
   "weeklyPoints": 2185,
   "articlesRead": 11,
   "totalArticles": 30,
   "studyContacts": 4,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 68,
   "coursePerformance": 84
 },
 {
   "id": "STU-021",
   "email": "felipe.santos@example.com",
   "firstName": "Felipe",
   "lastName": "Santos",
   "avatar": "https://randomuser.me/api/portraits/men/7.jpg",
   "gender": "male",
   "age": 27,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 21,
   "phone": "543210987",
   "address": "6789 Maple Street",
   "city": "Rio de Janeiro",
   "region": "Rio de Janeiro",
   "country": "Brazil",
   "zipCode": "67890",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 89 }, { "id": "SK-LSN", "percent": 47 }, { "id": "SK-SPK", "percent": 75 }, { "id": "SK-RED", "percent": 53 }] },
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "01/02/2023", "skills": [{ "id": "SK-WRT", "score": 48, "message": "At level" }, { "id": "SK-LSN", "score": 57, "message": "Above level" }, { "id": "SK-SPK", "score": 33, "message": "Below level" }, { "id": "SK-RED", "score": 44, "message": "At level" }] },
   "weeklyPoints": 2856,
   "articlesRead": 19,
   "totalArticles": 30,
   "studyContacts": 0,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 66,
   "coursePerformance": 61
 },
 {
   "id": "STU-022",
   "email": "isabel.martinez@example.com",
   "firstName": "Isabel",
   "lastName": "Martínez",
   "avatar": "https://randomuser.me/api/portraits/women/28.jpg",
   "gender": "female",
   "age": 29,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Spanish",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 91,
   "phone": "210987654",
   "address": "7890 Elm Street",
   "city": "Barcelona",
   "region": "Catalonia",
   "country": "Spain",
   "zipCode": "78901",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 61 }, { "id": "SK-LSN", "percent": 82 }, { "id": "SK-SPK", "percent": 93 }, { "id": "SK-RED", "percent": 70 }] },
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "01/18/2023", "skills": [{ "id": "SK-WRT", "score": 39, "message": "Below level" }, { "id": "SK-LSN", "score": 49, "message": "At level" }, { "id": "SK-SPK", "score": 50, "message": "At level" }, { "id": "SK-RED", "score": 56, "message": "Above level" }] },
   "weeklyPoints": 1472,
   "articlesRead": 8,
   "totalArticles": 30,
   "studyContacts": 5,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 78,
   "coursePerformance": 60
 },
 {
   "id": "STU-023",
   "email": "antonio.costa@example.com",
   "firstName": "Antonio",
   "lastName": "Costa",
   "avatar": "https://randomuser.me/api/portraits/men/97.jpg",
   "gender": "male",
   "age": 33,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 55,
   "areaCode": 21,
   "phone": "876543210",
   "address": "9012 Oak Avenue",
   "city": "São Paulo",
   "region": "São Paulo",
   "country": "Brazil",
   "zipCode": "90123",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 74 }, { "id": "SK-LSN", "percent": 57 }, { "id": "SK-SPK", "percent": 88 }, { "id": "SK-RED", "percent": 79 }] },
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "01/12/2023", "skills": [{ "id": "SK-WRT", "score": 42, "message": "At level" }, { "id": "SK-LSN", "score": 31, "message": "Below level" }, { "id": "SK-SPK", "score": 52, "message": "Above level" }, { "id": "SK-RED", "score": 46, "message": "At level" }] },
   "weeklyPoints": 3169,
   "articlesRead": 27,
   "totalArticles": 30,
   "studyContacts": 2,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 94,
   "coursePerformance": 98
 },
 {
   "id": "STU-024",
   "email": "sofia.rodrigues@example.com",
   "firstName": "Sofia",
   "lastName": "Rodrigues",
   "avatar": "https://randomuser.me/api/portraits/women/19.jpg",
   "gender": "female",
   "age": 36,
   "ageGroupId": "GRP-ADS",
   "occupation": "student",
   "cohort": ["ENG-ADS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "Unknown",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 91,
   "phone": "432109876",
   "address": "3456 Maple Street",
   "city": "Madrid",
   "region": "Community of Madrid",
   "country": "Spain",
   "zipCode": "34567",
   "organization": "Avalon School of English",
   "enrollments": {"id": "ENG-ADS-B1P-000", "name": "General English", "skills": [{ "id": "SK-WRT", "percent": 66 }, { "id": "SK-LSN", "percent": 78 }, { "id": "SK-SPK", "percent": 71 }, { "id": "SK-RED", "percent": 62 }] },
   "cefrTest": {"id": "ENG-ADS-B1-000", "name": "General English", "testDate": "02/05/2023", "skills": [{ "id": "SK-WRT", "score": 36, "message": "Below level" }, { "id": "SK-LSN", "score": 51, "message": "Above level" }, { "id": "SK-SPK", "score": 38, "message": "At level" }, { "id": "SK-RED", "score": 43, "message": "At level" }] },
   "weeklyPoints": 678,
   "articlesRead": 6,
   "totalArticles": 30,
   "studyContacts": 0,
   "totalStudyContacts": 10,
   "status":"offline",
   "attendance": 82,
   "coursePerformance": 54
 },
 {
   "id": "INS-000",
   "email": "marcia.dasilva@avalon.com",
   "firstName": "Marcia",
   "lastName": "Da Silva",
   "avatar": "https://xsgames.co/randomusers/assets/avatars/female/35.jpg",
   "gender": "female",
   "age": 25,
   "ageGroupId": "GRP-INS",
   "occupation": "instructor",
   "cohort": ["ENG-TNS-B1P-000", "ENG-ADS-A1-000", "ENG-ADS-B1P-001", "ENG-YTS-A1-000", "ENG-TNS-B2-002", "ENG-ADS-B1P-000"],
   "nativeLanguage": "Portuguese",
   "englishLevel": "B2",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 91,
   "phone": "123456789",
   "address": "1234 Main Street",
   "city": "Barcelona",
   "region": "Catalonia",
   "country": "Spain",
   "zipCode": "12345",
   "organization": "Avalon School of English",
   "enrollments": [{"id": "GEN-INS-ADS", "name": "General Instruction", "percent": 56, "skills": [{"id": "SK-INS-LDR", "hours": 32}, {"id": "SK-INS-COM", "hours": 41}, {"id": "SK-INS-CFT", "hours": 28}]}],
   "cefrTest": {},
   "weeklyPoints": null,
   "articlesRead": null,
   "totalArticles": null,
   "studyContacts": null,
   "totalStudyContacts": null,
   "status":"online",
   "attendance": 62,
   "coursePerformance": 78
 },
 {
   "id": "INS-001",
   "email": "steven.peters@avalon.com",
   "firstName": "Steven",
   "lastName": "Peters",
   "avatar": "https://randomuser.me/api/portraits/men/14.jpg",
   "gender": "male",
   "age": 43,
   "ageGroupId": "GRP-INS",
   "occupation": "instructor",
   "cohort": ["ENG-ADS-A1-001", "ENG-ADS-A2-000", "ENG-ADS-A2P-000", "ENG-ADS-B1-000", "ENG-TNS-B1-000"],
   "nativeLanguage": "Chinese",
   "englishLevel": "C1",
   "learningGoal": "Unknown",
   "countryCode": 34,
   "areaCode": 91,
   "phone": "987654321",
   "address": "5678 Elm Street",
   "city": "Madrid",
   "region": "Community of Madrid",
   "country": "Spain",
   "zipCode": "56789",
   "organization": "Avalon School of English",
   "enrollments": [{"id": "GEN-INS-ADS", "name": "General Instruction", "percent": 75, "skills": [{"id": "SK-INS-LDR", "hours": 45}, {"id": "SK-INS-COM", "hours": 40}, {"id": "SK-INS-CFT", "hours": 30}]}],
   "cefrTest": {},
   "weeklyPoints": null,
   "articlesRead": null,
   "totalArticles": null,
   "studyContacts": null,
   "totalStudyContacts": null,
   "status":"online",
   "attendance": 53,
   "coursePerformance": 91
 },
 {
   "id": "INS-002",
   "email": "ximena.flora@avalon.com",
   "firstName": "Ximena",
   "lastName": "Flora",
   "avatar": "https://xsgames.co/randomusers/assets/avatars/female/36.jpg",
   "gender": "female",
   "age": 25,
   "ageGroupId": "GRP-INS",
   "occupation": "instructor",
   "cohort": ["ENG-ADS-A2P-001", "ENG-ADS-B1-001",  "ENG-TNS-B1P-002", "ENG-ADS-A2-001", "ENG-TNS-B1-001"],
   "nativeLanguage": "Italian",
   "englishLevel": "C2",
   "learningGoal": "Unknown",
   "countryCode": 54,
   "areaCode": 11,
   "phone": "456789012",
   "address": "9101 Oak Avenue",
   "city": "Buenos Aires",
   "region": "Buenos Aires",
   "country": "Argentina",
   "zipCode": "91011",
   "organization": "Avalon School of English",
   "enrollments": [{"id": "GEN-INS-ADS", "name": "General Instruction", "percent": 90, "skills": [{"id": "SK-INS-LDR", "hours": 57}, {"id": "SK-INS-COM", "hours": 55}, {"id": "SK-INS-CFT", "hours": 50}]}],
   "cefrTest": {},
   "weeklyPoints": null,
   "articlesRead": null,
   "totalArticles": null,
   "studyContacts": null,
   "totalStudyContacts": null,
   "status":"online",
   "attendance": 76,
   "coursePerformance": 69
 },
 {
  "id": "INS-003",
  "email": "roberto.gonzalez@avalon.com",
  "firstName": "Roberto",
  "lastName": "Gonzalez",
  "avatar": "https://xsgames.co/randomusers/assets/avatars/male/21.jpg",
  "gender": "male",
  "age": 32,
  "ageGroupId": "GRP-INS",
  "occupation": "instructor",
  "cohort": [],
  "nativeLanguage": "Italian",
  "englishLevel": "B1",
  "learningGoal": "Unknown",
  "countryCode": 52,
  "areaCode": 55,
  "phone": "123456789",
  "address": "123 Main Street",
  "city": "Mexico City",
  "region": "Mexico City",
  "country": "Mexico",
  "zipCode": "12345",
  "organization": "Avalon School of English",
  "enrollments": [
  {
  "id": "GEN-INS-ADS",
  "name": "General Instruction",
  "percent": 90,
  "skills": [
  {
  "id": "SK-INS-LDR",
  "hours": 57
  },
  {
  "id": "SK-INS-COM",
  "hours": 55
  },
  {
  "id": "SK-INS-CFT",
  "hours": 50
  }
  ]
  }
  ],
  "cefrTest": {},
  "weeklyPoints": null,
  "articlesRead": null,
  "totalArticles": null,
  "studyContacts": null,
  "totalStudyContacts": null,
  "status":"online",
  "attendance": 86,
   "coursePerformance": 93
  },
  {
    "id": "INS-004",
    "email": "maria.santos@avalon.com",
    "firstName": "Maria",
    "lastName": "Santos",
    "avatar": "https://xsgames.co/randomusers/assets/avatars/female/15.jpg",
    "gender": "female",
    "age": 28,
    "ageGroupId": "GRP-INS",
    "occupation": "instructor",
    "cohort": [],
    "nativeLanguage": "Italian",
    "englishLevel": "B1",
    "learningGoal": "Unknown",
    "countryCode": 55,
    "areaCode": 21,
    "phone": "987654321",
    "address": "456 Elm Street",
    "city": "Sao Paulo",
    "region": "Sao Paulo",
    "country": "Brazil",
    "zipCode": "54321",
    "organization": "Avalon School of English",
    "enrollments": [
    {
    "id": "GEN-INS-ADS",
    "name": "General Instruction",
    "percent": 90,
    "skills": [
    {
    "id": "SK-INS-LDR",
    "hours": 57
    },
    {
    "id": "SK-INS-COM",
    "hours": 55
    },
    {
    "id": "SK-INS-CFT",
    "hours": 50
    }
    ]
    }
    ],
    "cefrTest": {},
    "weeklyPoints": null,
    "articlesRead": null,
    "totalArticles": null,
    "studyContacts": null,
    "totalStudyContacts": null,
    "status":"online",
    "attendance": 78,
   "coursePerformance": 84
    },
    {
      "id": "INS-005",
      "email": "luca.ferrari@avalon.com",
      "firstName": "Luca",
      "lastName": "Ferrari",
      "avatar": "https://xsgames.co/randomusers/assets/avatars/male/9.jpg",
      "gender": "male",
      "age": 32,
      "ageGroupId": "GRP-INS",
      "occupation": "instructor",
      "cohort": [],
      "nativeLanguage": "Italian",
      "englishLevel": "B1",
      "learningGoal": "Unknown",
      "countryCode": 39,
      "areaCode": 2,
      "phone": "987654321",
      "address": "789 Cedar Street",
      "city": "Milan",
      "region": "Lombardy",
      "country": "Italy",
      "zipCode": "56789",
      "organization": "Avalon School of English",
      "enrollments": [
      {
      "id": "GEN-INS-ADS",
      "name": "General Instruction",
      "percent": 90,
      "skills": [
      {
      "id": "SK-INS-LDR",
      "hours": 57
      },
      {
      "id": "SK-INS-COM",
      "hours": 55
      },
      {
      "id": "SK-INS-CFT",
      "hours": 50
      }
      ]
      }
      ],
      "cefrTest": {},
      "weeklyPoints": null,
      "articlesRead": null,
      "totalArticles": null,
      "studyContacts": null,
      "totalStudyContacts": null,
      "status":"online",
      "attendance": 81,
   "coursePerformance": 72
      },
      {
      "id": "INS-006",
      "email": "ana.rodriguez@avalon.com",
      "firstName": "Ana",
      "lastName": "Rodriguez",
      "avatar": "https://xsgames.co/randomusers/assets/avatars/female/27.jpg",
      "gender": "female",
      "age": 30,
      "ageGroupId": "GRP-INS",
      "occupation": "instructor",
      "cohort": [],
      "nativeLanguage": "Italian",
      "englishLevel": "B1",
      "learningGoal": "Unknown",
      "countryCode": 34,
      "areaCode": 93,
      "phone": "123456789",
      "address": "9101 Oak Avenue",
      "city": "Barcelona",
      "region": "Catalonia",
      "country": "Spain",
      "zipCode": "91011",
      "organization": "Avalon School of English",
      "enrollments": [
      {
      "id": "GEN-INS-ADS",
      "name": "General Instruction",
      "percent": 90,
      "skills": [
      {
      "id": "SK-INS-LDR",
      "hours": 57
      },
      {
      "id": "SK-INS-COM",
      "hours": 55
      },
      {
      "id": "SK-INS-CFT",
      "hours": 50
      }
      ]
      }
      ],
      "cefrTest": {},
      "weeklyPoints": null,
      "articlesRead": null,
      "totalArticles": null,
      "studyContacts": null,
      "totalStudyContacts": null,
      "status":"offline",
      "attendance": 80,
   "coursePerformance": 77
      },
      {
      "id": "INS-007",
      "email": "mario.rossi@avalon.com",
      "firstName": "Mario",
      "lastName": "Rossi",
      "avatar": "https://xsgames.co/randomusers/assets/avatars/male/11.jpg",
      "gender": "male",
      "age": 34,
      "ageGroupId": "GRP-INS",
      "occupation": "instructor",
      "cohort": [],
      "nativeLanguage": "Italian",
      "englishLevel": "B1",
      "learningGoal": "Unknown",
      "countryCode": 39,
      "areaCode": 2,
      "phone": "123456789",
      "address": "456 Maple Street",
      "city": "Rome",
      "region": "Lazio",
      "country": "Italy",
      "zipCode": "12345",
      "organization": "Avalon School of English",
      "enrollments": [
      {
      "id": "GEN-INS-ADS",
      "name": "General Instruction",
      "percent": 90,
      "skills": [
      {
      "id": "SK-INS-LDR",
      "hours": 57
      },
      {
      "id": "SK-INS-COM",
      "hours": 55
      },
      {
      "id": "SK-INS-CFT",
      "hours": 50
      }
      ]
      }
      ],
      "cefrTest": {},
      "weeklyPoints": null,
      "articlesRead": null,
      "totalArticles": null,
      "studyContacts": null,
      "totalStudyContacts": null,
      "status":"offline",
      "attendance": 87,
   "coursePerformance": 60
      },
      {
      "id": "INS-008",
      "email": "lucia.fuentes@avalon.com",
      "firstName": "Lucia",
      "lastName": "Fuentes",
      "avatar": "https://xsgames.co/randomusers/assets/avatars/female/19.jpg",
      "gender": "female",
      "age": 29,
      "ageGroupId": "GRP-INS",
      "occupation": "instructor",
      "cohort": [],
      "nativeLanguage": "Italian",
      "englishLevel": "B1",
      "learningGoal": "Unknown",
      "countryCode": 34,
      "areaCode": 91,
      "phone": "987654321",
      "address": "789 Oak Avenue",
      "city": "Madrid",
      "region": "Community of Madrid",
      "country": "Spain",
      "zipCode": "54321",
      "organization": "Avalon School of English",
      "enrollments": [
      {
      "id": "GEN-INS-ADS",
      "name": "General Instruction",
      "percent": 90,
      "skills": [
      {
      "id": "SK-INS-LDR",
      "hours": 57
      },
      {
      "id": "SK-INS-COM",
      "hours": 55
      },
      {
      "id": "SK-INS-CFT",
      "hours": 50
      }
      ]
      }
      ],
      "cefrTest": {},
      "weeklyPoints": null,
      "articlesRead": null,
      "totalArticles": null,
      "studyContacts": null,
      "totalStudyContacts": null,
      "status":"offline",
      "attendance": 55,
   "coursePerformance": 52
      },
      {
      "id": "INS-009",
      "email": "carlos.garcia@avalon.com",
      "firstName": "Carlos",
      "lastName": "Garcia",
      "avatar": "https://xsgames.co/randomusers/assets/avatars/male/25.jpg",
      "gender": "male",
      "age": 31,
      "ageGroupId": "GRP-INS",
      "occupation": "instructor",
      "cohort": [],
      "nativeLanguage": "Italian",
      "englishLevel": "B1",
      "learningGoal": "Unknown",
      "countryCode": 34,
      "areaCode": 93,
      "phone": "123456789",
      "address": "9101 Elm Street",
      "city": "Barcelona",
      "region": "Catalonia",
      "country": "Spain",
      "zipCode": "91011",
      "organization": "Avalon School of English",
      "enrollments": [
      {
      "id": "GEN-INS-ADS",
      "name": "General Instruction",
      "percent": 90,
      "skills": [
      {
      "id": "SK-INS-LDR",
      "hours": 57
      },
      {
      "id": "SK-INS-COM",
      "hours": 55
      },
      {
      "id": "SK-INS-CFT",
      "hours": 50
      }
      ]
      }
      ],
      "cefrTest": {},
      "weeklyPoints": null,
      "articlesRead": null,
      "totalArticles": null,
      "studyContacts": null,
      "totalStudyContacts": null,
      "status":"offline",
      "attendance": 75,
   "coursePerformance": 80
      },
 {
   "id": "ADM-000",
   "email": "anna.richards@avalon.com",
   "firstName": "Anna",
   "lastName": "Richards",
   "avatar": "https://xsgames.co/randomusers/assets/avatars/female/58.jpg",
   "gender": "female",
   "age": 30,
   "ageGroupId": "GRP-ADM",
   "occupation": "administrator",
   "cohort": "",
   "nativeLanguage": "English",
   "englishLevel": "C2",
   "learningGoal": "",
   "countryCode": 54,
   "areaCode": 11,
   "phone": "456789012",
   "address": "6053 Ocala Avenue",
   "city": "Buenos Aires",
   "region": "Buenos Aires",
   "country": "Argentina",
   "zipCode": "91011",
   "organization": "Avalon School of English",
   "enrollments": [],
   "cefrTest": {},
   "weeklyPoints": null,
   "articlesRead": null,
   "totalArticles": null,
   "studyContacts": null,
   "totalStudyContacts": null,
   "status":"busy",
   "attendance": 77,
   "coursePerformance": 89
 },
 {
  id: "ADM-001",
  email: "jeremy.stewart@quadbank.com",
  firstName: "Jeremy",
  lastName: "Stewart",
  avatar: "https://assets.pearsonct.design/avatar/63d6e420807516d2739002ee/jeremy.jpg",
  gender: "male",
  age: 30,
  ageGroupId: "GRP-ADM",
  occupation: "administrator",
  cohort: "",
  nativeLanguage: "English",
  englishLevel: "C2",
  learningGoal: "",
  countryCode: 1,
  areaCode: 404,
  phone: "6786665555",
  address: "6053 Gresham Rd",
  city: "Charlotte",
  region: "NC",
  country: "NC",
  zipCode: "91011",
  organization: "Avalon School of English",
  enrollments: [],
  cefrTest: {},
  weeklyPoints: "",
  articlesRead: null,
  totalArticles: null,
  studyContacts: null,
  totalStudyContacts: null,
},
];

export default users;