import React from "react";
import PageWrapper from "./global/PageWrapper";
import { Link } from "react-router-dom";


//images
import pal from "../assets/images/brand/pal.svg"
import back from "../assets/images/icons/arrow-left-blue.svg"
import selfStudy1 from "../assets/images/student_mylearning/self-study-1.png"
import selfStudy2 from "../assets/images/student_mylearning/self-study-2.png"
import selfStudy3 from "../assets/images/student_mylearning/self-study-3.png"
import selfStudy4 from "../assets/images/student_mylearning/self-study-4.png"
import tyler from "../assets/images/student_mylearning/tyler.png"
import florence from "../assets/images/student_mylearning/florence.png"
import star from "../assets/images/icons/star-yellow.svg"
import test from "../assets/images/student_mylearning/test.svg"
import badge from "../assets/images/student_mylearning/PEIC-logo.svg"

const MyLearningDetail = (props) => {

    return (
        <PageWrapper>
            <div className="mylearning mylearning-detail">
            <div className="background"></div>
                <Link className="nav-link icon-button icon-left" to="/mylearning">
                    <img src={back}/>
                    <p className="gradient-text bold">Back to My learning</p>
                </Link>
                <h2>Learn at your own pace</h2>
                <div className="hero-card">
                    <div className="pal-badge">
                        <img src={pal}/>
                        <span><span>PAL</span> Your virtual assistant</span>
                    </div>
                    <p className="title">Your personalized learning plan</p>
                    <p>Reach a <strong>B2</strong> level of English <strong>in 18 months</strong >and then take the <strong>Pearson English International Certificate</strong></p>
                </div>
                <section>
                    <h3>
                        <span>1</span>
                        Self Study
                    </h3>
                    <p>Follow this self-paced, personalized course to reach B2 proficiency.</p>
                    <div className="hero-card interests-card">
                        <div className="pal-badge">
                            <img src={pal}/>
                            <span><span>PAL</span></span>
                        </div>
                        <p>I created this course based on your interests:</p>
                         <span className='tag border'>🍕 Food</span>
                         <span className='tag border'>⚽ Soccer</span>
                         <span className='tag border'>🗞️ Politics</span>
                    </div>
                    <div className=" card self-study-card">
                        <div>
                            <div className="image-container">
                                <img src={selfStudy1}/>
                            </div>
                            <div>
                                <p>Digital human</p>
                                <p>News & Politics</p>
                            </div>
                        </div>
                        <span class="tag content">Speaking</span>
                    </div>
                    <hr/>
                    <div className=" card self-study-card">
                        <div>
                            <div className="image-container">
                                <img src={selfStudy2}/>
                            </div>
                            <div>
                                <p>Pearson Channels</p>
                                <p>Food Around the World</p>
                            </div>
                        </div>
                        <span class="tag content">Listening</span>
                    </div>
                    <hr/>
                    <div className=" card self-study-card">
                        <div>
                            <div className="image-container">
                                <img src={selfStudy1}/>
                            </div>
                            <div>
                                <p>Pearson Channels</p>
                                <p>Hobbies & Interests</p>
                            </div>
                        </div>
                        <span class="tag content">Listening</span>
                    </div>
                    <hr/>
                    <div className=" card self-study-card">
                        <div>
                            <div className="image-container">
                                <img src={selfStudy3}/>
                            </div>
                            <div>
                                <p>Daily article</p>
                                <p>Football in the News</p>
                            </div>
                        </div>
                        <span class="tag content">Reading</span>
                    </div>
                    <hr/>
                    <div className=" card self-study-card">
                        <div>
                            <div className="image-container">
                                <img src={selfStudy4}/>
                            </div>
                            <div>
                                <p>Games</p>
                                <p>Building Complex Sentences</p>
                            </div>
                        </div>
                        <span class="tag content">Writing</span>
                    </div>
                </section>
                <section>
                    <h3>
                        <span>2</span>
                        1:1 lessons
                    </h3>
                    <p>Improve your English with the help of a professional private tutor</p>
                    <p className="bold">Recommended Pearson tutors</p>
                    <div className="people">
                        <div className="person-card">   
                            <div className="image-container">
                                    <img src={tyler}/>
                            </div>
                            <p className="name bold">Tyler Campbell</p>
                            <p className="major">English tutor</p>
                            <div className="details">
                                <div className="rating">
                                    <img src={star}/>
                                    4.7
                                </div>
                                <span>£11.40/hr</span>
                            </div>
                        </div>
                        <div className="person-card">   
                            <div className="image-container">
                                    <img src={florence}/>
                            </div>
                            <p className="name bold">Florence Adams</p>
                            <p className="major">English tutor</p>
                            <div className="details">
                                <div className="rating">
                                    <img src={star}/>
                                    4.7
                                </div>
                                <span>£11.40/hr</span>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h3>
                        <span>3</span>
                        Test readiness
                    </h3>
                    <p>Find out if you’re ready for the real PEIC test and what you can do to improve</p>
                    <div className="dark-card step-3-card">
                        <img src={test}/>
                        <p className="title">Take a readiness test</p>
                        <p>for Pearson English International Certificate (PEIC)</p>
                    </div>
                </section>
                <section>
                    <h3>
                        <span>4</span>
                        PEIC test
                    </h3>
                    <div className="dark-card step-4-card">
                        <img src={badge}/>
                        <p className="title">Sit the Pearson English International Certificate (PEIC)</p>
                    </div>
                </section>
                <Link className="primary" to="/home">
                    Confirm study plan
                </Link>
            </div>
        </PageWrapper>
    )
}

export default MyLearningDetail