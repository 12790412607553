import React from "react";

const ProfileProgressBar = (props) => {

    return (
        <div className="progress-bar-container course">
            <div className="progress-bar course">
                <div className="bar" role="progressbar"
                aria-labelledby="PprogressBar"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow={props.value}
                style={{width:props.value + "%"}}
                />
            </div>
            <span className="bold">{props.value}%</span>
        </div>
        
    )
}
export default ProfileProgressBar