/** @format */

import React, { useState } from 'react';
import TextInput from './input/TextInput';
import DateInput from './input/DateInput';
import TextEditor from './input/TextEditor';
import TimeInput from './input/TimeInput';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LessonInfo = (props) => {
	const [lessonName, setLessonName] = useState('');
	const [lessonDate, setLessonDate] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	//const [lessonDescription, setLessonDescription] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	const handleSave = () => {
		setIsEditing(!isEditing);
	};

	return (
		<Accordion
			className={`accordion ${props.active ? 'active' : ''}`}
			onClick={props.onClick}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				className="summary"
			>
				<h3>What is this lesson about? </h3>
			</AccordionSummary>
			<AccordionDetails className="detail">
				<div className="input-header">
					{isEditing ? (
						<>
							<div className="input-value">
								<p>
									Lesson name: <b>{lessonName}</b>
								</p>
								<p>
									Lesson date: <b>{lessonDate}</b>
								</p>
							</div>
						</>
					) : (
						<>
							<TextInput
								label="Lesson name"
								placeholder=" "
								value={lessonName}
								onChange={(e) => setLessonName(e.target.value)}
							/>
							<DateInput
								label="Lesson date"
								placeholder=" "
								value={lessonDate}
								onChange={(e) => setLessonDate(e.target.value)}
							/>
						</>
					)}
				</div>
				<div className="input-time">
					{isEditing ? (
						<div className="input-value">
							<p>
								Start time:<b>{startTime}</b>
							</p>
							<p>
								End time: <b>{endTime}</b>
							</p>
						</div>
					) : (
						<>
							<TimeInput
								label="Start time"
								value={startTime}
								onChange={(e) => setStartTime(e.target.value)}
							/>
							<TimeInput
								label="End time"
								value={endTime}
								onChange={(e) => setEndTime(e.target.value)}
							/>
						</>
					)}
				</div>
				<div className="text-editor-container">
					{isEditing ? (
						<div className="input-value lesson-description">
							<p>
								Lesson Description:
								<br />
								{/* <b>{lessonDescription}</b> */}
							</p>
						</div>
					) : (
						<TextEditor label="Lesson Description" />
					)}
				</div>
				<div className="save-button">
					{isEditing ? (
						<button
							className="primary"
							onClick={handleSave}
						>
							Edit
						</button>
					) : (
						<button
							className="primary"
							onClick={() => setIsEditing(true)}
						>
							Save
						</button>
					)}
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default LessonInfo;
