/** @format */

import React from 'react';

//images
import chevronRight from '../../../assets/images/icons/chevron-right.svg';

const Material = [
	{
		src: 'material_card1',
		title: 'Creative Writing 1',
		description:
			'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
	},
	{
		src: 'material_card2',
		title: 'Creative Writing 2',
		description:
			'Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
	},
	{
		src: 'material_card3',
		title: 'Creative Writing 3',
		description:
			'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form',
	},
	{
		src: 'material_card2',
		title: 'Creative Writing 4',
		description:
			'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for will uncover many web sites still in their infancy.',
	},
];

const MaterialCard = (props) => {
	return (
		<>
			{Material.map((content, i) => (
				<div
					key={i}
					className="material-card"
				>
					<img
						className="background"
						src={require(`../../../assets/images/material_card/${content.src}.png`)}
						alt="Background"
					/>
					<div className="article">
						<h3 className="title">{content.title}</h3>
						<p className="description">{content.description}</p>
						<div className="preview-btn">
							<button className="icon-button icon-right">
								<span>Preview</span>
								<img
									src={chevronRight}
									alt="Chevron Right"
								/>
							</button>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default MaterialCard;
