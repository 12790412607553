import React from "react";
import Icon from "./Icon";

const AvailabilityTable = () => {

    const available = () => {
        return (
            <span className="available" role="img" aria-label="Available"><Icon icon="correct" size="18"/></span>
        )
    }

    return (
        <div className="availability-table">
            <table aria-label="Availability">
                <thead>
                    <tr>
                        <td></td>
                        <th scope="col">Mon</th>
                        <th scope="col">Tue</th>
                        <th scope="col">Wed</th>
                        <th scope="col">Thu</th>
                        <th scope="col">Fri</th>
                        <th scope="col">Sat</th>
                        <th scope="col">Sun</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">6am &ndash; 9am</th>
                        <td className="cell">{available()}</td>
                        <td className="cell">{available()}</td>
                        <td className="cell">{available()}</td>
                        <td className="cell">{available()}</td>
                        <td className="cell">{available()}</td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                    </tr>
                    <tr>
                        <th scope="row">9am &ndash; 12pm</th>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                    </tr>
                    <tr>
                        <th scope="row">12pm &ndash; 3pm</th>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell">{available()}</td>
                    </tr>
                    <tr>
                        <th scope="row">3pm &ndash; 5pm</th>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                    </tr>
                    <tr>
                        <th scope="row">5pm &ndash; 8pm</th>
                        <td className="cell">{available()}</td>
                        <td className="cell"></td>
                        <td className="cell">{available()}</td>
                        <td className="cell">{available()}</td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                        <td className="cell"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default AvailabilityTable